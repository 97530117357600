import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import  { MultiValue } from 'react-select';
import { getStorageData } from "../../../framework/src/Utilities";
interface IRequest {
  id: string;
  type: string;
  attributes: {
    sender_id: number;
    status: string;
    rejection_reason: string | null;
    request_text: string;
    created_at: string;
    updated_at: string;
    reviewer_group_id: number;
    sender_full_name: string;
  };
}
interface Lawyer {
  name: string;
  field: string;
  rating: string;
}
interface OptionType {
  value: string;
  label: string;
  id: number;
}
interface OptionTypeReason {
  value:string,
  label:string,
  id:number,
}
interface FormData {
  legal_service_type_id: number | string; 
  prices: number; 
}
interface LegalServiceState {
  optionsShow: OptionType[];  
  selectedOption: OptionType | null;  
}
export interface DataLegal{
  value:string,
  label:string,
  id:number,
}
export interface LegalServices{
  id: string,
  legal_service_name: string,
  legel_service_description: string|null,
  created_at: string,
  updated_at: string
}

export interface ServicesDataType{
  id: string,
  legal_service_name: string,
  legel_service_description: string|null,
  created_at: string,
  updated_at: string
}
export interface ServicesReason{
  id: string,
  reason_content: string,
  created_at: string,
  updated_at: string
}
interface ServiceData {
  id: string;
  legal_service_name: string;
}
interface ServiceDataReason {
  id: string;
  reason_content: string;
}
interface LegalServiceType {
  id: number;
  legal_service_name: string;
  legel_service_description: string | null;
  created_at: string;
  updated_at: string;
}

interface AssociatedLawyerDetails {
  id: string;
  type: string;
  attributes: {
    first_name: string;
    last_name: string;
    website: string;
    firm: string;
    jurisdiction: string;
    full_phone_number: string;
    country_code: number;
    email: string;
    user_role: string;
    accomplishments: string;
    lawyer_status: string;
    legel_service_type: LegalServiceType[];
    years_of_experience: number;
    profile_image: {
      url?: string | null; 
    } | null;
  };
}

interface LawyerData {
 
}

export interface ApiResponse {
  attributes: {
    associated_lawyer_details: {
      data: {
        id:number,
        attributes: {
          first_name: string;
          last_name: string;
          website: string;
          firm: string;
          jurisdiction: string;
          full_phone_number: string;
          country_code: number;
          email: string;
          user_role: string;
          accomplishments: string;
          lawyer_status: string;
          legel_service_type: [{
            id: string,
            legal_service_name: string,
            legel_service_description: string|null,
            created_at: string,
            updated_at: string
          },
          {
            id: string,
            legal_service_name: string,
            legel_service_description: string|null,
            created_at: string,
            updated_at: string
          },{
            id: string,
            legal_service_name: string,
            legel_service_description: string|null,
            created_at: string,
            updated_at: string
          }];
          years_of_experience: number;
          profile_image: {
            url?: string | null; 
          } | null;
        };
      }
    };
    legal_service_count: number;
  };
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  opendialog: boolean;
  openDialogLawyer:boolean
  rejectDialog:boolean
  handleClose:any;
  handleRejectClose:any
  taskId: string;
  projectId:string;
  handleUpdateCall: () => void;

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  description:string,
  responseShow:{
    success:string,
    errors:string},
  rejectDialog:boolean
  token: string;
  receivedRequests: IRequest[];
  anchorEl: HTMLElement | null;
  rejectText: string;
  rejectTextError: string | null;
  selectedId: string | null;
  viewRequest: IRequest | null;
  filterKey: string;
  price: number;
  searchQuery: string;
  selectedLawyers: ApiResponse[];
  close: boolean;
  searchResults: ApiResponse[];
  opens:boolean
  openPop:boolean
  optionsShow: OptionType[],
  optionsReason: OptionTypeReason[],
  selectedOption: string,  
  selectedOptions:MultiValue<LegalServices>,
  servicesData: ServiceData[];
  serviceReason:ServiceDataReason[],
  openDialog:boolean;
  acceptTask:boolean;
  rejectTask:boolean;
  taskViewId:string;
  taskData:{
    ids:string,
    attributes:{
      task_name: string,
      description: string,
      trademark_name: string,
      trademark_application_number: string,
      deadline: string,
      jurisdiction: string,
      state: string,
      status: string,
      legel_service_type:DataLegal[],
    }
  },
   formData:{
    legal_service_type_id: number | string; 
  prices: number; 
   };
   formData2:{
    rejection_description: string,
    task_reject_reason_id: number|null,
   };
   hasError: boolean,
   descError: boolean,
   selectedLawyer:boolean,
   rejectOption:string

  
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class RequestManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAllReceivedRequestCallId: string = "";
  postAcceptCallId:string="";
  postRejectCallId:string="";
  updateRequestReviewCallId: string = "";
  deleteRequestCallId: string = "";
  getServiceApiCallId:string = "";
  getReasonApiCallId:string = "";
  getSearchApiCallId:string = "";
  getTaskListsApiCallId = "";
  getTasksApiCallId=""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      description:"",
      descError:false,
      responseShow:{
        success:"",
        errors:""},
      taskViewId:"",
      openDialog:false,
      rejectDialog:false,
      acceptTask:false,
      rejectTask:false,
      selectedOptions:[],
      openPop:false,
      anchorEl: null,
      servicesData: [],
      serviceReason:[],
      searchQuery: "",
      selectedLawyers: [],
      searchResults: [],
      close: false,
      taskData:{
        ids:"",
        attributes:{
          task_name: "",
          description: "",
          trademark_name: "",
          trademark_application_number: "",
          deadline: "",
          jurisdiction: "",
          state: "",
          status: "",
          legel_service_type: [
            {
              value: "", 
              label: "", 
              id: 1, 
            }
          ],
        }
      },
      token: "",
      receivedRequests: [],
      rejectText: "",
      rejectTextError: null,
      selectedId: null,
      viewRequest: null,
      filterKey: '',
      price: 1800,
      opens:false,
      optionsShow: [
        {
          value: "",
          label: "string",
          id: 1,
        },
      ],
      optionsReason: [
        {
          value: "",
          label: "string",
          id: 1,
        }
      ],
      selectedOption: "",
      formData:{
        legal_service_type_id: 3,
        prices: 1500,
    },
    formData2:{
      rejection_description: "",
      task_reject_reason_id:null
  },
    hasError: false,
    selectedLawyer:false,
    rejectOption:""
   
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      runEngine.debugLog("API Message Recived", message);
      if (apiRequestCallId === this.getServiceApiCallId) {
        this.setState({ servicesData: responseJson });

        const servicesOptions = responseJson.map((e: ServicesDataType) => ({
            id: e.id,
            label: e.legal_service_name,
            value: e.legal_service_name,
        }));

        this.setState({ optionsShow: servicesOptions });
    }

    if (apiRequestCallId === this.getReasonApiCallId) {
      this.setState({ serviceReason: responseJson });
      const reasonsOptions = responseJson.map((e: ServicesReason) => ({
          id: e.id,
          label: e.reason_content, 
          value: e.reason_content,  
      }));
      
      this.setState({ optionsReason: reasonsOptions });
  }
  
  if(apiRequestCallId === this.postAcceptCallId ||apiRequestCallId === this.postRejectCallId){
  this.handleProjectCreationResponse(responseJson)
  }
 
  if(apiRequestCallId === this.getSearchApiCallId){

      if (responseJson.data) {
        this.setState({ searchResults: responseJson.data});
      } else {
        this.setState({ searchResults: [] });
      }

    
  }
  }

   
  }
 

  componentDidMount = async () => {
    this.getServicesList()
    this.getReasonList()
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
  };
  
  handleProjectCreationResponse = (responseJson: { meta: { message: any; }; errors?: { message: string }[]; }) => {
    if (responseJson?.errors ){
      this.setState({
        openDialog: true,
        responseShow: { errors: responseJson.errors[0].message, success: "" } 
      });
    } else if (responseJson?.meta?.message) {
      this.setState({
        openDialog: true,
        responseShow: { errors: "", success: responseJson.meta.message }
      });
    }
  
    setTimeout(() => {
      this.setState({ openDialog: false });
       this.props.handleUpdateCall()
    }, 700);
    
    this.setState({ selectedLawyers: [] });
  };
  
  
handleSearchChange = (event: { target: { value: string; }; }) => {
  const query = event.target.value;
  this.setState({ searchQuery: query, openPop: query.length > 0 }, () => {
    this.getSearchData(query);
  });
};
 getTaskRejectReasonId = (value: string, optionsReason: any[]) => {
   if (value === "no_legal_case") {
     return null;
    }
  const selectedOption = optionsReason.find((option: { value: any; }) => option.value === value);
  return selectedOption ? selectedOption.id : null;
};

handleChange2 = (event: React.ChangeEvent<HTMLSelectElement>) => {
  const { value } = event.target;
  const taskRejectReasonId = this.getTaskRejectReasonId(value, this.state.optionsReason);
  this.setState(prevState => ({
      ...prevState,
      selectedOption: value,
      rejectOption:value,
      formData2: {
          ...prevState.formData2,
          task_reject_reason_id: taskRejectReasonId,
      },
      hasError: false,
  }));
};
handleRejectClose = () => {
  this.setState({
    selectedOption: "", 
    hasError:false
  });
  this.props.handleRejectClose(); 
}
handleCloseDialog = () => {
  this.setState((prevState) => ({
    selectedOption: "",
    formData: {
      ...prevState.formData,
      prices: 1500, 
    },
    selectedLawyers:[],
    searchQuery:"",
    hasError:false
  }));
  this.props.handleClose(); 
}
handleLawyerSelect = (lawyer: ApiResponse) => {
  const { selectedLawyers } = this.state;
  const lawyerId = lawyer?.attributes?.associated_lawyer_details?.data.id;
  const index = selectedLawyers.findIndex(selected => 
    selected?.attributes?.associated_lawyer_details?.data?.id === lawyerId
  );

  if (index > -1) {
    this.setState(prevState => {
      const updatedLawyers = prevState.selectedLawyers.filter((_, i) => i !== index);
      
      return { selectedLawyers: updatedLawyers };
    });
  } else {
    const updatedLawyers = [...this.state.selectedLawyers, lawyer];

    this.setState({ selectedLawyers: updatedLawyers })
    this.setState({ openPop: true }); 
  }
};
closePop=()=>{
  this.setState({openPop:false})
}

  handleRemoveSelectedLawyer = (lawyer: ApiResponse) => {
    this.setState((prevState) => ({
      selectedLawyers: prevState.selectedLawyers.filter(
        (selected) => selected?.attributes?.associated_lawyer_details?.data.id !== lawyer?.attributes?.associated_lawyer_details?.data?.id
      ),
    }));
  };

  handleCloseDelete = () => {
    this.setState({ openDialog: false });
  };


  getServicesList= async () => {
    const header = {
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getServiceApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     "bx_block_productdescription/service_managements/legal_services_list"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getReceivedRequestApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  determineMainToken1 = (
    metaSignUpToken: { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string } },
    metaLoginToken: { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string } }
  ): { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string } } | null => {
    if (metaSignUpToken?.data?.id) {
      if (metaLoginToken?.id && metaSignUpToken.data.id !== metaLoginToken.id) {
        return metaLoginToken;
      }
      return metaSignUpToken;
    }

    if (metaLoginToken?.id) {
      return metaLoginToken;
    }

    return null; 
  };

  getReasonList= async () => {
    const signupTokens: string = await getStorageData("userdetails");
    const loginTokens: string = await getStorageData("token");
    const metaSignUpToken = JSON.parse(signupTokens);
    const metaLoginToken = JSON.parse(loginTokens);

    const maintoken = this.determineMainToken1(metaSignUpToken, metaLoginToken);
    const header = {
      "token": maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token,
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getReasonApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     "bx_block_request_management/requests/reject_request_reason"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getReceivedRequestApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  incrementPrice = () => {
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        prices: Math.min(prevState.formData.prices + 50, 2500), 
      },
    }));
  };

  decrementPrice = () => {
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        prices: Math.max(prevState.formData.prices - 50, 1500), 
      },
    }));
  };

  
  handlePriceChange = (event: React.ChangeEvent<{}>, value: number | number[]) => {
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        prices: value as number, 
      },
    }));
  };
  handlePriceChangeText = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value);
  
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        prices: value, 
      },
    }));
  };
  getToken = () => {
    const tokenMsg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(tokenMsg);
  };

 

  updateRequestReview = (requestReviewId: string, is_accepted: boolean) => {
    const headers = {
      "Content-Type": configJSON.requestApiContentType,
      token: this.state.token,
    };

    if (!is_accepted && this.state.rejectText.length < 1) {
      this.setState({ rejectTextError: configJSON.rejectTextFieldIsRequired });
      return;
    }

    const httpBody = {
      is_accepted,
      ...(!is_accepted && {
        rejection_reason: this.state.rejectText,
      }),
    };

    const updateRequestReviewMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateRequestReviewCallId = updateRequestReviewMsg.messageId;

    updateRequestReviewMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateRequestApiEndpointStart +
        requestReviewId +
        configJSON.updateRequestApiEndpointEnd
    );

    updateRequestReviewMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    updateRequestReviewMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateRequestApiMethod
    );

    updateRequestReviewMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(updateRequestReviewMsg.id, updateRequestReviewMsg);
  };

  deleteRequest = (deleteRequestId: string) => {
    const headers = {
      "Content-Type": configJSON.requestApiContentType,
      token: this.state.token,
    };

    const deleteRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteRequestCallId = deleteRequestMsg.messageId;

    deleteRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteRequestApiEndpoint + deleteRequestId
    );

    deleteRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    deleteRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteRequestApiMethod
    );

    runEngine.sendMessage(deleteRequestMsg.id, deleteRequestMsg);
  };

 
  onChangeTextRejectText = (rejectText: string) => {
    this.setState({ rejectText });
  };

  setSelectedId = (selectedId: string | null) => {
    this.setState({ selectedId, rejectText: "", rejectTextError: null });
  };

  navigateHandler = () => {
    const navigationMsg = new Message(getName(MessageEnum.NavigationMessage));
    navigationMsg.addData(getName(MessageEnum.NavigationTargetMessage), "SentRequest");

    navigationMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    
    navigationMsg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(navigationMsg);
  };

  setViewRequest = (viewRequest: IRequest) => {
    this.setState({viewRequest})
  }

  closeViewModal = () => {
    this.setState({viewRequest: null})
  }

  
  onChangeTextFilterKey = (filterKey: string) => {
    this.setState({ filterKey });
  };
  getValue = (trueSta:any, falseSta: any) => {
    if(trueSta){
      return trueSta;
    }else{
      return falseSta
    }

  }
  acceptedRequest= async()=>{
    const assignedLawyerIds = this.state.selectedLawyers.map(lawyer => 
      lawyer.attributes.associated_lawyer_details.data.id
    );
    const requestBody = {
      "request":{
        "legel_service_type_id": this.state.formData.legal_service_type_id,
        "price": this.state.formData.prices,
        "assigned_lawyer_ids": assignedLawyerIds,
        "project_template_id":this.props.projectId
      }
     
    };
    const signupTokens: string = await getStorageData("userdetails");
    const loginTokens: string = await getStorageData("token");
    const metaSignUpToken = JSON.parse(signupTokens);
    const metaLoginToken = JSON.parse(loginTokens);

    const maintoken = this.determineMainToken1(metaSignUpToken, metaLoginToken);
    const header = {
      "token": this.getValue(maintoken?.serialized_data?.meta?.token , maintoken?.meta?.token),
      "Content-Type": "application/json",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postAcceptCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.postAcceptRequest}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.createSendRequestApiMethod
    );
    
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getSearchData= async (query:any) => {
    const signupTokens: string = await getStorageData("userdetails");
    const loginTokens: string = await getStorageData("token");
    const metaSignUpToken = JSON.parse(signupTokens);
    const metaLoginToken = JSON.parse(loginTokens);

    const maintoken = this.determineMainToken1(metaSignUpToken, metaLoginToken);
    const header = {
      "token": maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token,
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSearchApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_catalogue/catalogues/list_of_associated_lawyers?search=true&query=${query}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getReceivedRequestApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const description = e.target.value;
  
    this.setState({
      formData2: {
        ...this.state.formData2,
        rejection_description: description,
      },
      hasError: false,
     
    });
  };

  rejectRequest= async()=>{
    const signupTokens: string = await getStorageData("userdetails");
    const loginTokens: string = await getStorageData("token");
    const metaSignUpToken = JSON.parse(signupTokens);
    const metaLoginToken = JSON.parse(loginTokens);

    const maintoken = this.determineMainToken1(metaSignUpToken, metaLoginToken);
    const header = {
      "token": maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token,
      "Content-Type": "application/json",
    };
    const { task_reject_reason_id ,rejection_description} = this.state.formData2;

const requestBody = {
  request: {
    task_reject_reason_id:task_reject_reason_id,
    project_template_id: this.props.projectId,
    ...(this.state.rejectOption !== "NO LEGAL CASE" ? { rejection_description } : {})
  }
};



    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postRejectCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.postRejectRequest}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.createSendRequestApiMethod
    );
    
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  
 
  getTaskLists = async() => {
    const signupTokens: string = await getStorageData("userdetails");
    const loginTokens: string = await getStorageData("token");
    const metaSignUpToken = JSON.parse(signupTokens);
    const metaLoginToken = JSON.parse(loginTokens);

    const maintoken = this.determineMainToken1(metaSignUpToken, metaLoginToken);
    const header = {
      "token": maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token,
    "Content-Type": "application/json",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTaskListsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.tasksApiLawfirmEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSentRequestApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  lawyerTasks = async () => {
    const signupTokens: string = await getStorageData("userdetails");    
    const loginTokens: string = await getStorageData("token");
    const metaSignUpToken = JSON.parse(signupTokens);
    const metaLoginToken = JSON.parse(loginTokens);

    const maintoken = this.determineMainToken1(metaSignUpToken, metaLoginToken);

    const header: { "Content-Type": string; token: string | undefined } = {
      "token": maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token,
    "Content-Type": "application/json",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTasksApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.tasksListApiEndPoint}`
    );
 
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSentRequestApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Customizable Area End
}
