import { v4 as uuidv4 } from 'uuid';
import { runEngine } from '../../../framework/src/RunEngine';
import { IBlock } from '../../../framework/src/IBlock';
import { Message } from '../../../framework/src/Message';
import MessageEnum, {
  getName
} from '../../../framework/src/Messages/MessageEnum';

export default class ProjectTemplatesAdapter {
  send: (message: Message) => void;

  constructor() {
    const blockId = uuidv4();
    this.send = message => runEngine.sendMessage(blockId, message);
    runEngine.attachBuildingBlock(this as IBlock, [
      getName(MessageEnum.NavigationProjectTemplatesMessage),
      getName(MessageEnum.NavigationTaskAllocatorMessage),
      getName(MessageEnum.NavigationProjectTrackingMessage),
    ]);
  }


  convert = (from: Message): Message => {


    const to = new Message(getName(MessageEnum.NavigationMessage));

    if ( from.getData(getName(MessageEnum.TaskAllocatorMessage))) {
      to.addData(getName(MessageEnum.NavigationTargetMessage), "TaskAllocator")
  } 
  else if ( from.getData(getName(MessageEnum.ProjectTrackingMessage))){
    to.addData(getName(MessageEnum.NavigationTargetMessage), "projectTracking")
  }
else{
      to.addData(getName(MessageEnum.NavigationTargetMessage), "projectTemplates")
  } 

    to.addData(
      getName(MessageEnum.NavigationPropsMessage),
      from.getData(getName(MessageEnum.NavigationPropsMessage))
    );

    const raiseMessage = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );

    raiseMessage.addData(
      getName(MessageEnum.NavigationProjectTemplatesWithId),
      from.getData(getName(MessageEnum.NavigationProjectTemplatesWithId))
    );

    raiseMessage.addData(
      getName(MessageEnum.TaskAllocatorMessage), 
      from.getData(getName(MessageEnum.TaskAllocatorMessage))
    );
    raiseMessage.addData(
      getName(MessageEnum.NavigationTaskAllocatorId),
      from.getData(getName(MessageEnum.NavigationTaskAllocatorId))
    );
    raiseMessage.addData(
      getName(MessageEnum.ProjectTrackingMessage), 
      from.getData(getName(MessageEnum.ProjectTrackingMessage))
    );
    raiseMessage.addData(
      getName(MessageEnum.NavigationProjectTrackingId),
      from.getData(getName(MessageEnum.NavigationProjectTrackingId))
    );

    to.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    return to;
  };

  receive(from: string, message: Message): void {
    this.send(this.convert(message));
  }
}
