import React from "react";
// Customizable Area Start
import ForgotPasswordController, { Props } from "./ForgotPasswordController.web";
import { Grid, Button, Typography, Box, styled, IconButton, TextField, InputAdornment } from '@material-ui/core';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { Formik, Field, ErrorMessage } from "formik";
import { logo } from "./assets";
import * as Yup from "yup";
import ErrorSuccessPopUp from "../../../components/src/ErrorSuccessPopUp.web";


// Customizable Area End


export default class ForgotPasswordWeb extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  errorClass=(values:{password: string})=>{
    if(this.state.passwordError && values.password == this.state.oldpassword){
      return "errorOutline"
    }
    return "";
  }
  renderPasswordError=(values:{password: string})=> {
    if (this.state.passwordError && values.password === this.state.oldpassword) {
      return <Typography className="errormsg errorOutline">{this.state.passwordError}</Typography>;
    }
    return null;
  }
  renderNewPassword = () => {
    const validationSchema = Yup.object().shape({
      password: Yup.string()
        .required('Password is required')
        .min(8, 'Minimum 8 characters')
        .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
        .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
        .matches(/\d/, 'Password must contain at least one number'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Confirm password is required'),
    });

    return (
      <Formik
        initialValues={{ password: "", confirmPassword: "" }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          this.resetPassword(values)
        }}
      >
        {({ values, errors, handleSubmit, touched }) => (
          <form className="thirdForm" onSubmit={handleSubmit}>
            <Box className="firstNameField">
              <Box>
                <Typography variant="subtitle1" className="firstNamePlaceHolder">
                  New password
                </Typography>
                <Field
                  as={TextField}
                  name="password"
                  type={this.state.showPassword ? "text" : "password"}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  placeholder="Your password"
                  className={this.errorClass(values)}
                  onFocus={() => this.setState({ showPasswordCriteria: true })}
                  onBlur={() => this.setState({ showPasswordCriteria: false })}
                  inputProps={{ "data-test-id": "newPassword" }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          data-test-id="toggleNewPasswordVisibility"
                          onClick={this.handleClickShowPassword}
                          onMouseDown={this.handleMouseDownPassword}
                          edge="end"
                        >
                          {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
                {this.renderPasswordError(values)}
              </Box>
              <Box>
                <Typography variant="subtitle1" className="firstNamePlaceHolder">
                  Confirm new password
                </Typography>
                <Field
                  as={TextField}
                  name="confirmPassword"
                  type={this.state.showConfirmPassword ? "text" : "password"}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  placeholder="Confirm your password"
                  inputProps={{ "data-test-id": "confirmPassword" }}
                  className={errors.confirmPassword && touched.confirmPassword ? "errorOutline" : ''}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          data-test-id="toggleConfirmPasswordVisibility'"
                          aria-label="toggle password visibility"
                          onClick={this.handleClickShowConfirmPassword}
                          onMouseDown={this.handleMouseDownPassword}
                          edge="end"
                        >
                          {this.state.showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
                <ErrorMessage name="confirmPassword" component="div" style={{ color: '#DC2626', fontFamily: 'DM Sans', fontSize: '14px', fontWeight: 600 }} />
              </Box>
              {this.state.showPasswordCriteria && (
                <Box mt={2}>
                  <Typography className="errorParent">Your password must contain:</Typography>
                  <Box style={{ display: "flex", alignItems: "flex-start", flexDirection: "column" }}>
                    <Box className="passwordErrorBox" display="flex" alignItems="center">
                      <IconButton>
                        {/[A-Z]/.test(values.password) ? (
                          <CheckCircleIcon style={{ color: 'green' }} />
                        ) : (
                          <RemoveCircleIcon style={{ color: 'red' }} />
                        )}
                      </IconButton>
                      <Typography className="passError">At least one uppercase letter</Typography>
                    </Box>
                    <Box className="passwordErrorBox errorBox" display="flex" alignItems="center">
                      <IconButton>
                        {/[a-z]/.test(values.password) ? (
                          <CheckCircleIcon style={{ color: 'green' }} />
                        ) : (
                          <RemoveCircleIcon style={{ color: 'red' }} />
                        )}
                      </IconButton>
                      <Typography className="passError">At least one lowercase letter</Typography>
                    </Box>
                    <Box className="passwordErrorBox errorBox" display="flex" alignItems="center">
                      <IconButton>
                        {/\d/.test(values.password) ? (
                          <CheckCircleIcon style={{ color: 'green' }} />
                        ) : (
                          <RemoveCircleIcon style={{ color: 'red' }} />
                        )}
                      </IconButton>
                      <Typography className="passError">At least one number</Typography>
                    </Box>
                    <Box className="passwordErrorBox errorBox" display="flex" alignItems="center">
                      <IconButton>
                          {this.handlePasswordIcon(values)}
                      </IconButton>
                      <Typography className="passError">Old password cannot be reused</Typography>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
            <button data-test-id="setNew" type="submit" className='continueBtn'  color="primary">
              Set New Password
            </button>
          </form>
        )}
      </Formik>
    );
  }
  handlePasswordIcon=(values:{password:string})=>{
    let passwordIcon;
      if (this.state.passwordError) {
        if (values.password !== this.state.oldpassword) {
          passwordIcon = (
            <div>
              {this.handleRemoveError()}
              <CheckCircleIcon style={{ color: 'green' }} />
            </div>
          );
        } else {
          passwordIcon = <RemoveCircleIcon style={{ color: 'red' }} />;
        }
      } else {
        passwordIcon = <CheckCircleIcon style={{ color: 'green' }} />;
      }

      return(
        passwordIcon
      )
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <MainGrid container data-test-id="NewPassword">
        <Grid item xs={12} className="logoGrid">
          <img src={logo} alt="Logo" className="logo" />
        </Grid>
        <Grid container item xs={12} justifyContent="center" alignItems="center" className="secondGridItem">
          <Box
            p={3}
            className="formBox"
          >
            <Typography variant="h4" gutterBottom className="signupText">
              New Password
            </Typography>
            <Typography className='newPasswordText'>
              Enter a new password for example Example123
            </Typography>
            <Box mt={2} mb={2}>
              {this.renderNewPassword()}
            </Box>
            <Box className="accountCreate">
              <Typography className="accountQuestion">Back to</Typography>
              <Box className="sign" data-test-id="loginNavigation" onClick={this.handleLogIn}>Log in</Box></Box>

          </Box></Grid>

        {this.state.showPopUP && 
        
        <ErrorSuccessPopUp
        setShowPop={(newState: boolean) => this.setState({ showPopUP: newState })}
        loginNav={this.handleLogIn}
        showResponse={this.state.ResponseShoW}
        deletePop={this.state.loginNav}
        showPop={this.state.showPopUP}
        termfunction={  () => {}}
                />
                }
      </MainGrid>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const MainGrid = styled(Grid)({
  height: '100vh',
  position: 'relative',
  "& .logoGrid": {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: "13px",
    color: "#ffff",
    height: '8%  !important',
  },
  "& .logo": {
    width: '100px',
    height: 'auto',
    marginLeft:"1% !important"
  },
  "& .secondGridItem": {
    height: '90%',
    marginBottom:"2%"
  },
  "& .formBox": {
    width: '100%',
    maxWidth: '400px',
  },
  "& .signupText": {
    width: '100%',
    marginBottom: "30px",
    fontFamily: "DM Sans",
    fontSize: "33px",
    fontWeight: 700,
    lineHeight: "42px",
    textAlign: "left"
  },
  "& .continueBtn": {
    width: "100%",
    padding: "20px",
    marginTop: "30px",
    borderRadius: "10px",
    backgroundColor: "#355EE7",
    color: "white",
    textTransform: "capitalize",
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: "DM Sans",
    lineHeight: "21px",
    boxShadow:"none !important",
    border:"none",
    cursor:'pointer',
    '&:disabled': {
      backgroundColor: "#D9D9D9",
    },
    '&:hover': {
      border:'2px solid #B9C5FF',
      boxShadow:'none !important'
    },
  },
  "& .firstNamePlaceHolder": {
    textAlign: "left"
  },
  "& .newPasswordText": {
    margin: "30px 0",
    textAlign: "left"
  },
  "& .passwordErrorBox": {
    display: "flex !important",
    alignItems: "center !important",
    justifyContent: "center !important"
  },
  "& .accountCreate": {
    display: "flex",
    marginTop: "40px"
  },
  "& .accountQuestion": {
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: "DM Sans"
  },
  "& .sign": {
    fontFamily: "DM Sans",
    marginLeft: "10px",
    color: "#355EE7",
    justifyContent: 'center',
    cursor: 'pointer',
    fontSize: '16px',
    fontWeight: 400
  },
  "& .errormsg": {
    color: '#DC2626',
    fontFamily: "DM Sans",
    fontSize: "14px",
    fontWeight: 600,
    marginBottom: "10px",
  },
  "& .errorOutline": {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'red',
      },
    },
  },
});
// Customizable Area End
