export const logo = require("../assets/image_logo.svg");
export const userpic = require("../assets/user.jpg");
export const updownarrow = require("../assets/updown.png");
export const editIcon = require("../assets/editIcon.png");
export const deleteIcon = require("../assets/deleteIcon.png")
export const confirmdeleteIcon = require("../assets/confirmdeleteIcon.png")
export const closeIcon =require("../assets/closeIcon.png")
export const bag =require("../assets/bag.png")
export const earth =require("../assets/earth.png")
export const userP =require("../assets/userPr.png")

