import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { ITask, ITaskList } from "./types";
import { getStorageData } from "../../../framework/src/Utilities";
export interface TaskProfile {
  isSelected: boolean;
  id: string;
  attributes: {
    task_name: string;
    legel_service_type: any;
    trademark_name: string;
    trademark_application_number: string;
    state: string;
    status: string;
    id: string;
    account_id: number;
    name: string;
    created_at: Date;
    updated_at: Date;
    tasks: ITask[];
  };
}
export interface ExperienceDataType{
  name:string
}
export interface CountryDatatype {
  id: string;
  type: string;
  attributes: {
    name: string;
    emoji_flag:string
  };
}
export interface DataLegal{
  value:string,
  label:string,
  id:number,
}
export interface LegalServices{
  id: string,
  legal_service_name: string,
  legel_service_description: string|null,
  created_at: string,
  updated_at: string
}

export interface ServicesDataType{
  id: string,
  legal_service_name: string,
  legel_service_description: string|null,
  created_at: string,
  updated_at: string
}
export interface LegalData{
  id: string,
  legal_service_name: string,
}
export interface LegalServiceType {
  id: number,
  name: string
}
export interface LegalOptionService{
  value:string,
  label:string,
  id:string,
}
export interface ApiResponse {
  attributes: {
    associated_lawyer_details: {
      data: {
        id:number,
        attributes: {
          first_name: string;
          last_name: string;
          website: string;
          firm: string;
          jurisdiction: string;
          full_phone_number: string;
          country_code: number;
          email: string;
          user_role: string;
          accomplishments: string;
          lawyer_status: string;
          legel_service_type: [{
            id: string,
            legal_service_name: string,
            legel_service_description: string|null,
            created_at: string,
            updated_at: string
          }];
          years_of_experience: number;
          profile_image: {
            url?: string | null; 
          } | null;
        };
      }
    };
    legal_service_count: number;
  };
}
export interface OptionsService {
  value: string;
  label: string;
  id: number;
};

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start


  // Customizable Area End
}

export interface S {
  id: number;
  // Customizable Area Start
  name: string;
  searchCountry:string,
  editMode: boolean;
  token: string;
  taskLists: ITaskList[];
  taskProfile:TaskProfile[],
  taskView:{
  ids: string;
  attributes: {
    task_name: string;
    legel_service_type: LegalServiceType;
    trademark_name: string;
    trademark_application_number: string;
    state: string;
    status: string;
    jurisdiction:string,
    deadline:string,
    description:string
    created_at:string
  };
  },
  tasksData: ITask[];
  selectedTasks: ITask[];
  isVisibleModal: boolean;
  dropdownTasks: boolean;
  mobileOpen: boolean;
  open: boolean;
  openDialog: boolean
  drawerOpen: boolean,
  taskViewId:string,
  isExpanded:boolean,
  loading:boolean,
  associatedLawyer: ApiResponse[];
  isTaskPosted:boolean,
  selectedLawyers:ApiResponse[];
  selectedLawyersID: number[]
  updatedSelectedID: number[],
  isAnyLawyerSelected:boolean,
  isFilterPopupOpen:boolean,
  optionsShow:OptionsService[],
  legalServiceType:LegalOptionService[],
  countryData:CountryDatatype[],
  servicesData:LegalData[],
  formData:{
    legal_service_type_id: number | string; 
  prices: number; 
   };
   selectedOp: string,
   selectedCountry:string,
   selectChange:string,
    experienceData:ExperienceDataType[],
    searchQuery:string,
    searchResults: ApiResponse[],
    selectedService: string,
    selectedCountryChip:string,
    selectedYearExp:string,
    searchYearExp:string,
    searchYearExpChip:string,
    selectedLawId:string
  // Customizable Area End
}

export interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class TaskAllocatorController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getTaskListsApiCallId = "";
  postTaskListApiCallId = "";
  putTaskListApiCallId = "";
  deleteTaskListApiCallId = "";
  getTasksApiCallId = "";
  getTaskAllocatorApiId = "";
  getAssociatedLawyersId = "";
  patchLawyerCallId = "";
  assignLawyerCallId="";
  getServiceApiCallId="";
  getCountryApiCallId="";
  getExperienceApiCallId="";
  getSearchApiCallId="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.NavigationPropsMessage),
  
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      id: 0,
      name: "",
      editMode: false,
      token: "",
      taskLists: [],
      tasksData: [],
      selectedTasks: [],
      updatedSelectedID: [],
      isVisibleModal: false,
      dropdownTasks: false,
      mobileOpen: false,
      open: false,
      openDialog: false,
      drawerOpen: false,
      taskProfile:[],
      selectedLawyers:[],
      taskView:{
        ids:"",
        attributes: {
          task_name: "",
          legel_service_type: {
            id:1,
            name:""
          },
          trademark_name: "",
          trademark_application_number: "",
          state: "",
          status: "",
          jurisdiction:"" ,
          deadline:"",
          description:"",
          created_at:""
            }
        },
      taskViewId:"",
      isExpanded:false,
      loading:true,
      associatedLawyer:[],
      isTaskPosted:false,
      selectedLawyersID: [],
      isAnyLawyerSelected:false,
      isFilterPopupOpen:false,
      optionsShow:[],
      legalServiceType:[],
      countryData:[],
      servicesData:[],
      formData:{
        legal_service_type_id: 3,
        prices: 1500,
    },
    selectedOp: "",
    experienceData:[],
    searchQuery:"",
    searchResults: [],
    selectChange:"",
    selectedService:"",
    searchCountry:"",
    selectedCountry:"",
    selectedCountryChip:"",
    selectedYearExp:"",
    searchYearExp:"",
    searchYearExpChip:"",
    selectedLawId:""
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.associatedLawyers();
    this.getServicesList();
    this.getCountryList();
    this.getExperienceList()
    }

  handleNavigationProject = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage),
      "projectTemplates");
    message.addData(getName(MessageEnum.NavigationPropsMessage),
      this.props);

    this.send(message);
  }
  handleFilterPopupOpen = () =>{
    this.setState({isFilterPopupOpen:!this.state.isFilterPopupOpen})
  }

  handleNavigateDashboard = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage),
      "DashBoard");
    message.addData(getName(MessageEnum.NavigationPropsMessage),
      this.props);

    this.send(message);
  }

  handleNavigateAllocator = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage),
      "TaskAllocator");
    message.addData(getName(MessageEnum.NavigationPropsMessage),
      this.props);

    this.send(message);
  }
  handleNavigationTaskList = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage),
      "TaskListLawfirm");
    message.addData(getName(MessageEnum.NavigationPropsMessage),
      this.props);

    this.send(message);
  }
  handleNavigateList = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage),
      "TaskListLawfirm");
    message.addData(getName(MessageEnum.NavigationPropsMessage),
      this.props);

    this.send(message);
  }
  
  receive = async (from: string, message: Message) => {
    runEngine.debugLog("Message Received", message);
    
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(apiRequestCallId === this.getAssociatedLawyersId){
     this.handleApiResponse(responseJson)
  }
  if (apiRequestCallId === this.getExperienceApiCallId) {
    this.setState({experienceData:responseJson})
   }
  if (apiRequestCallId === this.getCountryApiCallId) {
    this.setState({countryData:responseJson?.data})
    
   }
  if (apiRequestCallId === this.getServiceApiCallId) {
    this.setState({servicesData:responseJson})
    if(responseJson){
      let user: DataLegal[] = await Promise.all(
        responseJson.map(async (e:ServicesDataType) => ({
          id: e.id,
          label: e.legal_service_name,
          value: e.legal_service_name,
        }))
      );
        this.setState({optionsShow:user})
    }
   }
  if(apiRequestCallId === this.assignLawyerCallId){
   this.handleAssign(responseJson)
}
if(apiRequestCallId === this.patchLawyerCallId){
 this.handlePatchResponse(responseJson)
 this.assignLawyer()
}
if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
  let taskId = message.getData(getName(MessageEnum.NavigationTaskAllocatorId));
  this.setState({taskViewId:taskId},() =>  this.assignLawyer())
}
if(apiRequestCallId === this.getSearchApiCallId){
  this.handleSearchResults(responseJson);
}
  };

  handleSearchResults = ( responseJson:any) => {
    if (responseJson.data) {
      this.setState({
        associatedLawyer: responseJson.data,
      });
    }else if(responseJson?.message){
      this.setState({
        associatedLawyer: []
      })
    }
  };

  handleApiResponse = (responseJson:any) => {
      if (responseJson.data) {
        this.setState({
          associatedLawyer: responseJson.data,
          loading: false
        });
      }
   
  };
  handlePatchResponse=(responseJson:any)=>{
    if(responseJson){
      this.setState({ openDialog: true ,isAnyLawyerSelected:false});
   
      setTimeout(() => {
        this.setState({ openDialog: false });
      }, 800);
    }
  }
  handleAssign=(responseJson:any)=>{
   
    if (responseJson.data) {
      const selectedIds = responseJson?.data.map((element: any) => (
        element.id
      ))
       this.setState({ selectedLawyers: responseJson.data, selectedLawyersID: selectedIds});
    }
  }

  

  handleLawyerSelect = (associatedId: number) => {
    if(!this.state.updatedSelectedID?.includes(associatedId)&& 
    !this.state.selectedLawyersID?.includes(associatedId)){
    const updated = [...this.state.updatedSelectedID, associatedId];
    this.setState({
      updatedSelectedID: updated,
      isAnyLawyerSelected: updated.length > 0
    })
   }else{
    const removedUpdated = this.state.updatedSelectedID.filter((element) => element !== associatedId);
    this.setState({
      updatedSelectedID: removedUpdated
      ,isAnyLawyerSelected: removedUpdated.length > 0, 
    })
   }
   
  }

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: true });
  }

  handleclosesidebar = () => {
    this.setState({ mobileOpen: false });

  }

  handleClickOpen = () => {
    this.setState({ open: true })

  };

  


  handleSettingIn = async () => {
    const ctoken: string = await getStorageData("clientTokes");

    const message = new Message(getName(MessageEnum.NavigationMessage));

    if (ctoken) {
      message.addData(getName(MessageEnum.NavigationTargetMessage), "ClientSettings");
    } else {
      message.addData(getName(MessageEnum.NavigationTargetMessage), "Settings");
    }

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  handleCloseDelete = () => {
    this.setState({ openDialog: false });
  };
  handleSearchChange = (event: { target: { value: string; }; }) => {
    const query = event.target.value;
    this.setState({ searchQuery: query  }, () => {
      this.getSearchData();
    });
  };
 
  handleSearchChange2 = (newValue: string, actionMeta: any) => {
    if (actionMeta.action === "input-change") {
      this.setState({ selectedLawId: (newValue) }); 
    }
  };
  
  handleSearchCountry = (newValue: string, actionMeta: any) => {
    if (actionMeta.action === "input-change") {
      this.setState({ selectedCountry: newValue });
    }
};
handleSeachYearExp = (newValue:string, actionMeta:any) => {
  if (actionMeta.action === "input-change") {
    this.setState({ searchYearExp: newValue });
  }
};
  
  handleSelected = (selectedOption: any) => {
      this.setState({ 
        selectedOp: selectedOption?.value,
        selectedLawId: selectedOption?.id,
        selectedService: selectedOption?.label,
        isFilterPopupOpen:false},
        () => {
          this.getSearchData(); 
      });
  };
  handleCountrySelected = (selectedOption:any) => {
     
      this.setState({ 
        selectedCountry: selectedOption?.value,
        selectedCountryChip: selectedOption?.label,
        searchCountry: selectedOption ? selectedOption.label : '', 
        isFilterPopupOpen:false}, () => {
          this.getSearchData(); 
        });
  };
  handleSelectedYear = (selectedOption:any) => {
    this.setState({ 
      selectedYearExp: selectedOption?.value,
      searchYearExp: selectedOption?.value,
      searchYearExpChip: selectedOption?.label,
      isFilterPopupOpen:false, 
    }, () => {
      this.getSearchData(); 
    });
  };
  handleRemoveFilter = (filter: string) => {
    if (filter === 'selectedService') {
      this.setState({ selectedService: "", selectedOp: '', searchQuery: '',selectedLawId:"" });
      this.getSearchData()
    }
  };
  handleRemoveCountry = (filter: string) => {
    if (filter === 'selectedCountryChip') {
      this.setState({selectedCountryChip:"",selectedCountry:"",searchCountry:"" });
      this.getSearchData()
    }

  }; 
  handleRemoveYear = (filter: string) => {
    if (filter === 'searchYearExpChip') {
      this.setState({searchYearExpChip:"",searchYearExp:"",selectedYearExp:"" });
      this.getSearchData()
    }
   
  };

  determineMainToken1 = (
    metaSignUpToken: { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string } },
    metaLoginToken: { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string } }
  ): { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string } } | null => {
    if (metaSignUpToken?.data?.id) {
      if (metaLoginToken?.id && metaSignUpToken.data.id !== metaLoginToken.id) {
        return metaLoginToken;
      }
      return metaSignUpToken;
    }

    if (metaLoginToken?.id) {
      return metaLoginToken;
    }

    return null; 
  };
  associatedLawyers = async () => {
    const signupTokens: string = await getStorageData("userdetails");
    
    const loginTokens: string = await getStorageData("token");
    const metaSignUpToken = JSON.parse(signupTokens);
    const metaLoginToken = JSON.parse(loginTokens);

    const maintoken = this.determineMainToken1(metaSignUpToken, metaLoginToken);

    const header: { "Content-Type": string; token: string | undefined } = {
      "token": maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token,
    "Content-Type": "application/json",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAssociatedLawyersId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.associatedLawyersApi}`
    );
 
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  updateLawyers = async () => {
    const signupTokens: string = await getStorageData("userdetails");
    
    const loginTokens: string = await getStorageData("token");
    const metaSignUpToken = JSON.parse(signupTokens);
    const metaLoginToken = JSON.parse(loginTokens);

    const maintoken = this.determineMainToken1(metaSignUpToken, metaLoginToken);

    const header: { "Content-Type": string; token: string | undefined } = {
      "token": maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token,
    "Content-Type": "application/json",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.patchLawyerCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.patchApiAllocator}${this.state.taskViewId}&assigned_lawyer_ids[]=${[...this.state.selectedLawyersID, this.state.updatedSelectedID]}`
    );
 
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  assignLawyer = async () => {
    const signupTokens: string = await getStorageData("userdetails");
    
    const loginTokens: string = await getStorageData("token");
    const metaSignUpToken = JSON.parse(signupTokens);
    const metaLoginToken = JSON.parse(loginTokens);

    const maintoken = this.determineMainToken1(metaSignUpToken, metaLoginToken);

    const header: { "Content-Type": string; token: string | undefined } = {
      "token": maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token,
    "Content-Type": "application/json",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.assignLawyerCallId = requestMessage.messageId;

    requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.assignLawyerApiEndPoint}${this.state.taskViewId}`
        );
 
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getServicesList= async () => {
    const header = {
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getServiceApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_productdescription/service_managements/legal_services_list"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getCountryList= async () => {
    const header = {
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCountryApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account_block/accounts/get_countries"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getExperienceList= async () => {
    const header = {
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getExperienceApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_productdescription/service_managements/year_of_experience"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getSearchData= async() => {
    const signupTokens: string = await getStorageData("userdetails");
    const loginTokens: string = await getStorageData("token");
    const metaSignUpToken = JSON.parse(signupTokens);
    const metaLoginToken = JSON.parse(loginTokens);

    const maintoken = this.determineMainToken1(metaSignUpToken, metaLoginToken);
    const header = {
      "token": maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token,
      "Content-Type": "application/json",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSearchApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_tasks/tasks/lawfirm_lawyers_filter?legel_service_type_id=${this.state.selectedLawId}&jurisdiction=${this.state.selectedCountry}&years_of_experience=${this.state.searchYearExp}&name=${this.state.searchQuery}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
 
  // Customizable Area End
}
