import React from "react";

// Customizable Area Start
import {
  Box,
  TextField,
  InputAdornment,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  Paper,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Chip,
  Grid,
  Drawer,
  Divider,
  Dialog, 
  DialogTitle,
   DialogContent,
    DialogContentText,
    CircularProgress,
    Avatar,
    DialogActions,
      Button,
} from "@material-ui/core";
import { styled } from '@material-ui/core/styles';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CloseIcon from '@material-ui/icons/Close';
import { logo, userpic, right, file, filter, backk ,request,toDo,succesImage} from "./assets";
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowForwardIosOutlinedIcon from "@material-ui/icons/ArrowForwardIosOutlined";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import SearchIcon from '@material-ui/icons/Search';
import RequestManagement from "../../../blocks/requestmanagement/src/RequestManagement.web";
// Customizable Area End

import TaskController, { Props, configJSON } from "./TaskController";

// Customizable Area Start

// Customizable Area End

export default class Task extends TaskController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getSeeMoreButtonText = () => {
    return this.state.isExpanded ? "See less" : "See more...";
  };
  drawer = () => (
    <DrawerBox style={{ display: this.state.mobileOpen ? 'block' : '' }}>
      <div className="toolbar" />
      <Divider />
      <div className="logobox">
        <img src={logo} alt="logo" className="logoimag" data-test-id="logoImg" />
      </div>
      {this.state.mobileOpen && <CloseIcon style={{ position: 'fixed', top: '30px', left: '210px', cursor: 'pointer' }} onClick={this.handleclosesidebar} />}
      <div className="settingbox">
      </div>
      <List className="MainListBox">
        <ListItem onClick={this.handleNavigateDashboard} className="listbox">
          <ListItemIcon><HomeOutlinedIcon className="icondrawer2" /></ListItemIcon>
          <ListItemText  className="listtile" data-test-id="Dashboard">
            Dashboard
          </ListItemText>
        </ListItem>
        <ListItem className="listbox" onClick={this.handleSettingIn}>
          <ListItemIcon><SettingsOutlinedIcon className="icondrawer2" /></ListItemIcon>
          <ListItemText className="listtile" data-test-id="Setting">
            Settings
          </ListItemText>
        </ListItem>
      </List>
      <Box className="userProfile">
        <Box><img src={userpic} className="imageBox" /></Box>
        <Box>
          <Typography className="userName">sass</Typography>
          <Typography className="userContent">Side subnav item</Typography>
        </Box>
      </Box>
    </DrawerBox>
  )
  buttonMenu = () => (
    <ButtonBox className="buttonbox">
      <IconButton
        className="iconbtn"
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={this.handleDrawerToggle}
        style={{ marginRight: '24px', color: '#000' }}
      >
        <MenuIcon />

      </IconButton>
    </ButtonBox>
  )
  sidebarr = () => {
    return (this.state.mobileOpen &&
      <>
        <div className="offcanvascss" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
          {this.drawer()}
        </div>
      </>

    )
  }
 
  getTaskStatus = (state: string) => {
    switch (state) {
      case 'new_task':
        return {
          label: 'NEW',
        
          style: {
            backgroundColor: "#D1FAE5",
            color: "#059669",
            fontWeight: 700,
            fontSize: "10px",
            width: '102px',
            height: '22px',
            borderRadius: '40px',
            padding: '11px 8px 8px 8px',
          },
        };
      case 'pending':
        return {
          label: 'PENDING',
          className: 'chipPending',
          style: {
            backgroundColor: "#DCECFF",
            color: "#355EE7",
            width: '102px',
            height: '22px',
            borderRadius: '40px',
            padding: '11px 8px 8px 8px',
            fontWeight: 700,
            fontSize: "10px",
        }
      };
      case 'in_progress':
        return {
          label: 'PROGRESS',
          className: 'chipInProgress',
          style: {
            backgroundColor: "#FEF3C7",
            color: "#D97706",
            width: '102px',
            height: '22px',
            borderRadius: '40px',
            padding: '11px 8px 8px 8px',
            fontWeight: 700,
            fontSize: "10px",
          },
        };
      case 'closed':
        return {
          label: 'CLOSED',
          className: 'chipClosed',
          style: {
            backgroundColor: "#FEE2E2",
            color: "#DC2626",
            width: '102px',
            height: '22px',
            borderRadius: '40px',
            padding: '11px 8px 8px 8px',
            fontWeight: 700,
            fontSize: "10px",
          },
        };
      default:
        return {
          label: 'NO STATUS',
          className: 'chipNoStatus',
          style: {
            backgroundColor: "#E2E3E5",
            color: "#6C757D",
            fontWeight: 700,
            fontSize: "10px",
          },
        };
    }
  };
  sideBarr2 = () => {
    return (
      this.state.mobileOpen && <div className="offcanvasbackdrop" onClick={this.handleclosesidebar}></div>
    )
  }

  formatDate = () => {
    const createdAt = this.state?.taskView?.attributes?.created_at;
    const formattedDate = {createdAt}
      ? new Date(createdAt).toLocaleDateString('en-GB', {
          day: 'numeric',
          month: 'long',
          year: 'numeric',
        })
      : '';
    return formattedDate;
  };
  seeMore = () => {
    const description = this.state.taskView?.attributes?.description;
    return this.checkIfContentIsLong(description); 
  };
  shouldShowSeeMoreButton = () => {
    const description = this.state.taskView?.attributes?.description;
    return this.checkIfContentIsLong(description);
  };

  checkIfContentIsLong =  (description: string) => {
    const maxLength = 200; 
    return description && description.length > maxLength;
  };
  fullText = () => {
    const desc = this.state.taskView?.attributes?.description;
    return desc;
  };
  renderDescription = () => {
    const fullText = this.fullText();
    
    if (this.state.isExpanded) {
      return fullText;
    }
  
    const truncatedText = `${fullText?.substring(0, 200)}...`;
    return (
      <>
        {truncatedText}
        {this.shouldShowSeeMoreButton() && this.renderSeeMoreButton()}
      </>
    );
  };
  
  renderSeeMoreButton = () => (
    <button
      onClick={this.handleSeeMoreClick}
      style={{
        color: "#355EE7",
        border: "none",
        background: "white",
        cursor: "pointer",
        fontSize: "11px",
        fontWeight: 400,
        position: "absolute",
        bottom: "2px",
        right: "-8px",
        padding: "3px",
        zIndex: 1,
        width: "12%",
        display: "flex",
        justifyContent: "flex-start",
      }}
    >
      {this.getSeeMoreButtonText()}
    </button>
  );

  getStatus = (task:any) => {
    return task?.attributes?.status?.replace(/_/g, " ") || "unknown_status";
  };
  getStatus2 = () => {
    return this.state?.taskView?.attributes?.status?.replace(/_/g, " ") || "unknown_status";
  };
  renderTableRows = () => {
    const { loading,todoList} = this.state;
    
    const formatDate = (dateString:string) => {
      if (!dateString) return ''; 
      return new Date(dateString).toLocaleDateString('en-GB');
    };
    if (loading) {
      return (
        <TableRow>
          <TableCell colSpan={7} align="center">
            <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
              <CircularProgress />
            </Box>
          </TableCell>
        </TableRow>
      );
    }

    if (todoList.length === 0) {
      return (
        <TableRow>
          <TableCell colSpan={7} align="center">
            <Box style={{
              display: "flex",
              justifyContent: "flexStart",
              height: "auto",
              alignItems: "center",
              marginLeft:"1.6%"
            }}>
              <Typography style={{
                textAlign: 'center',
                padding: '2px',
                display: "flex",
                flexDirection: "column",
                fontSize: "12px",
          fontFamily: "Dm sans",
          color: "#8C8C8C",
              }}>
               No To-Dos created, click add to-do to add a row
              
              </Typography>
            </Box>
          </TableCell>
        </TableRow>
      );
    }
    return todoList?.map((task, id) => (
      <TableRow  
        component={Paper}
        elevation={5}
        style={{
          borderSpacing: '0px !important',
          borderCollapse: 'unset',
          boxShadow:"none"
        }}
        key={id} >
        <TableCell style={{
          paddingLeft:"3.6%",
          fontFamily: "Dm sans",
        }}>
        
          {task.attributes?.to_do_name}
        </TableCell>
        <TableCell style={{
          fontFamily:"DM sans"
        }}>
          <Typography style={{
            display: "flex",
            gap: "6px",
            alignItems: "center",
            fontSize: "12px",
            fontWeight: 400,
            fontFamily: "Dm sans",
            color: "#0F172A",
            textTransform: "capitalize",
          }}>
           {formatDate(task.attributes?.deadline)} <CalendarTodayIcon style={{
        width:"16px",
        height:"16px",
        color:"#BFBFBF",
        marginBottom:"1.5px",
        marginRight:"6px"
      }}/>
          </Typography>
        </TableCell>
        <TableCell
         style={{
          fontFamily:"DM sans"
        }}
          key={task.attributes.id}
        >
          {task.attributes?.status === 'Completed' && (
          <Chip
            label='COMPLETED'
            className={'chipNew'}
            style={{ width: 'auto',
              height: '22px',
              borderRadius: '40px',
              padding: '11px 8px 8px 8px',
              backgroundColor: "#D1FAE5",
              color: "#059669",
              fontWeight: 700,
              fontSize: "10px" }} />
        )}
        {task.attributes?.status === 'pending' && (
          <Chip
            label='PENDING'
            className={'chipPending'}
            style={{
              width: 'auto',
              height: '22px',
              borderRadius: '40px',
              padding: '11px 8px 8px 8px',
              backgroundColor: "#DCECFF",
              color: "#355EE7",
               fontWeight: 700,
                fontSize: "10px"}} />
        )}
        {task.attributes?.status === 'Inprogress' && (
          <Chip
            label='IN PROGRESS'
            className={'chipInProgress'}
            style={{
              width: 'auto',
              height: '22px',
              borderRadius: '40px',
              padding: '11px 8px 8px 8px',
              backgroundColor: "#FEF3C7",
              color: "#D97706",
              fontWeight: 700,
              fontSize: "10px" }} />
        )}
     

        </TableCell>
        <TableCell
          style={{
            fontSize: "12px",
            fontWeight: 400,
            fontFamily: "Dm sans",
            color: "#0F172A;",
            whiteSpace: "nowrap",
            overflow: "hidden",
            maxWidth: "100px",
            textTransform: "capitalize"
          }}
        >
            {task.attributes?.priority === 'Medium' && (
          <Chip
            label='MEDIUM'
           style={{
            backgroundColor: "#FFD7B9",
    color: "#FF6C29",
    fontWeight: 700,
    fontSize: "10px",
    width: 'auto',
     height: '22px',
    borderRadius: '40px',
    padding: '11px 8px 8px 8px',
           }} />
        )}
        {task.attributes?.priority === 'High' && (
          <Chip
            label='HIGH'
            className={'chipHigh'}
            style={{
              width: 'auto',
              height: '22px',
              borderRadius: '40px',
              padding: '11px 8px 8px 8px',
              backgroundColor: "#FEE2E2",
    color: "#DC2626",
    fontWeight: 700,
    fontSize: "10px"}} />
        )}
        {task.attributes?.priority === 'Low' && (
          <Chip
            label='LOW'
            className={'chipLow'}
            style={{
              width: 'auto',
              height: '22px',
              borderRadius: '40px',
              padding: '11px 8px 8px 8px', 
              backgroundColor: "#F0F0F0",
              color: "#262626",
              fontWeight: 700,
              fontSize: "10px"}} />
        )}
        </TableCell>
        <TableCell
          style={{
            fontSize: "12px",
            fontWeight: 400,
            fontFamily: "Dm sans",
            color: "#0F172A;",
            whiteSpace: "nowrap",
            overflow: "hidden",
            maxWidth: "100px",
            textTransform: "capitalize",
        
          }}
        >
      {task?.attributes?.assigned_lawyers?.data?.length > 0 ? (
  task.attributes.assigned_lawyers.data.map((lawyer, index) => (
    <div key={index} style={{ display: "flex", alignItems: "center", gap: "6px" }}>
      <Avatar
        style={{
          width: "23px",
          height: "23px",
          borderRadius: "50%",
          fontSize: "14px",
          padding: "3px",
        }}
        src={
          lawyer.attributes.associated_lawyer_details.data.attributes.profile_image?.url
        }
        alt={
          lawyer.attributes.associated_lawyer_details.data.attributes.first_name || "Lawyer"
        }
      />
      <span
        style={{
          fontWeight: 400,
          fontFamily: "DM sans",
          color: "#0F172A",
          fontSize: "14px",
          gap:"3px"
        }}
      >
        {lawyer?.attributes.associated_lawyer_details.data.attributes.first_name} {lawyer?.attributes.associated_lawyer_details.data.attributes.last_name}
      </span>
    </div>
  ))
) : (
  <span> </span>
)}
        </TableCell>
        <TableCell style={{
          fontFamily:"DM sans"
        }}>
        <IconButton style={{
          width: "24px",
          height: "24px",
          color: "#355EE7"
        }}>
            <ArrowForwardIosOutlinedIcon />
          </IconButton>
   
        </TableCell>
      </TableRow>
    ));
  };
  drawerContents = () => (
    <Box
      style={{
        width: "100vh",
        maxWidth: "812px",
        padding: "16px",
        paddingLeft:"31px",
        height: "auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        fontSize: "Dm sans",
      }}
    >

      <Grid
        container
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Box>
          <IconButton style={{
            cursor:"pointer"
          }} onClick={this.toggleDrawerClose}>
          <img src={backk} alt="" style={{
            width:"18px",
            height:"18px"
          }} />
          </IconButton>
        </Box>
        <Box style={{ display: "flex", alignItems: "center" }}>
          <Typography style={{ color: "#8C8C8C",fontSize:"14px",fontWeight:400,fontStyle:"Dm sans" }}>Created: {this.formatDate()}</Typography>

          <IconButton
            className={"closeButton"}
            onClick={this.toggleDrawerClose}
          >
            <CloseIcon  style={{
              color:"#BFBFBF",
              width:"18px",
              height:"18px"
            }}  />
          </IconButton>
        </Box>
      </Grid>

      <Box style={{ width: "100%", height: "105px", gap: "12px" }}>
      <Chip
   label={this.getTaskStatus(this.state.taskView?.attributes?.state).label}
  style={this.getTaskStatus(this.state.taskView?.attributes?.state).style}
/>


        <Grid container style={{ display: "flex", marginTop: "30px" }}>
          <Grid item>
            <img src={file} alt="file" />
          </Grid>
          <Grid item style={{ marginLeft: '10px' }}>
            <Typography
              variant="body1"
              style={{
                fontFamily: "DM sans",
                fontSize: "18px",
                fontWeight: "400",
                lineHeight: "23.44px",
                textAlign: "left",
                whiteSpace: "nowrap", 
                overflow: "hidden",  
                textOverflow: "ellipsis", 
                maxWidth: "calc(10ch + 1em)",
                textTransform:"capitalize"
              }}
              title={this.state.taskView?.attributes?.task_name}  
            >
              {this.state.taskView?.attributes?.task_name}
            </Typography>
            <Typography variant="body2" >
             
              <Chip
            label={this.getStatus2().replace(/_/g, " ")}
       
          style={{
            height: '22px',
            borderRadius: '40px',
            fontFamily: "DM sans",
            padding: '9px 0px 8px',
            backgroundColor: "#FAFAFA",
            color: "black",
            fontWeight: 400,
            fontSize: "10px",
            textTransform: "uppercase",
            width: '154px',
            display: "flex",
            justifyContent: "flex-start"

          }}
        />
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box
        style={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
        }}
      >
        <Box style={{ margin: "8px 0" }}>
          <Typography style={{ color: "#8C8C8C",fontSize:"12px",fontWeight:400,fontStyle:"Dm sans" }}>
            Trademark Name
          </Typography>
          <Typography  
          title={this.state.taskView?.attributes?.trademark_name}  
          style={{fontSize:"16px",fontWeight:500,fontStyle:"Dm sans" ,
              whiteSpace: "nowrap",
              overflow: "hidden",  
              textOverflow: "ellipsis", 
              maxWidth: "calc(10ch + 1em)",
              textTransform:"capitalize"
          }}>
            {this.state.taskView?.attributes?.trademark_name}</Typography>
        </Box>
        <Box style={{ margin: "8px 0" }}>
          <Typography style={{ color: "#8C8C8C",fontSize:"12px",fontWeight:400,fontStyle:"Dm sans" }}>
            Trademark Application Number
          </Typography>

          <Typography 
            title={this.state.taskView?.attributes?.trademark_application_number}  
           style={{fontSize:"16px",fontWeight:500,fontStyle:"Dm sans" ,textTransform:"capitalize"}}>
            {this.state.taskView?.attributes?.trademark_application_number}</Typography>
        </Box>
        <Divider style={{
          margin:"16px 0px"
        }}/>
        <Box style={{ margin: "8px 0" }}>
          <Typography style={{ color: "#8C8C8C",fontSize:"12px",fontWeight:400,fontStyle:"Dm sans" }}>
            Jurisdiction
          </Typography>
          <Typography
            style={{fontSize:"16px",fontWeight:500,fontStyle:"Dm sans" }}
            >{this.state.taskView?.attributes?.jurisdiction}</Typography>
        </Box>
        <Box style={{ margin: "8px 0" }}>
          <Typography style={{ color: "#8C8C8C",fontSize:"12px",fontWeight:400,fontStyle:"Dm sans" }}>
            Legal Service Type
          </Typography>
          <Typography   style={{fontSize:"16px",fontWeight:500,fontStyle:"Dm sans" ,textTransform:"capitalize"}}
          >{this.state.taskView?.attributes?.legel_service_type?.name}</Typography>
        </Box>
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            margin: "8px 0",
          }}
        >
          <Typography
           style={{ color: "#8C8C8C",fontSize:"12px",fontWeight:400,fontStyle:"Dm sans" }}
          >
            Deadline:
          </Typography>
          <Box style={{ display: "flex" ,gap:"4px"}}>
            <Typography   style={{fontSize:"16px",fontWeight:500,fontStyle:"Dm sans" }}>
              18/09/2024
            </Typography>
            <Box style={{ color: "#8C8C8C"}}>
              <CalendarTodayIcon style={{
                color:"#BFBFBF",
                width:"18px",
                height:"18px"
              }}/>
            </Box>
          </Box>
        </Box>
        <Divider style={{
          margin:"16px 0px"
        }}/>
   <Box style={{ width: "100%", marginTop: "16px", position: 'relative' }}>
  <Typography
    style={{
      color: "#8C8C8C",
      fontSize: "12px",
      fontWeight: 400,
      fontStyle: "Dm sans",
    }}
  >
    Description
  </Typography>
  
  <Typography
    variant="subtitle2"
    style={{
      color: "#8C8C8C",
      marginLeft: 4,
      display: "flex",
      justifyContent: "flex-end",
      paddingRight: "1vw",
      textTransform: "capitalize",
    }}
  >
    {this.formatDate()}
  </Typography>
  
  <Typography
  style={{
    fontSize: "16px",
    fontWeight: 500,
    fontFamily: "Dm sans",
    wordWrap: 'break-word',
    whiteSpace: 'normal',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: this.state.isExpanded ? 'none' : 2,
    position: "relative", 
  }}
  className="description-text"
>
  {this.renderDescription()}
</Typography>

</Box>



        <Box style={{ width: "100%", marginTop: "16px" }}>
          <Typography
            style={{ color: "#8C8C8C",fontSize:"12px",fontWeight:400,fontStyle:"Dm sans" }}
          >
            Attachments
          </Typography>
          <Box onClick={()=>this.handleDocumentFolderNavigation(this.state.taskView?.id)}
            style={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "#4872FF",
              marginTop: "16px",
              width: "30%",
              color: "white",
              padding: "10px",
              borderRadius: "12px",
            }}
          >
            <AttachFileIcon style={{ marginRight: 8 }} />
            <Typography >Documents Folder</Typography>
          </Box>
          {this.state.taskView?.attributes?.status === "assigned" && (
      <>
       <Divider style={{
  marginBottom:"21px",
  marginTop:"21px"
 }}/>
           <Box className="userdetailbox">
            <Box style={{
              display:"flex",
              justifyContent:"space-between",
              alignItems:"center"
            }}>
            <Typography style={{
                  fontSize: "18px",
                  fontFamily: "Dm sans",
                  fontWeight: 700,
                }}>To-Dos List</Typography>
                <Typography onClick={this.handleNavigationProjectTrack} style={{
          display: "flex",
          alignItems: "center",
          gap: "4px",
          cursor: "pointer",
          marginTop: "18px"
        }}>
          <span style={{
            fontSize: "12px",
            fontWeight: 400,
            fontFamily: "Dm sans",
            color: "#355EE7"
          }}>
            See all
          </span>
          <img src={request} alt="" />
        </Typography>
            </Box>
               
                <TableContainer
                  component={Paper}
              
                  style={{
                    borderRadius: '12px',
                    border: '1px solid #F0F0F0',
                    marginTop: '25px',
                    boxShadow: 'none',
                    height:"120px"
                  }}
                >
                  <Table >
                    <TableHead>
                      <TableRow>
                      
                        <TableCell
                          style={{
                            color: '#8C8C8C',
                            fontSize: '12px',
                            fontWeight: '500',
                            display:"flex",
                            gap:"9px"
                         
                          }}
                        >   <CheckCircleOutlineIcon style={{marginLeft:"10px",width:"24px",height:"24px",color:"BFBFBF",marginBottom:"2px"}} />
                          To-Do Name
                        </TableCell>
                        <TableCell
                          style={{
                            color: '#8C8C8C',
                            fontSize: '12px',
                            fontWeight: '500',
                     
                          }}
                        >
                          Due Date
                        </TableCell>
                        <TableCell style={{
                          color: "#8C8C8C",
                          fontSize: "12px",
                          fontWeight: "500",
                       
                        }}>Status</TableCell>
                        <TableCell style={{
                          color: "#8C8C8C",
                          fontSize: "12px",
                          fontWeight: "500",
                       
                        }}>Priority</TableCell>
                        <TableCell style={{
                          color: "#8C8C8C",
                          fontSize: "12px",
                          fontWeight: "500",
                         
                        }}>Assignee</TableCell>
                         <TableCell style={{
                          width:"4px"
                         }}></TableCell>
                       
                      
                      </TableRow>
                    </TableHead>
                    <TableBody style={{
                        height:"2%",
                        overflowY:"scroll"
                        
                    }}
                    className="scrollable" >
                 {this.renderTableRows()}
                    </TableBody>
                    </Table>
                 
                </TableContainer>
              </Box>
              <Box style={{
                display:"flex",
                justifyContent:"flex-end",
                marginTop:"3%"

              }}>
              
              <button onClick={this.handleNavigationProjectTrack}
                style={{
                  backgroundColor:  "#355EE7", 
                  color:  "white" , 
                  width: "145px",
                  height: '44px',
                  textTransform: "none" as "none",
                  borderRadius: "8px",
                  fontSize: "16px",
                  fontWeight: "400",
                  fontFamily: "DM Sans",
                  cursor: "pointer",
                  border: 'none',
                 gap:"3px",
                 display:"flex",
                 justifyContent:"center",
                 alignItems:"center",
                }}
                 data-test-id="signup" className="landingSignupBtn"  >
                  <img style={{width:"18px",height:"18px"}} src={toDo} alt="" />  Add to-do
                </button>
             
              </Box>
            </>
                )}
          {this.state.isTaskPosted  && (
           <>
          <Divider style={{marginTop:"20%",}}/>
        <Box style={{
         
          display: "flex",
          justifyContent: "flex-end",
          marginTop:"10px",
          top: "94%",
          right: "2%",
          gap:"12px"
        
        }} >
            <button  style={{
         
         color: "#F87171",
         width: "145px",
         height: '44px',
         textTransform: "none" as "none",
         borderRadius: "8px",
         fontSize: "16px",
         fontWeight: "400",
         fontFamily: "DM Sans",
         cursor: "pointer",
         border:"1px solid #F87171",
         backgroundColor:"white",
      
        }} onClick={this.handleRejectTask} >
          Reject Task
        </button>
        <button onClick={this.handleAcceptTask} style={{
         backgroundColor: "#355EE7",
         color: "white",
         width: "145px",
         height: '44px',
         textTransform: "none" as "none",
         borderRadius: "8px",
         fontSize: "16px",
         fontWeight: "400",
         fontFamily: "DM Sans",
         cursor: "pointer",
         border: '1px'
        }}>
       Accept Task
        </button>
           </Box>
           </>
          )}
              {this.state.taskView?.attributes?.status === "assigned" && (
  <>
    <Divider style={{ marginTop: "3%" }} />
    <Box
      style={{
        display: "flex",
        justifyContent: "flex-end",
        marginTop: "10px",
        top: "94%",
        right: "2%",
        gap: "12px",
      }}
    >
      <button
        onClick={this.handleAcceptTodo}
        style={{
          backgroundColor: "#355EE7",
          color: "white",
          width: "145px",
          height: "44px",
          textTransform: "none",
          borderRadius: "8px",
          fontSize: "16px",
          fontWeight: "400",
          fontFamily: "DM Sans",
          cursor: "pointer",
          border: "1px",
        }}
      >
        Finish Task
      </button>
    </Box>
  </>
)}


        </Box>


      </Box>
     

    </Box>
  );
  renderTableContent = () => {
    const { loading, taskProfile } = this.state;

    if (loading) {
      return (
        <TableRow>
          <TableCell colSpan={7} align="center">
            <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
              <CircularProgress />
            </Box>
          </TableCell>
        </TableRow>
      );
    }

    if (taskProfile.length === 0) {
      return (
        <TableRow>
          <TableCell colSpan={7} align="center">
            <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
              <Typography style={{ textAlign: 'center', padding: '20px', fontWeight: 'bold', display: "flex", flexDirection: "column" }}>
                No current tasks
                <span style={{
                  fontSize: "12px",
                  fontWeight: "normal",
                  color: "grey",
                  marginTop: "10px"
                }}>When you create a new task, you can find it here</span>
              </Typography>
            </Box>
          </TableCell>
        </TableRow>
      );
    }

    return taskProfile?.map((task, index) => (
      <TableRow
        component="tr"
        key={index}
        style={{
          borderSpacing: '0px !important',
          borderCollapse: 'unset',
        }}
        data-test-id={`handleClick-${index}`}
        onClick={() => this.toggleDrawer(task)} 
      >
        <TableCell
          style={{
            width: "12%",
            fontSize: "12px",
            fontWeight: 700,
            fontFamily: "Dm sans",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "calc(17ch + 1em)",
            textTransform: "capitalize"
          }}
          className="tableFont"
          title={task.attributes.task_name}
        >
          {task.attributes.task_name}
        </TableCell>

        <TableCell
          style={{
            width: "15%",
            fontSize: "12px",
            fontWeight: 400,
            fontFamily: "Dm sans",
            color: "#0F172A",
            textTransform: "capitalize"
          }}
        >
          {task.attributes.legel_service_type.name}
        </TableCell>

        <TableCell
          title={task.attributes.trademark_name}
          style={{
            width: "15%",
            fontSize: "12px",
            fontWeight: 400,
            fontFamily: "Dm sans",
            color: "#0F172A",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "calc(17ch + 1em)",
            textTransform: "capitalize"
          }}
        >
          {task.attributes.trademark_name}
        </TableCell>

        <TableCell
          title={task.attributes.trademark_application_number}
          style={{
            width: "13.6%",
            fontSize: "12px",
            fontWeight: 400,
            fontFamily: "Dm sans",
            color: "#0F172A",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "100px",
            textTransform: "capitalize"
          }}
        >
          {task.attributes.trademark_application_number}
        </TableCell>

        <TableCell style={{ width: "14.8%" }}>
          {task.attributes.state === 'new_task' && (
            <Chip
              label='NEW'
              className={'chipNew'}
              style={{
                width: '102px',
                height: '22px',
                borderRadius: '40px',
                padding: '11px 8px 8px 8px',
              }}
            />
          )}
          {task.attributes.state === 'pending' && (
            <Chip
              label='PENDING'
              className={'chipPending'}
              style={{
                width: '102px',
                height: '22px',
                borderRadius: '40px',
                padding: '11px 8px 8px 8px',
              }}
            />
          )}
          {task.attributes.state === 'in_progress' && (
            <Chip
              label='IN PROGRESS'
              className={'chipInProgress'}
              style={{
                width: '102px',
                height: '22px',
                borderRadius: '40px',
                padding: '11px 8px 8px 8px',
              }}
            />
          )}
          {task.attributes.state === 'closed' && (
            <Chip
              label='CLOSED'
              className={'chipClosed'}
              style={{
                width: '102px',
                height: '22px',
                borderRadius: '40px',
                padding: '11px 8px 8px 8px',
              }}
            />
          )}
        </TableCell>

        <TableCell style={{ width: "24%" }}>
          <Chip
            label={this.getStatus(task).replace(/_/g, " ")}
            className="chipStatus"
            style={{
              textTransform: "uppercase"
            }}
          />
        </TableCell>

        <TableCell>
          <IconButton className={'iconButton'}>
            <ArrowForwardIosOutlinedIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    ));
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    return(
    <>
    <MainDiv data-test-id="projectTemplatesProfiles" style={{
      maxHeight: this.state.mobileOpen ? '100vh' : '',
      overflow: this.state.mobileOpen ? "hidden" : ""
    }}>
      <Box className="sidebar">
        {this.drawer()}
        {this.buttonMenu()}
        {this.sidebarr()}
      </Box>
      {this.sideBarr2()}
      <Box className="rightbar">
        <Box className="topbox">
        <RequestManagement projectId={this.state.taskView.id} handleUpdateCall={()=>{}} openDialogLawyer={this.state.accepted} taskId={this.state.taskViewId} navigation={this.props.navigation} id={""} opendialog={this.state.acceptTask} handleClose={this.handleClosePopup} rejectDialog={false} handleRejectClose={undefined} />
        <RequestManagement projectId={this.state.taskView.id} handleUpdateCall={()=>{}}  taskId={this.state.taskViewId} navigation={this.props.navigation} id={""} rejectDialog={this.state.rejectTask} handleRejectClose={this.handleClosed} opendialog={false} openDialogLawyer={false} handleClose={undefined} />
        
          <Typography data-test-id="backbtn" className="titleuser2">
            <img src={file} className="imageBox2" alt="" /> Tasks
          </Typography>
        </Box>
        <Box style={{
          width: "92.5%",
          display: "flex",
          marginLeft: "60px",
          marginBottom: "30px",
          marginTop: "20px",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: "10px"
        }}>

          <TextField
            variant="outlined"
            placeholder="Search by task name or trademark name or number "
            style={{
              width: "100%"
            }}
            InputProps={{
              style: {
                height: '44px',
                borderRadius: '12px',
                fontSize: '16px',
                fontWeight: 400,
              },
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{
                    color: "#BFBFBF",
                    width: "24px",
                    height: "24px"
                  }} />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <img src={filter} alt="" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />


        </Box>

        <Box className="userdetailbox">
          <Typography style={{
            fontSize: "18px",
            fontFamily: "Dm sans",
            fontWeight: 700,
          }}>All tasks</Typography>
          <TableContainer
            component={Paper}
            elevation={0}
            style={{
              borderRadius: '12px',
              border: '1px solid #F0F0F0',
              marginTop: '25px',
              boxShadow: 'none',
            }}
          >
            <Table
              style={{
                borderSpacing: '0',
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      color: '#8C8C8C',
                      fontSize: '12px',
                      fontWeight: '500',
                      width: "11.8%"
                    }}
                  >
                    Task Name
                  </TableCell>
                  <TableCell
                    style={{
                      color: '#8C8C8C',
                      fontSize: '12px',
                      fontWeight: '500',
                      width: "15%"
                    }}
                  >
                    Legal Service Type
                  </TableCell>
                  <TableCell style={{
                    color: "#8C8C8C",
                    fontSize: "12px",
                    fontWeight: "500",
                    width: "15%"
                  }}>Trademark Name</TableCell>
                  <TableCell style={{
                    color: "#8C8C8C",
                    fontSize: "12px",
                    fontWeight: "500",
                    width: "14%"
                  }}>Trademark Number</TableCell>
                  <TableCell style={{
                    color: "#8C8C8C",
                    fontSize: "12px",
                    fontWeight: "500",
                    width: "15%"
                  }}>Task State</TableCell>
                  <TableCell className={'tableHeader'}>Task Status</TableCell>
                  <TableCell className={'tableHeader'}></TableCell>
                  <TableCell className={'tableHeader'}></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
            </Table>
            <Box
              className="scrollable-box"
            >
              <Table style={{ tableLayout: 'auto' }}>

                <TableBody >
                {this.renderTableContent()}
                </TableBody>

              </Table>
            </Box>
          </TableContainer>
          <Drawer
            anchor="right"
            open={this.state.drawerOpen}
            onClose={this.toggleDrawerClose}
          >
            {this.drawerContents()}
          </Drawer>
        </Box>
      </Box>
      <Dialog
        PaperProps={{
          style: {
            width: '600px',
            maxWidth: '600px',
            height: "239px",
            borderRadius: "12px",
            padding: "12px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }
        }} style={{}} open={this.state.openDialog} onClose={this.handleCloseDelete}
        aria-labelledby="responsive-dialog-title" >

        <Box style={{
          border: "1px solid #D1FAE5",
          width: "576px",
          height: "215px",
          borderRadius: "8px",

        }}>
          <DialogTitle id="responsive-dialog-title" style={{
            textAlign: 'right',
            padding: "7px"
          }}><CloseIcon style={{ cursor: 'pointer' }} onClick={this.handleCloseDelete} /></DialogTitle>
          <DialogContent
            style={{
              padding: "0px"
            }}
          >
            <DialogContentText  >
              <Typography style={{
                backgroundColor: '#ffffff',
                color: '#0F172A',
                fontSize: '19px',
                fontWeight: 400,
                fontFamily: 'DM Sans',
                marginBottom: '20px',
              }}>
                <Box style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}>
                  <img src={right} className="imageBox2" alt="success" />
                </Box>
                <Typography style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "25px",
                  fontSize: "22px",
                  fontFamily: "Dm sans",
                  fontWeight: 400
                }}>
                </Typography>
              </Typography>
            </DialogContentText>
          </DialogContent></Box>


      </Dialog>

      {this.state.acceptTodo && (
            <CustomizeDialog
              data-test-id="customizeDialog" >
              <Box style={{
                border: "1px solid #FEE2E2",
                borderRadius: "8px",
                height: "296px",
                padding: "5px",
                fontFamily: "Dm sans",
                fontWeight: 500,
                fontSize: "22"
              }}>
                <Typography style={{
                  display: "flex",
                  height: "30px"
                }}><IconButton
                  onClick={this.handleCloseTodo}
                  style={{
                    marginLeft: "auto",
                    marginBottom: "8%",
                    width: "24px",
                    height: "24px"
                  }}>
                    <CloseIcon /></IconButton>
                </Typography> <Typography style={{
                  display: "flex",
                  justifyContent: "center",
                  height: "121px"
                }}> <img style={{
                  width: "90px",
                  height: "90px"
                }} src={succesImage} alt="" /> </Typography>
                <Box style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom:"2%"
                }}>
                  <Typography data-test-id="accept1" style={{
                    fontSize: "18px",
                    fontWeight: 400,
                    color: "black ",
                    fontFamily: "Dm sans",
                    height: "52px",
                    display:"flex",
                    flexDirection:"column"
                  }}>
                    {this.state.todoList.every(
  (task: any) => task.attributes.status === "Completed"
) ? (
  <>
    <Typography
      style={{
        display: "flex",
        justifyContent: "center",
        fontWeight: 400,
        fontSize: "18px",
      }}
    >
      Are you sure you want to finish this task? This action will
    </Typography>
    <Typography
      style={{
        display: "flex",
        justifyContent: "center",
        fontWeight: 400,
        fontSize: "18px",
      }}
    >
      close the case and notify the client.
    </Typography>
  </>
) : (
  <>
    <Typography
      style={{
        display: "flex",
        justifyContent: "center",
        fontWeight: 400,
        fontSize: "18px",
      }}
    >
      Are you sure you want to finish this task? There are still some todos
      left.
    </Typography>
    <Typography
      style={{
        display: "flex",
        justifyContent: "center",
        fontWeight: 400,
        fontSize: "18px",
      }}
    >
      This action will close the case and notify the employees.
    </Typography>
  </>
)}
                  </Typography>
                </Box>

                <DialogActions style={{
                  width: "98%",
                  display: "flex",
                  justifyContent: "center"
                }}>
                  <Button
                    onClick={this.handleCloseTodo}
                    data-test-id="cancelBtn2"
                    style={{
                      border: "1px solid #8C8C8C",
                      alignItems: "center",
                      borderRadius: "8px",
                      fontSize: "16px",
                      fontWeight: 700,
                      fontFamily: "Dm sans",
                      color: "#8C8C8C",
                      textTransform: "none",
                      width: "250px",
                      height: "44px",
                      display: "flex",
                    }}>
                    Cancel</Button><Button
                      data-test-id="acceptBtn-1"
                     onClick={this.finishTodo}
                      style={{
                        backgroundColor: "#355EE7 ",
                        color: "white",
                        borderRadius: "8px",
                        alignItems: "center",
                        fontSize: "16px",
                        fontWeight: 700,
                        fontFamily: "Dm sans",
                        textTransform: "none",
                        width: "250px",
                        height: "44px",
                        display: "flex",
                      }}>
                    Finish Task </Button></DialogActions> </Box>

            </CustomizeDialog>)}
    </MainDiv>
    </>)
    // Customizable Area End
  }
}

// Customizable Area Start
const MainDiv = styled('div')({
  "& .description-text":{

  },
  "& .drawerResp": {
    width: "100vh",
    maxWidth: "812px",
    padding: "16px",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    fontSize: "Dm sans",
    "@media (max-width:900px)": {
      width: "30vh",
    },
  },
  "& .scrollable-box":{
      height: '54.5%',
      overflowY: 'auto',
      display: 'block',
      marginBottom: "10px",
      flexGrow: 1,
      paddingRight: '0px',
      "@media (max-width:800px)": {
        display: 'inline-block',
      },
  },  "& .scrollable-box::-webkit-scrollbar": {
    width: "8px", 
  },
  "& .scrollable-box::-webkit-scrollbar-track": {
    background: "#f1f1f1", 
  },
  "& .scrollable-box::-webkit-scrollbar-thumb": {
    backgroundColor: "#91AAFF",
    borderRadius: '10px',
    border: '2px solid #f1f1f1', 
  },
  "& .scrollable-box::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#5D85FF",
  },
  "& .tableFont": {
    width: "12%",
    fontSize: "12px",
    fontWeight: 700,
    fontFamily: "Dm sans"
  },
  "& .custom-datepicker-input": {
    width: "100%",
    border: "1px solid #C3D1FF",
    borderRadius: "12px",
    fontSize: "16px",
    fontWeight: 400,
    outline: "none",
    height: "44px",
    padding: "10px",
  },
  "& .custom-datepicker-input.error": {
    border: "1px solid red"
  },

  "& .custom-datepicker-input::placeholder ": {
    color: '#BFBFBF',
    fontFamily: 'DM Sans',
    fontSize: "16px",
    fontWeight: 400
  },
  "& .offcanvascss": {
    position: 'fixed',
    top: '0px !important',
    bottom: '0px !important',
    left: '0% !important',
    width: '252px !important',
    height: '100vh !important',
    backgroundColor: '#fff',
    zIndex: 9999,
  },
  "& .offcanvasbackdrop": {
    position: "fixed",
    top: '0px',
    left: '252px',
    width: 'calc(100% - 252px)',
    height: '100vh',
    zIndex: 999,
    backgroundColor: '#000',
    opacity: 0.5,
  },
  width: '100%',
  height: '100%',
  display: 'flex',
  "@media (max-width:991px)": {
    display: "block",
  },
  "& .selectCountry": {
    width: '100%',
    border: '1px solid #C3D1FF', borderRadius: "12px",
    marginBottom: '8px', outline: 'none', backgroundColor: '#fff',
    fontSize: '16px', height: "44px",
    backgroundRepeat: 'no-repeat', backgroundPositionX: '95%',
    backgroundImage: 'url("data:image/svg+xml;utf8,<svg fill=\'%23c5cbc6\' height=\'20\' viewBox=\'0 0 24 24\' width=\'20\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M7 10l5 5 5-5z\'/></svg>")',
    fontWeight: 400, fontFamily: "DM Sans",
    backgroundPositionY: 'center', appearance: 'none',
    '&:hover': {
      border: '1px solid #C3D1FF', borderRadius: "12px"
    },
    "&:focus": {
      border: '2px solid #C3D1FF;', borderRadius: "12px"
    },
  },
  "& .selectlegal": {
    width: '100% !important',
    borderRadius: '12px', fontSize: '16px',
    fontWeight: 400,
    fontFamily: "DM Sans",
    height: "44px",
    "& .css-13cymwt-control": {
      borderColor: "#C3D1FF !important",
      border: "1px solid",
      borderRadius: "12px",
      fonSize: "16px",
      fontweight: 400,
      outline: "none",
      position: "relative",
      bottom: "3px",
      height: "44px"

    },
    "& .css-t3ipsp-control": {
      borderColor: "#C3D1FF !important",
      border: "1px solid",
      borderRadius: "12px",
      fonSize: "16px",
      fontweight: 400,
      outline: "none",
      position: "relative",
      bottom: "3px",
      height: "44px",
      boxShadow: "0px 0px 0px 0px"

    },
    "& .css-1u9des2-indicatorSeparator": {
      backgroundColor: "white"
    },
    "&:focus": {
      border: '1px solid #C3D1FF;', borderRadius: "12px"
    },
    "@media (max-width:500px)": {
      width: '100% !important',
      borderRadius: '12px', fontSize: '8px',
      fontWeight: 200,
      fontFamily: "DM Sans",
      height: "44px",
    },
  },
  "& .btnAccept": {
    backgroundColor: "#355EE7",
    color: "white",
    width: "145px",
    height: '44px',
    textTransform: "none" as "none",
    borderRadius: "8px",
    fontSize: "16px",
    fontWeight: "400",
    fontFamily: "DM Sans",
    cursor: "pointer",
    border: 'none',
    '&:hover': {
      border: '2px solid #B9C5FF',
      boxShadow: 'none !important'
    }
  },
  "& .document": {
    backgroundColor: "#355EE7",
    color: "white",
    width: "179px",
    height: '46px',
    textTransform: "none" as "none",
    borderRadius: "8px",
    padding: "10px",
    fontSize: "16px",
    fontWeight: "400",
    fontFamily: "DM Sans",
    cursor: "pointer",
    border: 'none',

  },
  "& .landingLoginBtn": {
    backgroundColor: "#fff",
    width: "145px",
    height: '46px',
    textTransform: "none" as "none",
    borderRadius: "8px",
    padding: "10px",
    color: "#355ee7",
    fontSize: "16px",
    fontWeight: "400",
    fontFamily: "DM Sans",
    cursor: "pointer",
    border: '1px solid #d9d9d9',
    boxShadow: 'none !important',
    '&:hover': {
      border: '2px solid #B9C5FF',
      boxShadow: 'none !important'
    }

  },
  "& .firstNamePlaceHolder": {
    fontSize: "16px",
    fontWeight: 400
  },
  "& .sidebar": {
    height: '100%',
    position: "fixed",

    margin: 0,
    padding: 0,
    top: '0',
    left: '0',
    zIndex: 9999,
    "@media (max-width: 1100px)": {
      width: "300px"
    },
    "@media (max-width: 991px)": {
      width: '100vw',
      height: '80px'
    }
  },
  "& .mainheader": {
    position: 'fixed',
    left: '350px',
    width: '100%'
  },
  "& .logtxtdialog": {
    backgroundColor: "#355EE7",
    color: '#ffffff',
    borderRadius: '8px',
    fontSize: '16px',
    fontWeight: 400,
    fontFamily: 'DM Sans',
    marginLeft: '15px',
    border: 'none',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    '&:hover': {
      border: '2px solid #B9C5FF',
      boxShadow: 'none !important',
    },
  },
  "& .Header": {
    height: '50px',
    fontSize: '20px', padding: '20px',
  },
  "& .rightbar": {
    padding: "10px 0px",
    width: "100%",
    marginLeft: '253px',
    backgroundColor: "#fafafa",
    height: "auto",
    "@media (max-width: 1100px)": {
      padding: '20 40px', width: 'calc(100% - 300px)',
      marginLeft: '300px',
    },
    "@media (max-width: 991px)": {
      width: 'auto !important',
      marginLeft: '0px', marginTop: '50px',
      padding: "10px 50px",
    }
  },
  "& .userdetailbox": {
    width: '90%',
    padding: "20px",
    backgroundColor: "#ffffff",
    height: "auto",
    marginLeft: "61px",
    borderRadius: "20px",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
    marginBottom:"15px",
    "@media (max-width: 1424px)": {
      width: '89%',
      height: "auto",
      marginLeft: "51px",
      borderRadius: "20px",
      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
      padding: "20px",
      backgroundColor: "#ffffff",
    },
    "@media (max-width: 1366px)": {
      width: '87%',
      padding: "20px",
      backgroundColor: "#ffffff",
      marginLeft: "61px",
      borderRadius: "20px",
      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
      height: "auto",
    },
    "@media (max-width: 1280px)": {
      width: '87%',
      height: "auto",
      marginLeft: "61px",
      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
      borderRadius: "20px",
      fontSize: "20px",
      padding: "20px",
      backgroundColor: "#ffffff",

    },
    "@media (max-width: 780px)": {
      width: '87%',
      fontSize: "20px",
      height: "auto",
      marginLeft: "31px",
      borderRadius: "20px",
      padding: "20px",
      backgroundColor: "#ffffff",

      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
    },
    "@media (max-width: 600px)": {

      height: "auto",
      marginLeft: "24px",
      borderRadius: "20px",
      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
      width: '87%',
      fontSize: "20px",
      padding: "20px",
      backgroundColor: "#ffffff",
    },
    "@media (max-width: 520px)": {
      width: '87%',
      height: "auto",
      marginLeft: "24px",
      borderRadius: "20px",
      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
      fontSize: "20px",
      padding: "20px",
      backgroundColor: "#ffffff",
    },
  },
  "& .profilebox": {
    height: '156px',
    display: 'flex', borderBottom: '1px solid #D9D9D9',
    "@media (max-width: 450px)": {
      height: '120px',
    },
  },
  "& .profileImg": {
    display: 'flex',
    justifyContent: "center", width: "80%",
  },
  "& .profiletext": {
    fontSize: '16px',
    fontWeight: 400, color: '#8C8C8C', fontFamily: 'DM Sans',
  },
  "& .userimg": {
    height: '120px',
    width: '120px',
    border: '4px solid #ECF1FF',
    borderRadius: '50%',
    "@media (max-width: 450px)": {
      height: '80px',
      width: '80px',
    },
  },
  '& .topbox': {
    display: 'flex',
    width: "auto",
    height: '70px',
    justifyContent: 'space-between',
    "@media (max-width: 991px)": {
      width: "auto !important",
    },
  },
  "& .titleuser": {
    height: '30px',
    width: '230px',
    fontSize: '22px',
    fontWeight: 400,
    fontFamily: "DM Sans",
    color: '#000',
    "@media (max-width: 450px)": {
      width: '200px',
      fontSize: '16px',
    },
  },
  "& .titleuser2": {
    height: '30px',
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "13px",
    width: '230px',
    fontSize: '27px',
    fontWeight: 700,
    fontFamily: "DM Sans",
    color: '#0F172A',
    marginLeft: "60px",
    marginTop: "13px",
    "@media (max-width: 450px)": {
      width: '200px',
      fontSize: '16px',
    },
  },
  "& .titleuser3": {
    height: '30px',
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "5px",
    width: '230px',
    fontSize: '22px',
    fontWeight: 400,
    fontFamily: "DM Sans",
    marginLeft: "80px",
    color: '#000',
    "@media (max-width: 450px)": {
      width: '200px',
      fontSize: '16px',
    },
  },
  "& .titlbtn": {
    height: '44px',
    fontSize: '14px', fontWeight: 400, color: '#355EE7', fontFamily: 'DM Sans',
    width: '163px', border: '1px solid #355EE7', borderRadius: '8px', padding: '10px 12px',
    "@media (max-width: 450px)": {
      height: '40px',
      width: '140px', padding: '7px 9px',
    },
  },
  "& .detailsbox": {
    display: 'flex', marginTop: '20px', paddingBottom: '40px',
    borderBottom: '1px solid #D9D9D9', "@media (max-width: 600px)": {
      display: 'block'
    },

  },
  "& .detailsbox2": {
    display: 'flex', marginTop: '20px',
    "@media (max-width: 600px)": {
      display: 'block'
    },

  },
  "& .personaldetailtext": {
    width: '15%', fontSize: '20px', fontWeight: 700,
    fontFamily: 'DM Sans', color: 'black',
    "@media (max-width: 600px)": {
      width: '100%', marginBottom: '20px'
    },
  },
  "& .personalform": {
    width: '88%',
    position: 'relative',
    "@media (max-width: 600px)": { width: '100%' },
  },
  "& .fullName": {
    width: '100%', display: 'flex', paddingBottom: '30px',
    "@media (max-width: 650px)": {
      display: 'block', width: '100%'
    }
  },
  "& .firstname": {
    width: "49%", marginRight: "10px",
    "@media (max-width: 650px)": {
      width: "91% !important", marginBottom: '15px',
    },
  },
  "& .lastname": {
    width: "49%",

    "@media (max-width: 650px)": { width: "91% !important", },
  },
  "& .lawFirm": {
    width: "99%", marginBottom: '15px',
  },
  "& .country": {
    width: "91%", marginBottom: '15px',
  },
  "& .PhoneNumer": {
    width: "91%", marginBottom: '15px',
  },
  "& .Email": {
    width: "91%", marginBottom: '15px',
  },
  "& .Password": {
    width: "91%", marginBottom: '15px', height: "28px",
  },
  "& .passtext": {
    color: "#434343", fontSize: '14px',
    fontWeight: 400, fontFamily: 'DM Sans',
  },
  "& .changepasstext": {
    color: "#6200EA", fontSize: '14px',
    fontWeight: 700, fontFamily: 'DM Sans', cursor: 'pointer', marginTop: '4px',
  },
  "& .accountsbox": {
    display: 'flex', marginTop: '10px',
    "@media (max-width: 600px)": {
      display: 'block',
      marginTop: '10px',
    },
  },
  "& .accountdetailtext": {
    width: '20%', color: "#8C8C8C",
    fontSize: '16px', fontWeight: 400, fontFamily: 'DM Sans',
    "@media (max-width: 600px)": {
      width: '100%',
      marginBottom: '20px'
    },
  },
  "& .accountsactions": {
    width: '84.3%', marginBottom: '40px',
    display: "flex", justifyContent: "flex-end",
    "@media (max-width: 600px)": { width: '100%', },
  },
  "& .logouttxt": {
    display: 'flex', alignItems: 'center', margin: '0px 0px 20px 0px', gap: "20px",
    color: "#434343", fontSize: '16px', fontWeight: 400, fontFamily: 'DM Sans',
    cursor: 'pointer',
  },
  "& .deletetxt": {
    display: 'flex', alignItems: 'center',
    margin: '20px 0px', color: "#DC2626", fontSize: '16px', fontWeight: 400, fontFamily: 'DM Sans',
    cursor: 'pointer',
  },
  "& .errorOutline": {
    '& .MuiFormControl-root': {
      '& fieldset': {
        borderColor: 'red 1px solid',
      },
    },
  },
  "& .MuiInputBase-input": {
    padding: '18.5px 14px',
  },
  "& .tableContainer": {
    marginTop: " theme.spacing(4)",
    "& .MuiPaper-elevation1": {
      boxShadow: "none !important"
    }
  },

  "& .tableHeader": {
    color: "#8C8C8C",
    fontSize: "12px",
    fontWeight: "500"
  },
  "& .chipNew": {
    backgroundColor: "#D1FAE5",
    color: "#059669",
    fontWeight: 700,
    fontSize: "10px"
  },
  "& .chipPending": {
    backgroundColor: "#DCECFF",
    color: "#355EE7",
    fontWeight: 700,
    fontSize: "10px"
  },
  "& .chipInProgress": {
    backgroundColor: "#FEF3C7",
    color: "#D97706",
    fontWeight: 700,
    fontSize: "10px"
  },
  "& .chipClosed": {
    backgroundColor: "#FEE2E2",
    color: "#DC2626",
    fontWeight: 700,
    fontSize: "10px"
  },
  "& .chipStatus": {
    backgroundColor: ' #FAFAFA',
    width: '164px',
    height: '22px',
    borderRadius: '40px',
    fontWeight: 700,
    fontSize: "10px",
    padding: '10px 8px 8px 8px',
    display: "flex",
    justifyContent: "flex-start"
  },
  "& .iconButton": {
    width: "24px",
    height: "24px",
    color: "#355EE7"
  },
  "& .iconButton2": {
    width: "24px",
    height: "24px",
    color: "#BFBFBF"

  },
  "& .drawer": {
    width: "50%",
    minWidth: "812px",
  },
  "& .closeButton": {
    marginLeft: "auto",
  },
  "& .calendarIcon": {
    verticalAlign: "middle",
  },
})
const CustomizeDialog = styled(Box)({
  "& .selectCountry": {
    width: '100%',
    border: '1px solid #C3D1FF',
    borderRadius: "12px",
    outline: 'none',
    backgroundColor: '#fff',
    fontWeight: 400,
    fontSize: '16px',
    height: "44px",
    fontFamily: "DM Sans",
    color: "black",
    paddingLeft: "8px",
    backgroundPositionY: 'center',

  },

  display: "flex",
  flexDirection: "column",
  boxShadow: "  0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008,0px 25px 50px 0px #00000017",
  borderRadius: "12px",
  padding: "10px",
  width: "600px",
  background: "white",
  height: "auto",
  position: 'fixed',
top: '50%',        
left: '50%',      
transform: 'translate(-50%, -50%)',  
zIndex: 1000,      
  
  "& .MuiPaper-rounded ": {
    bordeRadius: "17px",
  },
  "& .mainDiv": {
    background: "#FFF",
    width: "100%",
    justifyContent: "center",
    flexDirection: "column",
    display: "flex",
    borderRadius: "16px",
    padding: "32px 24px 32px 24px",
    gap: "20px",
    fontFamily: "Dm sans",

  },
  "& .selectlegal": {
  
    fontSize: '16px',
    fontWeight: 400,
    width: '100% !important',
    borderRadius: '12px', 
    fontFamily: "DM Sans",
    height: "44px","& .css-13cymwt-control": {
      borderColor: "#C3D1FF !important",
      border: "1px solid",
      borderRadius: "12px",
      fonSize: "16px",
      fontweight: 400,
      outline: "none",
      position: "relative",
      bottom: "3px",
      height: "44px" },
    "& .css-t3ipsp-control": {
      borderColor: "#C3D1FF !important",
      border: "1px solid",
      borderRadius: "12px",
     
      bottom: "3px",
      height: "44px",
      fonSize: "16px",
      fontweight: 400,
      outline: "none",
      position: "relative",
      boxShadow: "0px 0px 0px 0px"},
    "& .css-1u9des2-indicatorSeparator": {
      backgroundColor: "white"
    },
    "&:focus": {
      border: '1px solid #C3D1FF;', borderRadius: "12px"
    },
    "@media (max-width:500px)": {
      width: '100% !important',
      borderRadius: '12px', fontSize: '8px',
      fontWeight: 200,
      fontFamily: "DM Sans",
      height: "44px",
    },
  },
  "& .selectBox": {
    border: "1px solid #C3D1FF",
    position: "relative",
    width: "97%",
    borderRadius: "12px",
    color: "black", height: "44px",
    display: "flex",
    justifyContent: "center",
    paddingLeft: "6px",
    paddingRight: "6px",
    "& .MuiInputBase-input::placeholder": {
      color: "#BFBFBF",
    },
    "& .div-root-1 .MuiInputBase-input": {
      padding: "0px"
    }
  },
  "& .forGap": {
    padding: "4px",
  },
  "& . moneySlider": {
    color: "red",
  },
  "& .fontsizing": {
    fontSize: "14px",
    fontWeight: 400,
    color: "#212121",
  },
  "& .selectedUser": {
  
  },
  "& .selectedUser2": {
    border: '1px solid #C3D1FF',
    borderRadius: '10px',
    display: 'flex',
    gap: '10px',
    maxHeight: '200px',
    overflowY: 'auto',
    backgroundColor: " #FAFAFA",
    scrollbarWidth: 'thin',
    scrollbarColor: '#355EE7 #F0F0F0',
    fontFamily: "Dm sans",
    width: "100%"
  }
});

const DrawerBox = styled('div')({
  minHeight: '100vh',
  width: "252px",
  borderRight: '1px solid #E2E8F0',
  "& .logobox": {
    display: 'flex', justifyContent: "start",
    height: "76px",
    padding: '17px 117px 17px 35px'
  },
  "& .logoimag":
  {
    height: '42px',
    width: '118px',
  },
  "& .settingbox":
  {
    display: 'flex',
    height: "65px",
  },
  "& .MainListBox": {
    padding: '0px 16px 8px 16px',
    position: 'relative'
  },
  "& .listbox": {
    height: '56px',
    borderRadius: '8px',
    "&:hover": {
      backgroundColor: "#ECF1FF",
      color: "#355EE7",
      "& .icondrawer2":
      {
        color: "#355EE7",
      },
    },
  },
  "& .listbox1": {
    height: '56px',
    backgroundColor: '#ECF1FF',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    borderRadius: '8px',
    marginBottom: '10px'
  },
  "& .icondrawer1": {
    marginBottom: '7px',
    marginLeft: '32px',
    color: '#355EE7'
  },
  "& .icondrawer2": {
    marginLeft: '32px',
    marginBottom: '2px',
  },
  "& .listtile1": {fontSize: "16px",color: '#355EE7',
    fontFamily: 'DM Sans',fontWeight: 400,cursor:'pointer'
  },
  "& .listtile":
  {
    fontSize: "12px",
    fontFamily: 'Inter',
    fontWeight: 700,
    cursor: 'pointer',
    marginLeft: '10px'
  },
  "& .userProfile": {
    display: "flex",
    width: '252px',
    position: 'absolute',
    bottom: '10px',
    left: '32px'
  },
  "& .imageBox": {
    width: '32px',
    height: '32px',
    borderRadius: '50%',
    border: '1px solid #F0E5FF',
    marginRight: '10px',
  },
  "& .imageBox2": {
    width: '90px',
    height: '90px',
    borderRadius: '50%',
  },
  "& .userName": {
    fontFamily: 'DM Sans',
    fontSize: '14px',
    fontWeight: 700,
    color: '#0F172A'
  },
  "& .userContent": {
    fontFamily: 'DM Sans',
    fontSize: '11px',
    fontWeight: 400,
    color: '#8C8C8C'
  },
  "@media (max-width: 1100px)":
  {
    zIndex: 999,
    width: '252px',
  },
  "@media (max-width: 991px)":
  {
    zIndex: 999,
    width: '252px',
    display: 'none',
  },
});
const ButtonBox = styled('div')(
  {
    display: 'none',
    "@media (max-width: 991px)":
    {
      width: '100%',
      display: 'block', minHeight: '70px',
      "& .buttonbox":
      {
        height: '70px', width: '100vw'
      },

      "& .iconbtn":
      {
        marginTop: '10px', marginLeft: '10px'
      }
    }
  })
// Customizable Area End
