import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

import { getStorageData, setStorageData } from "../../../framework/src/Utilities";

export interface CountryDatatype {
  id: string;
  type: string;
  attributes: {
    name: string;
  };
}

export interface LegalServices{
  id: string,
  legal_service_name: string,
  legel_service_description: string|null,
  created_at: string,
  updated_at: string
}

export interface DataLegal{
  value:string,
  label:string,
  id:string,
}

export interface ServicesDataType{
  id: string,
  legal_service_name: string,
  legel_service_description: string|null,
  created_at: string,
  updated_at: string
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  msg:any,
  mobileOpen:boolean,
  userdata:any,
  closeOption:boolean,
  openOption:boolean
  openOptionIndex:number,
  closebox:boolean,

  isArrowUp:boolean
  isArrowUp2:boolean
  isArrowUp3:boolean
  countryData:any,
    legalServiceType:{
      value:string,
      label:string,
      id:string,
    }[],
    filteredData:any,
    filteredDataLegalService:any,
    countryDataInputValue:any,
    countryDataInputValueStack:any,
    legalServiceDataInputValue:string,
    ratingDataInputValue:string,
    priceDataInputValue:string,
    openFilterPopUp:boolean,
    Error:any,
    intalSlideBarValue:any,
    finalSlideBarValue:any,
    SliderBarValue:any,
    servicesData:any,
    optionsShow:any,
    ratingOptions:any
    ratingInputValue:number
    legalServiceDataInputValueStack:any,
    filterResult:any
    currentIndex:number
    mediaArray:any
    detailsPopUp:boolean
    showdetails:{
      firmName:string,
      webUrl:string,
      legalServceType:any,
      rate:string,
      averageResponeTime:string,
      profileUrl:string
    }
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AdvancedSearchController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  advancedsearchApiCallId: any;
  getServiceApiCallId:string = "";
  getCountryApiCallId:string="";
  getAdvanceSearchApiCallId:string="";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      msg:"",
      mobileOpen: false,
      closeOption:false,
      openOption:false,
      openOptionIndex:-1,
      closebox:false,
      userdata: [],

      legalServiceType:[],
      countryData:[],
      isArrowUp:false,
      isArrowUp2:false,
      isArrowUp3:false,
      filteredData:[],
      filteredDataLegalService:[],
      countryDataInputValue:'',
      countryDataInputValueStack:[],
      legalServiceDataInputValue:'',
      legalServiceDataInputValueStack:[],
      ratingDataInputValue:'',
      priceDataInputValue:'',
      openFilterPopUp:false,
      intalSlideBarValue:10,
      finalSlideBarValue:20,
      SliderBarValue:0,
      Error:{
        isError:'Fill all mandatory fileds to continue',
        legalServices:'',
        jurisdiction:'',
        rating:'',
        price:'',
    },
    servicesData:[],
    optionsShow:[],
    ratingOptions:[{id:1,rating:1},{id:2,rating:2},{id:3,rating:4},{id:4,rating:4},{id:5,rating:5}],
    ratingInputValue:0,
    filterResult:{},
    currentIndex:0,
    mediaArray:[],
    detailsPopUp:false,
    showdetails:{
      firmName:"",
      webUrl:"",
      legalServceType:[],
      rate:'4.2',
      averageResponeTime:'6hrs',
      profileUrl:''
    }
    
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    this.handlesliderbar();
    this.getCountryList();
    this.getServicesList();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      runEngine.debugLog("API Message Recived", message);
     if (apiRequestCallId === this.getCountryApiCallId) {
      this.setState({countryData:[...responseJson?.data]})
      
     }
     if (apiRequestCallId === this.getServiceApiCallId) {
      this.setState({servicesData:responseJson})
      this.reciveLegalServiceData(responseJson)
      
     }
     if (apiRequestCallId === this.getAdvanceSearchApiCallId) {
      this.setState({filterResult:responseJson ? responseJson : {}})
      if(responseJson.data){
        this.setState({userdata:[...this.state.filterResult.data]})
      }
      else{
        this.setState({userdata:[]})
      }
      this.handleClosePopUp()
     }
    }
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const navRes = message.getData(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      
      if(navRes.advanceSearchFilterData){
      const {advanceSearchFilterData} = navRes;
      const {msg} = advanceSearchFilterData;
      if(msg.data){
        const {data} = msg;
        this.setState({userdata:data})
      }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  handleCloseDetailsPopUp = () =>{
    this.setState({detailsPopUp:false})
  }

  handleLawyerCatalogueNavigation = (frimname:string) =>{
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage),
      "ClientLawyerCatalogue");
    message.addData(getName(MessageEnum.NavigationPropsMessage),
      this.props);
    
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    const msg = {msg:frimname}
    raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), msg);
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
  
    this.send(message);
  }

  handleDetailsPopUp = async (index:number) => {
    const result = this.state.userdata[index].attributes.lawfirm_details.data.attributes.attachment.data.flatMap((attachment: any) => {
      if (attachment.attributes.files) {
        return attachment.attributes.files.map((file: any) => ({
          type: file.file_type,
          url: file.file_url
        }));
      }
      return [];
    });
    this.setState({
      mediaArray:result,
      detailsPopUp:true,
      showdetails:{
        ...this.state.showdetails,
        firmName:this.state.userdata[index].attributes.lawfirm_details.data.attributes.firm,
        legalServceType:[...this.state.userdata[index].attributes.service_deatils],
        webUrl:this.state.userdata[index].attributes.lawfirm_details.data.attributes.website,
        profileUrl:this.state.userdata[index].attributes.lawfirm_details.data.attributes.profile_image ? 
        this.state.userdata[index].attributes.lawfirm_details.data.attributes.profile_image.url : ''
      }
    })
  }

  handleNext = () => {
    const { currentIndex } = this.state;
    const newIndex = (currentIndex + 3) % this.state.mediaArray.length;
    this.setState({ currentIndex: newIndex });
  };

  handlePrev = () => {
    const { currentIndex } = this.state;
    const newIndex = (currentIndex - 3 + this.state.mediaArray.length) % this.state.mediaArray.length;
    this.setState({ currentIndex: newIndex });
  };

  handleNavigationClientDashboard = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage),
      "ClientDashboard");
    message.addData(getName(MessageEnum.NavigationPropsMessage),
      this.props);

    this.send(message);
  }

  handleclosesidebar = () => {
    this.setState({ mobileOpen: false });
  };
  
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: true });
  };

  handleCloseOption = () => {
    if (this.state.closeOption) {
      this.setState({ openOption: false, closeOption: false });
    }
  };
  handleCloseBox = () => {
    if (this.state.closebox) {
      this.setState({ openOptionIndex: -1, closebox: false });
    }
  };

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  reciveLegalServiceData=async (responseJson:any)=>{
    if(responseJson){
      let user: DataLegal[] = await Promise.all(
        responseJson.map(async (e:ServicesDataType) => ({
          id: e.id,
          label: e.legal_service_name,
          value: e.legal_service_name,
        }))
      );
        this.setState({optionsShow:user})
    }
  }

  callFilter = async ()=>{
    if (this.state.legalServiceDataInputValueStack.length === 0) {
      this.setState((prev) => {
        return {
          ...prev,
          Error: {
            ...prev.Error,
            legalServices: "Select legal service type",
          }
        };
      })
    }
    if (this.state.countryDataInputValueStack.length === 0) {
      this.setState((prev) => {
        return {
          ...prev,
          Error: {
            ...prev.Error,
            jurisdiction: "Select jurisdiction to Apply Filter",
          }
        };
      })
    }
    if (Number(this.state.ratingInputValue) === 0) {
      this.setState((prev) => {
        return {
          ...prev,
          Error: {
            ...prev.Error,
            rating: 'Select Rating for apply filter'
          }
        };
      })
    }

    if (this.state.intalSlideBarValue == 0) {
      this.setState((prev) => {
        return {
          ...prev,
          Error: {
            ...prev.Error,
            price: 'Select price to apply filter'
          }
        };
      })
    }
  }
  handleApplyFilter = async () => {
  
    await this.callFilter();

    !this.state.Error.legalServices &&
    !this.state.Error.jurisdiction &&
    !this.state.Error.rating &&
    !this.state.Error.price &&
    this.getAdvanceSearchFilteredValue();
  }

  getCountryList= async () => {
    const header = {
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCountryApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account_block/accounts/get_countries"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getServicesList= async () => {
    const header = {
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getServiceApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_productdescription/service_managements/legal_services_list"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getAdvanceSearchFilteredValue = async () =>{
    const loginTokens: string = await getStorageData("clientTokes");
    
    const header = {
      "Content-Type": "application/json",
      "token":loginTokens+""
    };

    const legalId = this.state.legalServiceDataInputValueStack.map((x:any)=>x.id).join(",");
    const jurisdictionlId = this.state.countryDataInputValueStack.map((x:any)=>x.attributes.name).join(",")

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAdvanceSearchApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_advanced_search/search/filter?legel_service_type_ids[]=${legalId}&jurisdictions[]=${jurisdictionlId}&price=${this.state.SliderBarValue}&rating=${this.state.ratingInputValue}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  
  handlesliderbar = () => {
    this.setState({ SliderBarValue: this.state.intalSlideBarValue })
  }
  handleChangesliderbar = (event: any) => {
    this.setState((prev) => {
      return {
        SliderBarValue: event.target.value, intalSlideBarValue: event.target.value, Error: { ...prev.Error, price: '' }
      }
    })
  }

  handleClosePopUp = () =>{
    this.setState({ 
      openFilterPopUp: !this.state.openFilterPopUp,
      isArrowUp:false,
      isArrowUp2:false,
      isArrowUp3:false,
      legalServiceDataInputValue:'',
      ratingDataInputValue:'',
      priceDataInputValue:'',
      Error: {
        isError:'Fill All Mandtory fileds to Contiune',
        jurisdiction: "",
        legalServices: "",
        rating: "",
        price: "",
    },

     });
  }

  handleSelectedCountry = (value:any) =>{
    const filteredData = this.state.countryData.filter((item: any) =>
      item.attributes.name.toLowerCase().includes(value.attributes.name.toLowerCase())
    );
    
    this.setState((prev) => {
      const newCountryDataInputValueStack = prev.countryDataInputValueStack.includes(value)
        ? prev.countryDataInputValueStack 
        : [...prev.countryDataInputValueStack, value]; 
    
      return {
        ...prev,
        filteredData,
        countryDataInputValue: value.attributes.name,
        countryDataInputValueStack: newCountryDataInputValueStack,
        isArrowUp2: false,
        Error: {
          ...prev.Error,
          jurisdiction: "",
        },
      };
    });

  }
  handleSelectedLegalService = (value: any) => {
    const filteredDataLegalService = this.state.optionsShow.filter((item: any) =>
      item.label.toLowerCase().includes(value.label.toLowerCase())
    );

    this.setState((prev) => {
      const existsInStack = prev.legalServiceDataInputValueStack.some(
        (item: any) => item.label === value.label
      );

      const newStack = existsInStack
        ? prev.legalServiceDataInputValueStack
        : [...prev.legalServiceDataInputValueStack, value];

      return {
        ...prev,
        filteredDataLegalService,
        legalServiceDataInputValue: value.label,
        legalServiceDataInputValueStack: newStack,
        isArrowUp: false,
        Error: {
          ...prev.Error,
          legalServices: "",
        },
      };
    });
  }
  handleSelectedrating = (value: string) => {
    this.setState((prev: any) => {
      return {
        ...prev,
        ratingInputValue: value,
        isArrowUp3: false,
        Error: {
          ...prev.Error,
          rating: "",
        }
      }
    });

  }

  handleRatingCancel = () => {
    this.setState({ ratingInputValue: 0 });
  }

  handleCancelSelectedCountry = (value: any) => {

    const res = this.state.countryDataInputValueStack.filter((item: any) => item.attributes.emoji_flag !== value);
    this.setState({ countryDataInputValueStack: [...res] })
  }

  handleCancelLegalServices = (value: any) => {

    const res = this.state.legalServiceDataInputValueStack.filter((item: any) => item.label !== value);
    this.setState({ legalServiceDataInputValueStack: [...res] })
  }

  handleInputChange = (event: any) => {
    const inputValue = event.target.value;
    const filteredData = this.state.countryData.filter((item:any) =>
      item.attributes.name.toLowerCase().includes(inputValue.toLowerCase())
    );

    this.setState({ filteredData, countryDataInputValue: inputValue });
  };

  handleInputChangeLegalService = (event: any) => {
    const inputValue = event.target.value;
    const filteredDataLegalService = this.state.optionsShow.filter((item: any) =>
      item.label.toLowerCase().includes(inputValue.toLowerCase())
    );

    this.setState({ filteredDataLegalService, legalServiceDataInputValue: inputValue });
  };

  toggleArr = () => {
    this.setState({ isArrowUp: !this.state.isArrowUp,isArrowUp2:false,isArrowUp3:false })
  }
  toggleArr2 = () => {
    this.setState({ isArrowUp2: !this.state.isArrowUp2,isArrowUp:false,isArrowUp3:false })
  }
  toggleArr3 = () => {
    this.setState({ isArrowUp3: !this.state.isArrowUp3,isArrowUp:false,isArrowUp2:false })
  }
  handleFinalValue=(event:any) => {
    this.setState({ finalSlideBarValue: event.target.value });
  }

  // Customizable Area End
}
