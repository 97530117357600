// Customizable Area Start
export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const threeDots = require('../assets/threedots.png');
export const folder = require('../assets/folder.png');
export const file = require('../assets/file.png');
export const backButton = require('../assets/Backbtn.png');
export const folderThreeDot = require('../assets/folderThreeDots.png');
export const logo = require("../assets/image_logo.svg");
export const pdfI = require("../assets/pdf.png")
export const docI = require("../assets/doc.png")
export const xlsI = require("../assets/xls.png")
export const pptI = require("../assets/ppt.png")
export const jpeg = require("../assets/jpeg.png")
export const pngFI = require("../assets/pngF.png")
export const mp4I = require("../assets/mp4.png")
export const userP = require("../assets/userPr.png")
// Customizable Area End
