import React from "react";
// Customizable Area Start
import ForgotPasswordController, { Props } from "./ForgotPasswordController.web";
import { Grid, Button, Typography, Box, styled, TextField, } from '@material-ui/core';
import { Formik, Field, Form } from "formik";
import { logo } from "./assets";
import * as Yup from "yup";
import ErrorSuccessPopUp from "../../../components/src/ErrorSuccessPopUp.web";

// Customizable Area End


export default class ResetPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

    // Customizable Area Start
    renderPasswordRecovery = () => {
        const validationSchema = Yup.object().shape({
            email: Yup.string().email('Invalid email').required('Email is Required.'),
        });

        return (
            <Formik
                initialValues={{ email: "" }}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true);
                    this.handleforgotpassword(values)
                    setSubmitting(false);
                }}
            >
                {({ errors, touched, handleChange }) => (
                    <Form className="thirdForm">

                        <Box className="firstNameField">
                            <Typography variant="subtitle1" className="firstNamePlaceHolder">
                                Email
                            </Typography>
                            <Field
                                as={TextField}
                                inputProps={{ "data-test-id": "email" }}
                                name="email"
                                type="email"
                                fullWidth
                                onChange={handleChange}
                                variant="outlined"
                                placeholder="Your email"
                                error={touched.email && !!errors.email || this.state.errormsg}
                                helperText={touched.email && errors.email}
                                FormHelperTextProps={{
                                    className: 'errorText', // Apply the custom class
                                }}
                                className={touched.email && errors.email ? "errorOutline" : ''}
                            />
                        </Box>
                        <Typography style={{ color: 'red' }}>{this.state.errormsg}</Typography>
                        <button data-test-id="submit" type="submit" className='continueBtn' color="primary" >
                            Send a recovery link
                        </button>
                    </Form>
                )}
            </Formik>
        );
    };

    // Customizable Area End

  render() {
        // Customizable Area Start
        return (
            <MainGrid container data-test-id="ResetPassword">
                <Grid item xs={12} className="logoGrid">
                    <img src={logo} alt="Logo" className="logo" />
                </Grid>
                <Grid container item xs={12} justifyContent="center" alignItems="center" className="secondGridItem">
                    <Box
                        display="flex"
                        flexDirection="column"
                        p={3}
                        className="formBox"
                    >
                        <Typography variant="h4" gutterBottom className="signupText">
                            Password recovery
                        </Typography>
                        <Typography className='newPassowrdText newText'>
                            Forgot password?
                        </Typography>
                        <Typography className='newText'>
                            No worries, we'll send a recovery link to your email.
                        </Typography>
                        <Box className="formBoxSecond">
                            {this.renderPasswordRecovery()}
                        </Box>


                        <Box className="accountCreate">
                            <Typography className="accountQuestion">
                                Back to  </Typography>
                            <Box className="sign" onClick={this.handleLogIn}>
                                Log in</Box>
                        </Box></Box>
                </Grid>

                {this.state.showPopUP && <ErrorSuccessPopUp
                  showPop={this.state.showPopUP}
                  setShowPop={(newState: boolean) => this.setState({ showPopUP: newState })}
                  termfunction={  () => {}}
                  showResponse={this.state.ResponseShoW} 
                  loginNav={this.handleLogIn}
                  deletePop={this.state.loginNav}
                />}</MainGrid>
        );
        // Customizable Area End
  }
}

// Customizable Area Start
const MainGrid = styled(Grid)({
    position: 'relative',
    height: '100vh',
    "& .logoGrid": {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        color: "#ffff",
        padding: "13px",
        height: '8%',
    },
    "& .logo": {
        height: 'auto',
        width: '100px',
        marginLeft:"1%"
    },
    "& .secondGridItem": {
      marginBottom:"2%",
        height: '90%',
    },
    "& .formBox": {
        maxWidth: '400px',
        width: '100%',
    },
    "& .signupText": {
        width: '100%',
        fontFamily: "DM Sans",
        marginBottom: "30px",
        fontWeight: 700,
        fontSize: "33px",
        textAlign: "left",
        lineHeight: "42px",
    },
    "& .continueBtn": {
        width: "100%",
        marginTop: "30px",
        padding: "20px",
        backgroundColor: "#355EE7",
        borderRadius: "10px",
        textTransform: "none",
        color: "white",
        fontWeight: 400,
        fontFamily: "DM Sans",
        fontSize: "16px",
        lineHeight: "21px",
        boxShadow:"none !important",
        border:"none",
        cursor:'pointer',
        '&:hover': {
            border:'2px solid #B9C5FF',
            boxShadow:'none !important'
          },
        '&:disabled': {
            backgroundColor: "#D9D9D9",
        },
    },
    "& .firstNamePlaceHolder": {
        textAlign: "left"
    },
    "& .newPassowrdText": {
        textAlign: "left",
        margin: "30px 0",
    },
    "& .passwordErrorBox": {
        display: "flex !important",
        justifyContent: "center !important",
        alignItems: "center !important",

    },
    "& .accountCreate":
    {
        display: "flex",
        justifyContent: "flex-start",
        marginTop: "40px",
        alignItems: "flex-start",
    },
    "& .accountQuestion": {
        fontSize: "16px",
        fontWeight: 400,
        fontFamily: "DM Sans"
    },
    "& .sign": {
        fontFamily: "DM Sans",
        marginLeft: "10px",
        color: "#355EE7",
        justifyContent: 'center',
        cursor: 'pointer',
        fontSize: '16px',
        fontWeight: 400
    },
    "& .newText": {
        marginBottom: "5px",
        textAlign: "left",
        fontSize: "16px",
        fontWeight: 400,
        fontFamily: "DM Sans"
    },
    "& .firstNameField": {
        marginTop: "30px",

    },
    "& .formBoxSecond": {
        width: "100%"

    },
    "& .errorText": {
        marginLeft: "0px"
    }

});
// Customizable Area End
