// Customizable Area Start
import { sendAPIRequest } from "./components/utils";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
import { getStorageData } from "../../../framework/src/Utilities";
export const configJSON = require("./config");
export interface Props {
    id?: string;
    navigation:any,
    projectId:string
}
export interface AssignedLawyers {
  data: LawyerData[];
}

export interface LawyerData {
  id: string;
  type: string;
  attributes: LawyerAttributes;
}

export interface LawyerAttributes {
  associated_lawyer_details: AssociatedLawyerDetails;
  legal_service_count: number;
}

export interface AssociatedLawyerDetails {
  data: LawyerDetailData;
}

export interface LawyerDetailData {
  id: string;
  type: string;
  attributes: LawyerDetailAttributes;
}

export interface LawyerDetailAttributes {
  first_name: string;
  last_name: string;
  website: string;
  firm: string;
  jurisdiction: string;
  full_phone_number: string;
  country_code: number;
  email: string;
  user_role: string;
  accomplishments: string;
  lawyer_status: string;
  legel_service_type: LegalServiceType[];
  years_of_experience: number;
  profile_image?: {
    url: string;
  };
  attachment: Attachment;
}

export interface LegalServiceType {
  id: number;
  legal_service_name: string;
  legel_service_description: string | null;
  created_at: string;
  updated_at: string;
}

export interface ProfileImage {
  url: string;
}

export interface Attachment {
  data: any[];
}
export interface ApiResponse {
    attributes: {
      associated_lawyer_details: {
        data: {
          id:string,
          attributes: {
            first_name: string;
            last_name: string;
            website: string;
            firm: string;
            jurisdiction: string;
            full_phone_number: string;
            country_code: number;
            email: string;
            user_role: string;
            accomplishments: string;
            lawyer_status: string;
            legel_service_type: [{
              id: string,
              legal_service_name: string,
              legel_service_description: string|null,
              created_at: string,
              updated_at: string
            }];
            years_of_experience: number;
            profile_image: {
              url?: string | null; 
            } | null;
          };
        }
      };
      legal_service_count: number;
    };
  }
  export interface Assigned{
    full_name:string,
    image:string,
    account_id:number
  }
  export interface TodoListResponse{
    id:string,
            attributes: {
                id: string,
                to_do_name: string,
                to_do_description: string,
                priority: string,
                status: string,
                deadline: string,
                account_id: 1,
                assign_lawyer: "",
                project_template_id: 1,
                created_at: "",
                updated_at: "",
                assigned_lawyers:{
                  data:LawyerData[];
                }
            }
  }
export interface S {
    page: number;
    rowsPerPage: number;
    open: boolean;
    startHasValue: boolean;
    startDateFocus: boolean;
    endHasValue: boolean;
    endDateFocus: boolean;
    mobileOpen:boolean;
    selectedLawyersID: string[]
    updatedSelectedID: string[],
    loading:boolean,
    associatedLawyer: ApiResponse[];
    todoList:TodoListResponse[];
   ViewTodoList:{
    attributes: {
      id: string,
      to_do_name: string,
      to_do_description: string,
      priority: string,
      status: string,
      deadline: string,
      account_id: 1,
      assign_lawyer: "",
      project_template_id: 1,
      created_at: "",
      updated_at: "",
      assigned_lawyers:{
        data:LawyerData[];
      }
  }};
    searchResults:TodoListResponse[];
    searchData:ApiResponse[];
    isAnyLawyerSelected:boolean;
    openDialog: boolean;
    anchorEl:null;
    drawerOpen:boolean;
    taskViewId:string,
    isExpanded:boolean,
    selectedLawyers: ApiResponse[];
    acceptTodo:boolean,
    filterDate:string,
    filterPriority:string,
    filterStatus:string,
    openToDo:boolean,
    searchQuery:string,
    openPop:boolean,
    searchLawyer:ApiResponse[],
    isFilterPopupOpen:boolean,
    task_to_do:{
      to_do_name: string, 
      to_do_description: string, 
      priority: string, 
      deadline: string, 
      assigned_lawyer_ids: number|null,
      assigned_lawyers:{
        data:LawyerData[];
      }
    },
      project_template_id: number,
      priority:string,
      responseShow:{
        success:string,
        errors:string},
        isEditMode:boolean,
        isFormValid :boolean,
        projectIds:string,
        validationErrors: {
          to_do_name: boolean, 
          deadline: boolean, 
        },
}
export interface SS {
    id: string;
}
export default class ProjectReportingController extends BlockComponent<
    Props,
    S,
    SS
> {
    getProRepoCallId: string = "";
    createProject: string = "";
    getCoManagersDataCallId: string = "";
    getManagersDataCallId: string = "";
    getClientCallId: string = "";
    getAssociatedLawyersId="";
    getTodoListId="";
    getViewToDoId="";
    postViewToDo = "";
    putEditTodo="";
    getSearchApiCallId="";
    postCreateTodoId=""
    deleteTodoId=""
    searchTodoId=""
    
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIRequestMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.NavigationTargetMessage),
            getName(MessageEnum.NavigationPropsMessage),
            getName(MessageEnum.NavigationTaskAllocatorMessage),
            getName(MessageEnum.NavigationTaskAllocatorId),
            getName(MessageEnum.NavigationProjectTrackingMessage),
            getName(MessageEnum.NavigationProjectTrackingId),
            getName(MessageEnum.ProjectTrackingMessage),
        ];
        this.state = {
            page: 0,
            rowsPerPage: 5,
            open: false,
            startHasValue: false,
            startDateFocus: false,
            endHasValue: false,
            endDateFocus: false,
            mobileOpen:false,
            selectedLawyersID: [],
            updatedSelectedID: [],
            loading:true,
            associatedLawyer: [],
            todoList:[],
            searchResults:[],
            isAnyLawyerSelected:false,
            openDialog: false,
            anchorEl:null,
            drawerOpen:false,
            taskViewId:"",
            projectIds:"",
            isExpanded:false,
            ViewTodoList:{
              attributes: {
                id: "",
                to_do_name: "",
                to_do_description: "",
                priority: "",
                status: "",
                deadline: "",
                account_id: 1,
                assign_lawyer: "",
                project_template_id: 1,
                created_at: "",
                updated_at: "",
                assigned_lawyers:{data:[]}
            }
            },
            selectedLawyers: [],
            acceptTodo:false,
            openToDo:false,
            filterDate:"",
            filterPriority:"",
            filterStatus:"",
            searchQuery:"",
            openPop:false,
            searchData:[],
            searchLawyer:[],
            isFilterPopupOpen:false,
            task_to_do:{
              to_do_name: "", 
              to_do_description: "", 
              priority: "", 
              deadline: "", 
              assigned_lawyer_ids: 1,
              assigned_lawyers:{data:[]}
            },
              project_template_id: 1,
              priority:"",
              responseShow:{
                success:"",
                errors:""},
                isEditMode:false,
                isFormValid :false,
                validationErrors: {
                  to_do_name: true, 
                  deadline: true,
                },
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
        this.associatedLawyers();
       
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(apiRequestCallId === this.getAssociatedLawyersId){
     this.handleApiResponse(responseJson)
  }
  if(apiRequestCallId === this.searchTodoId){
    this.handleTodoSearchApiResponse(responseJson)
  }  
  if(apiRequestCallId === this.getTodoListId){
    this.handleTodoApiResponse(responseJson)
 }  
 if(apiRequestCallId === this.getViewToDoId){
  if (responseJson.data) {
    this.setState({
      ViewTodoList: responseJson.data,
    });
  }
}  
if(apiRequestCallId === this.postCreateTodoId){
  this.handleToDoCreationResponse(responseJson)
}  
if(apiRequestCallId === this.postViewToDo){
  this.handleToDoCreationResponse(responseJson)
}  
if(apiRequestCallId === this.deleteTodoId){
  this.handleTodoDelete(responseJson)
}  
if(apiRequestCallId === this.putEditTodo){
  this.handleToDoEditResponse(responseJson)
}  
if(apiRequestCallId === this.getSearchApiCallId){

  if (responseJson.data) {
    this.setState({ searchData: responseJson.data});
  } else {
    this.setState({ searchData: [] });
  }


}
if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
  let taskId = message.getData(getName(MessageEnum.NavigationProjectTrackingId));  
  this.setState({projectIds:taskId},() =>  this.todoList())
}

    }
    handleToDoCreationResponse = (responseJson: { meta: { message: any; }; errors?: { message: string }[]; }) => {
      if (responseJson?.errors ){
        this.setState({
          openDialog: true,
          responseShow: { errors: responseJson.errors[0].message, success: "" } 
        });
      } else if (responseJson?.meta?.message) {
        this.setState({
          openDialog: true,
          responseShow: { errors: "", success: responseJson.meta.message },
          ViewTodoList:{
            attributes: {
              id: "",
              to_do_name: "",
              to_do_description: "",
              priority: "",
              status: "",
              deadline: "",
              account_id: 1,
              assign_lawyer: "",
              project_template_id: 1,
              created_at: "",
              updated_at: "",
              assigned_lawyers:{data:[]}
          }
          },
          searchLawyer:[],
          priority:"",
          searchQuery:""
        });
      }
    
      setTimeout(() => {
        this.setState({ openDialog: false });
      }, 700);
     
      this.setState({ searchData: [], openToDo:false,drawerOpen:false});
      this.todoList()
    };
    handleTodoDelete = (responseJson: {  message: any; errors?: { message: string }[]; }) => {
      if (responseJson?.errors ){
        this.setState({
          openDialog: true,
          responseShow: { errors: responseJson.errors[0].message, success: "" } 
        });
      } else if (responseJson?.message) {
        this.setState({
          openDialog: true,
          responseShow: { errors: "", success: responseJson.message },
        });
      }
    
      setTimeout(() => {
        this.setState({ openDialog: false });
      }, 700);
     
      this.setState({ searchData: [], openToDo:false,drawerOpen:false});
      this.todoList()
    };
    handleToDoEditResponse = (responseJson: { meta: { message: any; }; errors?: { message: string }[]; }) => {
      if (responseJson?.errors ){
        this.setState({
          openDialog: true,
          responseShow: { errors: responseJson.errors[0].message, success: "" } });
      } else if (responseJson?.meta?.message) {
        this.setState({openDialog: true,
          responseShow: { errors: "", success: responseJson.meta.message },
          ViewTodoList:{ attributes: {id: "",
              to_do_name: "",
              to_do_description: "",
              priority: "",
              status: "",
              account_id: 1,
              assign_lawyer: "",
              project_template_id: 1,
              created_at: "",
              updated_at: "",
              deadline: "",
              assigned_lawyers:{data:[]} } },
          searchLawyer:[],
          priority:"",
          searchQuery:"",
          isEditMode:false}); }
     setTimeout(() => {
        this.setState({ openDialog: false });
      }, 700);
     
      this.setState({ searchData: [],
         openToDo:false,
         drawerOpen:false});
      this.todoList()
    };
    handleApiResponse = (responseJson:any) => {
        if (responseJson.data) {
          this.setState({
            associatedLawyer: responseJson.data,
            loading: false
          });
        }
    };
    handleFilterPopupOpen = () =>{
      this.setState({isFilterPopupOpen:!this.state.isFilterPopupOpen})
    }
    handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, field: string) => {
      const { value } = e.target;
      this.setState((prevState) => {
        const updatedErrors = {
          ...prevState.validationErrors,
          [field]: !value.trim(),
        };
        return {
          ViewTodoList: {
            ...prevState.ViewTodoList,
            attributes: {
              ...prevState.ViewTodoList.attributes,
              [field]: value,
            },
          },
          validationErrors: updatedErrors,
          isFormValid:true
        };
      });
    };
  
    handleChanged = (newValue: { value: string; label: string } | null) => {
      if (newValue) {
        this.setState((prevState) => ({
          ViewTodoList: {
            ...prevState.ViewTodoList,
            attributes: {
              ...prevState.ViewTodoList.attributes, 
              priority: newValue.value, 
            },
          },
          isFormValid:true
        }));
      }
    };
    handleStatusChange = (newValue: { value: string; label: string}|null ) => {
      if(newValue){
        this.setState({filterStatus:newValue.value})
        this.FilterTodo();
      }
    };
    handlePriorityChange = (newValue: { value: string; label: string}|null ) => {
      if(newValue){
        this.setState({filterPriority:newValue.value})
        this.FilterTodo();
      }
     };
    handleDateChange = (event:any) => {
      if(event){
        this.setState({filterDate:event.target.value})
        this.FilterTodo();
      }
     };
    handleTodoApiResponse = (responseJson:any) => {
      if (responseJson.data) {
        this.setState({
          todoList: responseJson.data,
          loading: false
        });
      }
  };
  handleTodoSearchApiResponse = (responseJson:any) => {
    if (responseJson.data) {
      this.setState({
        todoList: responseJson.data,
        loading: false
      });
    }else{
      this.setState({
        todoList: [],
        loading: false
      });
    }
  }
  handleOpenToDo=()=>{
    this.setState({openToDo:true,
            validationErrors:{
              to_do_name:false,
              deadline:false
            }
    })
  }
  handleCloseBox=()=>{
    this.setState({openToDo:false,isEditMode:false,isFormValid:false,searchLawyer:[],searchQuery:"", ViewTodoList:{
      attributes: {
        id: "",
        to_do_name: "",
        to_do_description: "",
        priority: "",
        status: "",
        deadline: "",
        account_id: 1,
        assign_lawyer: "",
        project_template_id: 1,
        created_at: "",
        updated_at: "",
        assigned_lawyers: {
          data: []
        }
      }
    }})
  }
  handleClick = (event: { currentTarget: any; },id:string) => {
    this.setState(prevState => ({
      anchorEl: event.currentTarget,
      taskViewId: id, 
      open:!prevState.open
    }));
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };
    handleDrawerToggle = () => {
        this.setState({ mobileOpen: true });
      }
    
      handleclosesidebar = () => {
        this.setState({ mobileOpen: false });
    
      }
    handleNavigateDashboard = () => {
        const message: Message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage),
          "DashBoard");
        message.addData(getName(MessageEnum.NavigationPropsMessage),
          this.props);
    
        this.send(message);
      }
      handleSearchChange = (event: { target: { value: string; }; }) => {
        const query = event.target.value;
        this.setState({ 
           searchQuery: query, openPop: query.length > 0 }, () => {
          this.getSearchData(query);
        });
      };
      handleNavigateAllocator = () => {
        const message: Message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage),
          "TaskListLawfirm");
        message.addData(getName(MessageEnum.NavigationPropsMessage),
          this.props);
    
        this.send(message);
      }

      toggleDrawer = () => {   
        this.setState(prevState => ({
          drawerOpen: !prevState.drawerOpen, 
          anchorEl:null,
          open:false
        }));
        this.viewTodo()
       
      };
  handleSeeMoreClick = () => {
    this.setState((prevState) => ({
      isExpanded: !prevState.isExpanded,
    }));
  };
  toggleDrawerClose = () => {
    this.setState({ drawerOpen: false ,isExpanded:false}); 
  };
  handleLawyerSelect = (lawyer: ApiResponse) => {
    const { searchLawyer } = this.state;
    const lawyerId = lawyer?.attributes?.associated_lawyer_details?.data.id;
    const index = searchLawyer.findIndex(selected => 
      selected?.attributes?.associated_lawyer_details?.data?.id === lawyerId
    );
  
    if (index > -1) {
      this.setState(prevState => {
        const updatedLawyers = prevState.searchLawyer.filter((_, i) => i !== index);
        
        return { searchLawyer: updatedLawyers };
      });
    } else {
      const updatedLawyers = [...this.state.searchLawyer, lawyer];
  
      this.setState({ searchLawyer: updatedLawyers })
      this.setState({ openPop: false ,
        isFormValid:true
      }); 
    }
  };
  closePop=()=>{
    this.setState({openPop:false})
  }
  
    handleRemoveSelectedLawyer = (lawyer: any) => {
      this.setState((prevState) => ({
        searchLawyer: prevState.searchLawyer.filter(
          (selected) => selected?.attributes?.associated_lawyer_details?.data.id !== lawyer?.attributes?.associated_lawyer_details?.data?.id
        ),
      }));
    };
    handleRemoveSelectedLawyer2 = (lawyer: any) => {
      const lawyerId = lawyer?.attributes?.associated_lawyer_details?.data?.id;
    

      const updatedAssignedLawyers = this.state.ViewTodoList.attributes?.assigned_lawyers?.data.filter(
        (assignedLawyer: { attributes: { associated_lawyer_details: { data: { id: any; }; }; }; }) =>
          assignedLawyer.attributes?.associated_lawyer_details?.data?.id !== lawyerId
      );
    
   
      const updatedSearchLawyer = this.state.searchLawyer.filter(
        (selected) => selected?.attributes?.associated_lawyer_details?.data.id !== lawyerId
      );
    
      this.setState((prevState) => ({
        ViewTodoList: {
          ...prevState.ViewTodoList,
          attributes: {
            ...prevState.ViewTodoList.attributes,
            assigned_lawyers: {
              ...prevState.ViewTodoList?.attributes?.assigned_lawyers,
              data: updatedAssignedLawyers, 
            },
          },
        },
        searchLawyer: updatedSearchLawyer, 
      }));
    };
    

  handleEditToggle = (task:any) => {
 

    this.setState(prevState => ({
      openToDo: !prevState.openToDo,        
      anchorEl: null, 
      isEditMode:true,     
      validationErrors:{
        to_do_name:false,
        deadline:false
      }                      
    }));
  this.viewTodo()
  };
  handleDeleteTodo = () => {
    this.setState(prevState => ({      
      anchorEl: null,                          
    }));
  this.deleteToDo()
  };
  

  handleSettingIn = async () => {
    const ctoken: string = await getStorageData("clientTokes");

    const message = new Message(getName(MessageEnum.NavigationMessage));

    if (ctoken) {
      message.addData(getName(MessageEnum.NavigationTargetMessage), "ClientSettings");
    } else {
      message.addData(getName(MessageEnum.NavigationTargetMessage), "Settings");
    }

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  handleCloseDelete = () => {
    this.setState({ openDialog: false });
  };
  determineMainToken1 = (
    metaSignUpToken: { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string } },
    metaLoginToken: { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string } }
  ): { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string } } | null => {
    if (metaSignUpToken?.data?.id) {
      if (metaLoginToken?.id && metaSignUpToken.data.id !== metaLoginToken.id) {
        return metaLoginToken;
      }
      return metaSignUpToken;
    }

    if (metaLoginToken?.id) {
      return metaLoginToken;
    }

    return null; 
  };
  associatedLawyers = async () => {
    const signupTokens: string = await getStorageData("userdetails");
    
    const loginTokens: string = await getStorageData("token");
    const metaSignUpToken = JSON.parse(signupTokens);
    const metaLoginToken = JSON.parse(loginTokens);

    const maintoken = this.determineMainToken1(metaSignUpToken, metaLoginToken);

    const header: { "Content-Type": string; token: string | undefined } = {
      "token": maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token,
    "Content-Type": "application/json",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAssociatedLawyersId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.associatedLawyersApi}`
    );
 
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  todoList = async () => {
    const signupTokens: string = await getStorageData("userdetails");
  
    const loginTokens: string = await getStorageData("token");
    const metaLoginTokenn = JSON.parse(loginTokens);
    const metaSignUpTokenn = JSON.parse(signupTokens);
   

    const maintoken = this.determineMainToken1(metaSignUpTokenn, metaLoginTokenn);

    const header: { "Content-Type": string; token: string | undefined } = {
      "token": maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token, "Content-Type": "application/json",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTodoListId = requestMessage.messageId;

    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.todoListApi}${this.state.projectIds}`);
 
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod);

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  FilterTodo = async () => {
    
    const signupToken: string = await getStorageData("userdetails");
    const loginToken: string = await getStorageData("token");
    const metaLoginToken = JSON.parse(loginToken);
    const metaSignUpToken = JSON.parse(signupToken);
    

    const maintoken = this.determineMainToken1(metaSignUpToken, metaLoginToken);

    const header: { "Content-Type": string; token: string | undefined } = {
      "token": maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token, "Content-Type": "application/json",
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.searchTodoId = requestMessage.messageId;

    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.todoListApi}${this.state.projectIds}&search=true&status=${this.state.filterStatus}&priority=${this.state.filterPriority}&due_date=${this.state.filterDate}`);
 
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.getApiMethod);

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  viewTodo = async () => {
    const signupTokens: string = await getStorageData("userdetails");
    const loginTokens: string = await getStorageData("token");
    const metaSignUpToken = JSON.parse(signupTokens);
    const metaLoginToken = JSON.parse(loginTokens);

    const maintoken = this.determineMainToken1(metaSignUpToken, metaLoginToken);

    const header: { "Content-Type": string; token: string | undefined } = {"token": maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token,
    "Content-Type": "application/json", };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage) );
    this.getViewToDoId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.viewToDoApi}/${this.state.taskViewId}`
    );
 
    requestMessage.addData( getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);};
  finishTodo = async  ()=>{
    const signupTokens: string = await getStorageData("userdetails");
    const loginTokens: string = await getStorageData("token");
    const metaSignUpToken = JSON.parse(signupTokens);
    const metaLoginToken = JSON.parse(loginTokens);

    const maintoken = this.determineMainToken1(metaSignUpToken, metaLoginToken);
    const requestBody = {
      "finished": "true" 
     
    };
    const header: { "Content-Type": string; token: string | undefined } = {
      "token": maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token,
    "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.postViewToDo = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.viewToDoApi}/${this.state.taskViewId}`
    );
 
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getSearchData= async (query:any) => {
    const signupTokens: string = await getStorageData("userdetails");
    const loginTokens: string = await getStorageData("token");
    const metaSignUpToken = JSON.parse(signupTokens);
    const metaLoginToken = JSON.parse(loginTokens);

    const maintoken = this.determineMainToken1(metaSignUpToken, metaLoginToken);
    const header = {
      "token": maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token,
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSearchApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_catalogue/catalogues/list_of_associated_lawyers?search=true&query=${query}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getValue = (trueSta:any, falseSta: any) => {
    if(trueSta){
      return trueSta;
    }else{
      return falseSta
    }

  }
  createToDo= async()=>{
    const assignedLawyerIds = (this.state.searchLawyer.map(lawyer => 
      Number(lawyer.attributes.associated_lawyer_details.data.id)
    ));
    const requestBody = {
      "task_to_do":{
        "to_do_name":this.state.ViewTodoList.attributes.to_do_name,
        "assigned_lawyer_ids": assignedLawyerIds,
        "priority": this.state.ViewTodoList.attributes.priority,
        "deadline":this.state.ViewTodoList.attributes.deadline,
        "to_do_description":this.state.ViewTodoList.attributes.to_do_description,
      },
      "project_template_id":this.state.projectIds,
     
    };
    const signupTokens: string = await getStorageData("userdetails");
    const loginTokens: string = await getStorageData("token");
    const metaSignUpToken = JSON.parse(signupTokens);
    const metaLoginToken = JSON.parse(loginTokens);

    const maintoken = this.determineMainToken1(metaSignUpToken, metaLoginToken);
    const header = {
      "token": this.getValue(maintoken?.serialized_data?.meta?.token , maintoken?.meta?.token),
      "Content-Type": "application/json",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postCreateTodoId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.postCreateTodoApi}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );
    
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  editTodo = async  ()=>{
    const signupTokens: string = await getStorageData("userdetails");
    const loginTokens: string = await getStorageData("token");
    const metaSignUpToken = JSON.parse(signupTokens);
    const metaLoginToken = JSON.parse(loginTokens);

    const maintoken = this.determineMainToken1(metaSignUpToken, metaLoginToken);
    const assignedLawyerIds = (this.state.searchLawyer.map(lawyer => 
      Number(lawyer.attributes.associated_lawyer_details.data.id)
    ));
    const viewAssignedIds=this.state.ViewTodoList.attributes.assigned_lawyers.data?.map((obj)=>obj.id)

    const requestBody = {
      "task_to_do":{
        "to_do_name":this.state.ViewTodoList.attributes.to_do_name,
        "assigned_lawyer_ids": assignedLawyerIds.length > 0 ? assignedLawyerIds : viewAssignedIds ,
        "priority": this.state.ViewTodoList.attributes.priority,
        "deadline":this.state.ViewTodoList.attributes.deadline,
        "to_do_description":this.state.ViewTodoList.attributes.to_do_description,
      },
     
    };
    const header: { "Content-Type": string; token: string | undefined } = {
      "token": maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token,
    "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.putEditTodo = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.viewToDoApi}/${this.state.taskViewId}`
    );
 
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  
  }
  deleteToDo = async () =>{
    const signupTokens1: string = await getStorageData("userdetails");
    const loginTokens1: string = await getStorageData("token");
    const metaSignUpToken1 = JSON.parse(signupTokens1);
    const metaLoginToken1 = JSON.parse(loginTokens1);

    const maintoken = this.determineMainToken1(metaSignUpToken1, metaLoginToken1); 
    const header: { "Content-Type": string; token: string | undefined } = {
      "token": maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token,
    "Content-Type": "application/json", };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.deleteTodoId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.deleteTodo}/${this.state.taskViewId}`);
 
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header));
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod );

    runEngine.sendMessage(requestMessage.id, requestMessage);}
   
}
// Customizable Area End
