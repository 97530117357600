// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import { BuilderProvider } from '@builder/component-library';

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";
import 'react-phone-input-2/lib/style.css'
import 'react-toastify/dist/ReactToastify.css';
import 'react-calendar/dist/Calendar.css';

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import Customisableusersubscriptions from "../../blocks/customisableusersubscriptions/src/Customisableusersubscriptions";
import SubscriptionDetails from "../../blocks/customisableusersubscriptions/src/SubscriptionDetails";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import TermsConditionsWeb from "../../blocks/termsconditions/src/TermsConditions.web";
import ErrorSuccessPopUp from "../../components/src/ErrorSuccessPopUp.web"

import InvoiceBilling from "../../blocks/invoicebilling/src/InvoiceBilling";
import Catalogue from "../../blocks/catalogue/src/Catalogue.web";
import ServicesManagement from "../../blocks/catalogue/src/ServicesManagement.web"
import LawyerDashboard from '../../blocks/dashboard/src/LawyerDashBoard.web'
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Cfapireceiveofjobsonclientstrademark from "../../blocks/cfapireceiveofjobsonclientstrademark/src/Cfapireceiveofjobsonclientstrademark";
import SaveAsPdf from "../../blocks/saveaspdf/src/SaveAsPdf";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Settings from "../../blocks/settings2/src/Settings2.web";
import ClientSettings from '../../blocks/settings2/src/ClientSettings.web';
import SettingsNotification from "../../blocks/settings2/src/Notification.web";
import CompanyInformation from "../../blocks/settings2/src/ComanyInformation.web"
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import Subscriptionbilling2 from "../../blocks/subscriptionbilling2/src/Subscriptionbilling2";
import Notifications from "../../blocks/notifications/src/Notifications";
import PdfEdit from "../../blocks/pdfedit/src/PdfEdit";
import Paymentadmin2 from "../../blocks/paymentadmin2/src/Paymentadmin2";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import EmailAccountRegistrations from "../../blocks/email-account-registration/src/EmailAccountRegistration.web";
import lawyerManagement from "../../blocks/categoriessubcategories/src/Categoriessubcategories.web";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Analytics from "../../blocks/analytics/src/Analytics";
import ProductDescription from "../../blocks/productdescription/src/ProductDescription.web";
import AddEditServices from "../../blocks/productdescription/src/AddEditServices.web"
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import EmailAccountLogin from "../../blocks/email-account-login/src/EmailAccountLoginBlock.web";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import ClientDashboard from '../../blocks/dashboard/src/ClientDashboard.web'
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import TaskListLawfirm from "../../blocks/tasks/src/Tasks.web";
import TaskList from "../../blocks/tasks/src/TaskList";
import Task from "../../blocks/tasks/src/Task";
import TaskAllocator from '../../blocks/tasks/src/TaskAllocator.web'
import Reviews from "../../blocks/reviews/src/Reviews";
import AddReview from "../../blocks/reviews/src/AddReview";
import RequestManagement from "../../blocks/requestmanagement/src/RequestManagement";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import Chat from "../../blocks/chat/src/Chat";
import ViewChat from "../../blocks/chat/src/ViewChat";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import Servicespecificsettingsadmin2 from "../../blocks/servicespecificsettingsadmin2/src/Servicespecificsettingsadmin2";
import OrderManagement from "../../blocks/ordermanagement/src/OrderManagement";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import LandingPageWeb from "../../blocks/landingpage/src/LandingPage.web";
import ResetPasswordWeb from "../../blocks/forgot-password/src/ResetPassword.web"
import ForgotPasswordWeb from '../../blocks/forgot-password/src/ForgotPassword.web';
import CustomisableUserProfile from '../../blocks/customisableuserprofiles/src/CustomisableUserProfiles.web';
import DashBoard from '../../blocks/dashboard/src/Dashboard.web'
import AdminLawyerManagement from'../../blocks/catalogue/src/AdminLawyerMangement.web'
import Editlawyer from '../../blocks/categoriessubcategories/src/EditCategoriessubcategories.web'
import ClientCompanyInformationWeb from '../../blocks/settings2/src/ClientComapnyInformation.web'
import ClientNotifcation from "../../blocks/settings2/src/ClientNotification.web"
import projectTemplates from '../../blocks/projecttemplates/src/ProjectTemplates.web'
import projectTracking from '../../blocks/projectreporting/src/ProjectReporting.web'
import TaskListLawyer from  "../../blocks/tasks/src/Task.web";
import JobListing from '../../blocks/joblisting/src/JobListing.web'
import 'react-calendar/dist/Calendar.css'
import AdvancedSearch from '../../blocks/advancedsearch/src/AdvancedSearch.web'
import ClientLawyerCatalogue from '../../blocks/catalogue/src/ClientLawyerCatalogue.web'
import Documentation from '../../blocks/documentation/src/Documentation.web'



const routeMap = {
  Documentation:{
    component:Documentation,
    path:'/Documentation'
  },
  ClientLawyerCatalogue:{
  component:ClientLawyerCatalogue,
  path:"/ClientLawyerCatalogue"
},
AdvancedSearch:{
  component:AdvancedSearch,
path:"/AdvancedSearch"},
AccountGroups:{
 component:AccountGroups,
path:"/AccountGroups"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
StripePayments:{
 component:StripePayments,
path:"/StripePayments"},
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
Customisableusersubscriptions:{
 component:Customisableusersubscriptions,
path:"/Customisableusersubscriptions"},
SubscriptionDetails:{
 component:SubscriptionDetails,
path:"/SubscriptionDetails"},
TermsConditionsWeb:{
  component:TermsConditionsWeb,
 path:"/TermsCondition"},
 Editlawyer:{
  component:Editlawyer,
 path:"/Editlawyer"},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
InvoiceBilling:{
 component:InvoiceBilling,
path:"/InvoiceBilling"},
lawfirmManagement:{
 component:Catalogue,
path:"/lawfirmManagement"},
ServicesManagement:{
  component:ServicesManagement,
  path: "/ServicesManagement"
},
LawyerDashboard:{
  component:LawyerDashboard,
 path:"/LawyerDashboard"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
ProductDescription:{
  component:ProductDescription,
  path:"/ProductDescription"
},
AddEditServices:{
  component:AddEditServices,
  path:'/AddEditServices'
},
Cfapireceiveofjobsonclientstrademark:{
 component:Cfapireceiveofjobsonclientstrademark,
path:"/Cfapireceiveofjobsonclientstrademark"},
SaveAsPdf:{
 component:SaveAsPdf,
path:"/SaveAsPdf"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
Settings:{
  component:Settings,
 path:"/Settings"},
 SettingsNotification:{
  component: SettingsNotification,
  path:"/Settings-Notification",
 },
 CompanyInformation:{
  component: CompanyInformation,
  path:"/Settings-CompanyInfomation",
 },
 CustomisableUserProfile:{
  component: CustomisableUserProfile,
  path:"/CustomisableUserProfile",
 },
 DashBoard:{
  component: DashBoard,
  path:"/DashBoard",
 },
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
Subscriptionbilling2:{
 component:Subscriptionbilling2,
path:"/Subscriptionbilling2"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
PdfEdit:{
 component:PdfEdit,
path:"/PdfEdit"},
Paymentadmin2:{
 component:Paymentadmin2,
path:"/Paymentadmin2"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
EmailAccountRegistration:{
  component:EmailAccountRegistrations,
 path:"/EmailAccountRegistration"},
 lawyerManagement:{
 component:lawyerManagement,
path:"/lawyerManagement"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
EmailAccountLogin:{
  component:EmailAccountLogin,
 path:"/EmailAccountLogin"},
NavigationMenu:{
 component:NavigationMenu,
path:"/NavigationMenu"},
AdminLawyerManagement:{
  component:AdminLawyerManagement,
  path:"/AdminLawyerManagement"},
ClientDashboard:{
  component:ClientDashboard,
  path:"/ClientDashboard"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
LandingPage:{
  component:LandingPageWeb,
 path:"/LandingPage"},
 ResetPassword:{
  component:ResetPasswordWeb,
 path:"/ForgotPassword"},
 ForgotPassword:{
  component:ForgotPasswordWeb,
 path:"/ResetPassword"},
 TaskListLawfirm:{
 component:TaskListLawfirm,
path:"/TaskListLawfirm"},
TaskAllocator:{
  component:TaskAllocator,
 path:"/TaskAllocator"},
ClientSettings:{
  component:ClientSettings,
 path:"/ClientSettings"},
 ClientCompanyInformationWeb:{
  component:ClientCompanyInformationWeb,
 path:"/ClientCompanyInformationWeb"},
 ClientNotifcation:{
  component:ClientNotifcation,
 path:"/ClientNotifcation"},
 
 TaskListLawyer:{
 component:TaskListLawyer,
path:"/TaskListLawyer"},
TaskList:{
  component:TaskList,
 path:"/TaskList"},
 JobListing:{
  component:JobListing,
 path:"/JobListing"},
ErrorSuccessPopUp:{
  component:ErrorSuccessPopUp,
 path:"/ErrorSuccessPopUp"},
Task:{
 component:Task,
path:"/Task"},
Reviews:{
 component:Reviews,
path:"/Reviews"},
AddReview:{
 component:AddReview,
path:"/AddReview"},
RequestManagement:{
 component:RequestManagement,
path:"/RequestManagement"},
Rolesandpermissions2:{
 component:Rolesandpermissions2,
path:"/Rolesandpermissions2"},
Chat:{
 component:Chat,
path:"/Chat"},
projectTemplates:{
  component:projectTemplates,
 path:"/projectTemplates"},
 projectTracking:{
  component:projectTracking,
 path:"/projectTracking"},
ViewChat:{
 component:ViewChat,
path:"/ViewChat"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
Servicespecificsettingsadmin2:{
 component:Servicespecificsettingsadmin2,
path:"/Servicespecificsettingsadmin2"},
OrderManagement:{
 component:OrderManagement,
path:"/OrderManagement"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},

  Home: {
    component: LandingPage,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <BuilderProvider>
        <View style={{ height: '100vh', width: '100vw' }}>
          {/* <TopNav /> */}
          {WebRoutesGenerator({ routeMap })}
          <ModalContainer />
        </View>
      </BuilderProvider>
    );
  }
}

export default App;