// Customizable Area Start

import React from "react";

import {
  Box,
  Typography,
  IconButton,
  styled,
  Divider,
  LinearProgress,
  CircularProgress
} from "@material-ui/core";

import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import { DeleteOutlineOutlined, ExpandLessOutlined, InsertDriveFile, MoreVertOutlined} from "@material-ui/icons";
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import FilterListOutlinedIcon from '@material-ui/icons/FilterListOutlined';
import FormatListBulletedOutlinedIcon from '@material-ui/icons/FormatListBulletedOutlined';
import AppsOutlinedIcon from '@material-ui/icons/AppsOutlined';
import ChevronRightOutlinedIcon from '@material-ui/icons/ChevronRightOutlined';
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import FolderOutlinedIcon from '@material-ui/icons/FolderOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import PostAddOutlinedIcon from '@material-ui/icons/PostAddOutlined';
import FolderIcon from '@material-ui/icons/Folder';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import OpenWithIcon from '@material-ui/icons/OpenWith';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import RestoreIcon from '@material-ui/icons/Restore';
import ArchiveOutlinedIcon from '@material-ui/icons/ArchiveOutlined';

import DocumentationController, {
  Props,
} from "./DocumentationController";
import { docI, jpeg, logo, mp4I, pdfI, pngFI, pptI, userP, xlsI } from "./assets";

export default class Documentation extends DocumentationController {
  constructor(props: Props) {
    super(props);
  }

  drawer = () => (
    <DrawerBox style={{ display: this.state.mobileOpen ? 'block' : '' }}>
      <div className="toolbar" />
      <Divider />
      <div className="logobox">
        <img src={logo} alt="logo" className="logoimag" />
      </div>
      {this.state.mobileOpen &&
        <CloseIcon style={{ position: 'fixed', top: '30px', left: '260px', cursor: 'pointer' }}
          onClick={this.handleclosesidebar} />
      }
      <div className="settingbox" data-test-id="settingid" onClick={this.handleDashboardNavigation}>
        <HomeOutlinedIcon className="lefticon" />
        <Typography className="settingtext">Dashboard</Typography>
      </div>
    </DrawerBox>
  )

  buttonMenu = () => (
    <ButtonBox className="buttonbox">
      <IconButton
        className="iconbtn"
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={this.handleDrawerToggle}
        style={{ marginRight: '24px', color: '#000' }}
      >
        <MenuIcon />
      </IconButton>
    </ButtonBox>
  )

  infoModal = () => {
    return (
      <InfoModal style={{ zIndex: '99999' }}>
        <Box className="infoIconBox"><InfoOutlinedIcon style={{ fontSize: '40px' }} className="infoIconI" /></Box>
        <Box className="fileFormatsBox">
          <Box className="fileFormatsBoxText" style={{ textWrap: 'nowrap' }}>Supported file formats : </Box>
          <span className="fileFormats">PDF,</span>
          <span className="fileFormats">DOC,</span>
          <span className="fileFormats">XLS,</span>
          <span className="fileFormats">PPT,</span>
          <span className="fileFormats">JPEG,</span>
          <span className="fileFormats">PNG,</span>
          <span className="fileFormats">MP4</span>
        </Box>
        <Box className="fileSizeBox">Maximum file size :&nbsp;<Box className="fileFormats">50MB</Box></Box>
      </InfoModal>
    )
  }

  moreOptions = () => {
    const currentFolder = this.state.folderPath[this.state.folderPath.length - 1];
    const isArchiveSection = currentFolder.folderName === "Archive Section";

    const options = [
      {
        condition: isArchiveSection,
        icon: <RestoreIcon />,
        label: "Restore",
        onClick: this.handleRestoreFileOrFolder,
      },
      {
        condition: isArchiveSection,
        icon: <DeleteOutlineOutlinedIcon />,
        label: "Delete Permanently",
        onClick: this.handleDeleteFileFolder,
      },
      {
        condition: !isArchiveSection,
        icon: <OpenWithIcon />,
        label: "Move",
        onClick: this.handleMoveModalPopUp,
      },
      {
        condition: !isArchiveSection,
        icon: <EditOutlinedIcon />,
        label: "Rename",
        onClick: this.handleRenameModalPopUp,
      },
      {
        condition: !isArchiveSection,
        icon: <DeleteOutlineOutlinedIcon />,
        label: "Delete",
        onClick: this.handleOpenDeleteModalPopUp,
      },
    ];

    let topValue;
    let rightValue;
    let paddingValue;

    if (isArchiveSection) {
      topValue = '0px';
      rightValue = '45px';
      paddingValue = '10px 5px 10px 0px';

    } else {
      paddingValue = '10px 20px 10px 10px'
      if (this.state.viewMode === 'list') {
        topValue = '0px';
        rightValue = '80px';
      } else {
        topValue = '30px';
        rightValue = '50px';
      }
    }

    return (
      <MoreOptions style={{ 
        zIndex: '9999' ,
        top: topValue,
        right: rightValue,
        padding: paddingValue
        }}>
        {options.map((option, index) =>
          option.condition && (
            <Box key={index} className="optionRow" onClick={option.onClick}>
              {option.icon}
              <Box>{option.label}</Box>
            </Box>
          )
        )}
      </MoreOptions>
    );
  }

  moreOptionsF = () => {
    const currentFolder = this.state.folderPath[this.state.folderPath.length - 1];
    const isArchiveSection = currentFolder.folderName === "Archive Section";

    const options = [
      {
        condition: isArchiveSection,
        icon: <RestoreIcon />,
        label: "Restore",
        onClick: this.handleRestoreFileOrFolder,
      },
      {
        condition: isArchiveSection,
        icon: <DeleteOutlineOutlinedIcon />,
        label: "Delete Permanently",
        onClick: this.handleDeleteFileFolder,
      },
      {
        condition: !isArchiveSection,
        icon: <GetAppOutlinedIcon />,
        label: "Download",
      },
      {
        condition: !isArchiveSection,
        icon: <OpenWithIcon />,
        label: "Move",
        onClick: this.handleMoveModalPopUp,
      },
      {
        condition: !isArchiveSection,
        icon: <EditOutlinedIcon />,
        label: "Rename",
        onClick: this.handleRenameModalPopUp,
      },
      {
        condition: !isArchiveSection,
        icon: <DeleteOutlineOutlinedIcon />,
        label: "Delete",
        onClick: this.handleOpenDeleteModalPopUp,
      },
    ];

    let topValue;
    let rightValue;

    if (isArchiveSection) {
      topValue = '20px';
      rightValue = '15px';
    } else {
      if (this.state.viewMode === 'list') {
        topValue = '0px';
        rightValue = '80px';
      } else {
        topValue = '-35px';
        rightValue = '60px';
      }
    }

    return (
      <MoreOptionsF style={{
        zIndex: '9999',
        top: topValue,
        right: rightValue
      }}>
        {options.map((option, index) =>
          option.condition && (
            <Box key={index} className="optionRow" onClick={option.onClick}>
              {option.icon}
              <Box>{option.label}</Box>
            </Box>
          )
        )}
      </MoreOptionsF>
    );
  }

  fileTypeImage = (contentType: string) => {
    let mimeType = contentType.split('/')[1];
    let purl = '';
    switch (mimeType) {
      case "pdf": purl = pdfI; break;
      case "doc": purl = docI; break;
      case "xls": purl = xlsI; break;
      case "ppt": purl = pptI; break;
      case "jpeg": purl = jpeg; break;
      case "jpg": purl = jpeg; break;
      case "png": purl = pngFI; break;
      case "mp4": purl = mp4I; break;
      case "vnd.openxmlformats-officedocument.wordprocessingml.document": purl = docI; break;
      case "x-ole-storage": purl = docI; break;
    }
    return (
      <img src={purl} width={'50px'} height={'50px'} />
    )
  }

  topBox = () => {
    return (
      <TopBox data-test-id={"topBoxTestId"}>
        <Box className="topBoxHeader">
          <Box className="backIconBox" data-test-id="navigationBackId" onClick={this.handleBackNavigation}><ArrowBackOutlinedIcon className="backIcon" /></Box>
          <Box className="headerContent">Folder / Conduct Treadmark Search</Box>
        </Box>
        <Box className="topBoxSubHeader">
          <Box data-test-id="folderPathTestId" className="subHeaderContent">
            <Box>Task</Box>
            {
              this.state.folderPath.map((x: any, i: number) => (
                <Box style={{ display: 'flex', alignItems: 'center' }}>
                  <ChevronRightOutlinedIcon className="rightIcon" />
                  <Box data-test-id={`folderPath${i}`} onClick={() => this.handleFileFolderPath(i)}>{x.folderName}</Box>
                </Box>
              ))
            }
          </Box>
        </Box>
        <Box className="optionsBar">
          <Box className="searchBar">
            <Box className="searchIconBox"><SearchOutlinedIcon className="searchIcon" /></Box>
            <Box className="searchFieldBox">
              <input type="text" className="searchField" placeholder="Search by name" /></Box>
          </Box>
          <Box className="optionsBarSubBox">
            <Box className="filterBox">
              <FilterListOutlinedIcon className="filterIcon" />
            </Box>
            {this.state.folderPath[this.state.folderPath.length - 1]?.folderName !== "Archive Section" &&
              (
                <Box data-test-id={'addNewFolderTestId'} onClick={this.handleNewFolderModalPopUp} className="addNewFolder" style={{ textWrap: 'nowrap' }}>Add New Folder</Box>
              )
            }
            <Box className="viewFilterBox">
              <Box className="listBox" style={{
                backgroundColor: this.state.viewMode === 'list' ? '#dbecfd' : 'white'
              }}
                data-test-id="handleViewModeListId"
                onClick={() => this.handleViewMode('list')}
              >
                <FormatListBulletedOutlinedIcon className={` ${this.state.viewMode === 'list' ? 'listIcon' : 'listIconi'} `} />
              </Box>
              <Box className="gridBox" style={{
                backgroundColor: this.state.viewMode === 'grid' ? '#dbecfd' : 'white'
              }}
                data-test-id="handleViewModeGridId"
                onClick={() => this.handleViewMode('grid')}
              >
                <AppsOutlinedIcon className={` ${this.state.viewMode === 'grid' ? 'gridIcon' : 'listIconi'} `} />
              </Box>
            </Box>
          </Box>
        </Box>
        {this.state.folderPath[this.state.folderPath.length - 1]?.folderName === "Archive Section" &&
          <ArchiveSectionMsg>
            <Box className="archCon">
              <ArchiveOutlinedIcon className="archIcon" />
              <Box className="archMsg">Items from archive section will be deleted permanently after 30 days</Box>
            </Box>
            <Box className="archBtn" onClick={this.handleEmptyArchive}>Empty Archive section</Box>
          </ArchiveSectionMsg>
        }

      </TopBox>
    )
  }

  gridBox = () => {
    return (
      <>
        {this.state.folders.length === 0 &&
          this.state.files.length === 0 &&
          this.state.folderPath[this.state.folderPath.length - 1]?.folderName === "Archive Section" &&
          (
            <NoFilesOrFolder className="">
              <FolderOutlinedIcon className="noDataImg" />
              <Box className="noDataH1">There is nothing the archive section</Box>
              <Box className="noDataH2">items you deleted will be moved to the archive section. items in the archive section we be permanently deleted after 30 days</Box>
            </NoFilesOrFolder>
          )
        }
        {this.state.pageLoading && <CircularProgress style={{ margin: '20% auto' }} />}
        {this.state.folders.length === 0 &&
          !this.state.archiveFolder &&
          this.state.files.length === 0 &&
          this.state.folderPath[this.state.folderPath.length - 1]?.folderName !== "Archive Section" &&
          (
            <NoFilesOrFolder className="">
              <FolderOutlinedIcon className="noDataImg" />
              <Box className="noDataH1">There is nothing this Folder</Box>
              <Box className="noDataH2">Add files or create folder.</Box>
            </NoFilesOrFolder>
          )
        }
        {this.state.folders.length > 0 && <Box className="headerContent">Folders</Box>}

        <Box style={{ width: '90%', display: 'flex', flexWrap: 'wrap', gap: '2rem', justifyContent: 'start' }}>
          {
            this.state.folders.map((x: any, index: number) => {
              const isOpen = this.state.openOptionIndex === index;
              return (
                <Box className="moreOne" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', border: '1px solid #6b92ff', width: '300px', height: '100px', borderRadius: '10px', position: 'relative' }}>
                  <Box style={{ width: '70%', height: '100%', display: 'flex', alignItems: 'center', gap: '0.5rem', justifyContent: "center" }}>
                    <FolderOutlinedIcon style={{ borderRadius: '50%', backgroundColor: 'rgb(239 239 239)', padding: '12px', color: '#345ee6' }} />
                    <Box>
                      <Box data-test-id="openSubFolderId" onClick={() => this.openFolder(x)}>
                        {x.data.attributes.folder_name}
                      </Box>
                      <Box style={{ fontSize: '10px', color: 'gray' }}>{this.formatDateTime(x.data.attributes.created_at)}</Box>
                    </Box>
                  </Box>
                  <Box style={{ display: 'flex', gap: '5px', alignItems: 'center', borderRadius: '10px', backgroundColor: 'rgb(219, 236, 253)', color: 'rgb(107, 146, 255)', fontSize: '13px', position: 'absolute', top: '5px', right: '5px', padding: '1px 0 1px 5px' }}>
                    <Box>{x.data.attributes.account.data?.attributes.first_name || 'User Name'}</Box>
                    <Box style={{ width: '20px', height: '20px' }}><img src={x.data.attributes.profile ? x.data.attributes.profile : userP} width={'100%'} height={'100%'} /></Box>
                  </Box>
                  <Box data-test-id="openFolderMoreOptionsId"
                    onClick={() => this.handleFolderMoreOption(index, x)}>
                    <MoreVertOutlined className="tripledotcss" style={{ paddingRight: '1rem' }} />
                  </Box>
                  {isOpen && this.moreOptions()}
                </Box>

              )
            })
          }
          {
            this.state.folderPath[this.state.folderPath.length - 1]?.folderName === "Document Folder" &&
            (
              <Box style={{ backgroundColor: '#dbecfd', border: '1px solid #6b92ff', width: '300px', height: '100px', borderRadius: '10px' }}>
                <Box style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', gap: '0.5rem', justifyContent: "center" }}>
                  <ArchiveOutlinedIcon style={{ borderRadius: '50%', backgroundColor: 'white', padding: '12px', color: '#345ee6' }} />
                  <Box data-test-id="openArchiveFolderId" onClick={() => this.openFolder(this.state.archiveFolder)}>
                    {this.state.archiveFolder?.data?.attributes.folder_name === "Archive Section" && "Archive Section"}
                  </Box>
                  <ChevronRightOutlinedIcon />
                </Box>
              </Box>
            )
          }

        </Box>
        {this.state.files.length > 0 && <Box className="headerContent">Files</Box>}


        <Box style={{ width: '90%', display: 'flex', flexWrap: 'wrap', gap: '2rem', justifyContent: 'start' }}>
          {
            this.state.files.map((x: any, index: number) => {
              const isOpen = this.state.openOptionIndexF === index;
              return (
                <Box style={{ display: 'flex', flexDirection: 'column', gap: '10px', padding: '30px', border: '1px solid #6b92ff', width: '240px', height: '150px', borderRadius: '10px', position: 'relative' }}>
                  <Box style={{ borderRadius: '10px', backgroundColor: 'rgb(238, 232, 232)', width: '100%', height: '70%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {this.fileTypeImage(x.content_type)}
                  </Box>
                  <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Box>
                      <Box style={{ fontSize: '20px' }}>{x.filename}</Box>
                      <Box style={{ fontSize: '12px', color: 'gray' }}>Uploaded {this.formatDateTime(x.created_at)}</Box>
                    </Box>
                    <Box style={{ display: 'flex', gap: '5px', alignItems: 'center', borderRadius: '10px', backgroundColor: 'rgb(219, 236, 253)', color: 'rgb(107, 146, 255)', fontSize: '13px', position: 'absolute', top: '20px', right: '20px', padding: '1px 0 1px 5px' }}>
                      <Box style={{ width: '20px', height: '20px' }}><img src={x.account_profile_image ? x.account_profile_image : userP} width={'100%'} height={'100%'} /></Box>
                      <Box>{x.account_name}</Box>
                    </Box>
                    <Box data-test-id="openFilesMoreOptionsId" onClick={() => this.handleFileMoreOption(index, x)}>
                      <MoreVertOutlined />
                    </Box>
                    {isOpen && this.moreOptionsF()}
                  </Box>
                </Box>
              )
            })
          }
        </Box>
      </>
    )
  }

  listBox = () => {
    return (
      <>
        <Box style={{ width: '95%',height:'100%', display: 'flex', flexWrap: 'wrap', gap: '0.5rem', justifyContent: 'center' }}>
          <Box style={{ display: 'flex', width: '100%', padding: '10px 0', color: 'rgb(157, 144, 144)' }}>
            <Box style={{ padding: '10px 0', width: '5%' }}><FolderOutlinedIcon style={{ color: '#BFBFBF' }} /></Box>
            <Box style={{ padding: '10px', width: '30%', marginLeft: '3.5%' }}>Name</Box>
            <Box style={{ padding: '10px', width: '20%' }}>Date Uploaded</Box>
            <Box style={{ padding: '10px', width: '30%' }}>Owner</Box>
            <Box style={{ padding: '10px', width: '5%' }}>&nbsp;&nbsp;&nbsp;</Box>
          </Box>
          <Box style={{ width: '100%', height: '88%', overflowY: 'scroll' }}>
            {
              [...this.state.folders, ...this.state.files].map((x: any, i: number) => {
                const isOpen = this.state.openOptionIndexL === i;

                let imageSrc;
                let accountName;

                if (x.data) {
                  imageSrc = x.data.attributes.profile ? x.data.attributes.profile : userP;
                } else {
                  imageSrc = x.account_profile_image ? x.account_profile_image : userP;
                }

                if (x.data) {
                  accountName = x.data.attributes.account?.data?.attributes.first_name;
                } else {
                  accountName = x.account_name;
                }

                const formattedDate = x.data 
                ? this.formatDateTimeA(x.data.attributes.created_at) 
                : this.formatDateTimeA(x.created_at);

                return(
                  <Box style={{ display: 'flex', width: '100%', padding: '10px 0' }}>
                      {x.data ? <FolderIcon style={{padding:'8px',backgroundColor: '#BFBFBF', color: 'white', borderRadius: '50%'}} /> 
                      : 
                      <InsertDriveFile style={{padding:'8px',backgroundColor: '#BFBFBF', color: 'white', borderRadius: '50%'}} />}
                    <Box style={{ padding: '10px', width: '30%', marginLeft: '5%' }}>{x.data ? x.data.attributes.folder_name : x.filename}</Box>
                    <Box style={{ padding: '10px', width: '20%', display: 'flex', justifyContent: 'start', alignItems: 'centeer', gap: '0.5rem' }}>
                      <Box style={{ minWidth: '40%' }}>{formattedDate}</Box>
                      <CalendarTodayIcon style={{ color: 'rgb(157, 144, 144)' }} />
                    </Box>
                    <Box style={{ padding: '10px', width: '30%', display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                      <img src={imageSrc} width={'20px'} height={'20px'} />
                      {accountName}</Box>
                    <Box data-test-id="moreOptionsListViewId" style={{ padding: '10px', width: '5%', position:'relative' }} onClick={() => this.handleListMoreoptions(i,x)} ><MoreVertOutlined />
                      {isOpen && (x.data ? this.moreOptionsF() : this.moreOptions())}
                    </Box>
                  </Box>
                )
              }
              )
            }
            {
            this.state.folderPath[this.state.folderPath.length - 1]?.folderName === "Document Folder" &&
            <Box style={{ display: 'flex', width: '100%', padding: '10px 0' }}>
              <FolderIcon style={{ padding: '8px', backgroundColor: '#BFBFBF', color: 'white', borderRadius: '50%' }} />
              <Box style={{ padding: '10px', width: '30%', marginLeft: '5%' }} data-test-id="openFolderArchiveListTestId"  onClick={() => this.openFolder(this.state.archiveFolder)}>{'Archive Section'}</Box>
              <Box style={{ padding: '10px', width: '20%', display: 'flex', justifyContent: 'start', alignItems: 'centeer', gap: '0.5rem' }}>
              </Box>
            </Box>
            }
          </Box>
        </Box>
      </>
    )
  }

  rightBox = () => {
    return (
      <RightBox data-test-id={"rightBoxTestId"}>
        {this.topBox()}
        <MainBox>

          <Box className={`${this.state.folderPath[this.state.folderPath.length - 1]?.folderName !== "Archive Section" ? 'folderBox' : 'folderBoxA'} ${this.state.viewMode === 'grid' ? 'noScroll' : 'showScroll'}`}>
            {
              this.state.viewMode === 'grid' ?
                (
                  this.gridBox()
                )
                :
                (
                  this.listBox()
                )
            }
          </Box>
          {
            this.state.folderPath[this.state.folderPath.length - 1]?.folderName !== "Archive Section" &&
            (
              <Box className="uploadBox">
                <Box className="uploadHeading">
                  <Box>Upload Files</Box>
                  <Box data-test-id={'infoModalTestId'} className="infoIconBox infoIcss" onClick={this.handleInfoModal}><InfoOutlinedIcon className="infoIcon" /></Box>
                  {this.state.infoModal && this.infoModal()}
                </Box>
                <Box data-test-id="uploadDragZoneId" className="uploadDragZone"
                  onDrop={this.handleDrop}
                  onDragOver={(e) => e.preventDefault()}
                >
                  <PostAddOutlinedIcon className="dragIcon" />
                  <Box>Drag and drop your documents, images, video or compressed files here to start uploading</Box>
                </Box>
                <Box className="orText">
                  <div className="hr" />Or<div className="hr" />
                </Box>
                <Box className="browseBtnBox">
                  <Typography style={{ fontFamily: 'DM Sans' }} className="addNewFolder" component={"label"} htmlFor="fileUploadInput">Browse Files</Typography>
                  <input
                    data-test-id="fileUploadInputId"
                    accept={'*'}
                    type="file"
                    id="fileUploadInput"
                    hidden
                    multiple
                    onChange={this.handleFileChange}
                  />
                </Box>
                <ScrollBox style={{}}>
                  {this.state.LoadedFiles.length > 0 && (
                    <Box style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",

                    }}>
                      {this.state.LoadedFilesPreview.map((file: any, index: any) => {
                        const progress = this.state.uploadProgress.find(
                          (p: any) => p.fileName === file.fileName
                        );
                        return (
                          <Box key={index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Box style={{ display: 'flex', alignItems: "center", gap: "10px" }}>
                              <Box
                                style={{
                                  backgroundColor: "#ecf1ff",
                                  width: "60px",
                                  height: "60px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  overflow: "hidden",
                                  borderRadius: "10px"
                                }}
                              >
                                <InsertDriveFileOutlinedIcon style={{ color: '#345ee6' }} />
                              </Box>
                              <Box style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "2px"
                              }}>
                                <Box style={{
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                  maxWidth: '100px',
                                  width: '100%',
                                }}>
                                  {file.fileName}
                                </Box>
                                <Typography>{this.formatFileSize(file.fileSize)}</Typography>
                              </Box>
                            </Box>
                            <Box style={{ display: 'flex' }} >
                              <Box>
                                {progress.isUploading ? (
                                  <Box style={{ display: 'flex', alignItems: "center", gap: "5px" }}>
                                    <Box style={{ width: "60px" }}  >
                                      <CustomLinearProgress variant="determinate" value={progress.progress} />
                                    </Box>
                                    <Box>
                                      <CloseIcon style={{ color: "rgba(68, 65, 65, 0.51)" }} data-test-id='test-cancelUpload' onClick={() => this.cancelUpload(file.fileName)} />
                                    </Box>
                                  </Box>
                                ) : (
                                  <Box data-test-id='test-deleteUpload' onClick={() => this.handleDelete(file.fileName)}>
                                    <DeleteOutlineOutlined style={{ color: "rgba(68, 65, 65, 0.51)" }} />
                                  </Box>
                                )}
                              </Box>
                            </Box>
                          </Box>
                        );
                      })}
                    </Box>
                  )}
                </ScrollBox>
              </Box>
            )
          }

        </MainBox>
      </RightBox>
    )
  }

  renderExpandIcon = (x: any) => {
    const { folderLevel } = this.state;
    if (x.data) {
      const { subfolder, id } = x.data.attributes;

      if (subfolder.length > 0) {
        return folderLevel.includes(id) ? <ExpandLessOutlined /> : <ExpandMoreOutlinedIcon />;
      }
    }
    else {
      const { subfolder, id } = x.attributes;

      if (subfolder.length > 0) {
        return folderLevel.includes(id) ? <ExpandLessOutlined /> : <ExpandMoreOutlinedIcon />;
      }
    }

    return <Box style={{ padding: '0 10px' }}>&nbsp;</Box>;
  };

  showSubFolder = (subfolder: any) => {
    this.moveFolderMargin = (this.moveFolderMargin + 1)
    let newMargin = this.moveFolderMargin * 60
    return (
      <Box className="showSubMenu" style={{ zIndex: '99999', display: 'none' }}>
        {
          subfolder.filter((z: any) => z.data.attributes.folder_name !== "Archive Section").map((x: any, index: number) => (
            <>
              <Box onClick={(e) => this.toggleSubMenu(e, x.data.id)} style={{ paddingLeft: `${newMargin}px` }} className={`subFolder ${index === this.state.moveFolders.length - 1 ? '' : 'borderBottom'} `}>
                <Box className="selectFolder"><input value={x.id} checked={this.state.selectedFolderForMove == x.data.id} onChange={() => this.handleSelectedFolderForMove(x.data.id)} type="radio" name="folder" /></Box>
                {this.renderExpandIcon(x)}
                <Box><FolderIcon className="folderIcon" /></Box>
                <Box>{x.data.attributes.folder_name}</Box>
              </Box>
              {x.data.attributes.subfolder.length > 0 ? this.showSubFolder(x.data.attributes.subfolder) : () => { this.moveFolderMargin = 0; return (<></>) }}
            </>
          ))
        }
      </Box>
    )
  }

  moveModal = () => {
    return (
      <MoveModal>
        <Box className="moveModalContainer">
          <Box onClick={this.handleMoveModalPopUp} className="moveModalCloseIconBox"><CloseIcon className="moveModalCloseIcon" /></Box>
          <Box className="moveModalHeading">Move File/Folder</Box>
          <Box className="moveModalInputBox">
            <input
              className="moveModalInput" type="text" width={'100%'} height={'100%'}
              placeholder="Search by Lawyer name, service or jurisdiction" />
            <SearchOutlinedIcon className="moveModalSearchIcon" />
          </Box>
          <Box className="moveModalData">
            <MoveFolders>

              {
                this.state.moveFolders.map((x: any, index: number) => {
                  this.moveFolderMargin = 0;
                  return (
                    (
                      <>
                        <Box onClick={(e) => this.toggleSubMenu(e, x.id)} className={`mainFolder ${index === this.state.moveFolders.length - 1 ? '' : 'borderBottom'}`}>
                          <Box className="selectFolder"><input className="folderInput" value={x.id} checked={this.state.selectedFolderForMove == x.id} onChange={() => this.handleSelectedFolderForMove(x.id)} type="radio" name="folder" /></Box>
                          {this.renderExpandIcon(x)}
                          <FolderIcon className="folderIcon" />
                          <Box>{x.attributes.folder_name === 'root_folder' ? 'Document Folder' : x.attributes.folder_name}</Box>

                        </Box>
                        {x.attributes.subfolder.length > 0 ? this.showSubFolder(x.attributes.subfolder) : () => { this.moveFolderMargin = 0; return (<></>) }}
                      </>
                    )
                  )
                }
                )
              }
            </MoveFolders>

          </Box>
          <Box className="moveModalControls">
            <button onClick={this.handleMoveModalPopUp} type="button" className="moveModalControlsBtn moveModalControlsBtn1">Cancel</button>
            {
              this.state.workLoading ?
                <button type="button" className={`moveModalControlsBtn ${'moveModalControlsBtn2'} `}><CircularProgress style={{ color: 'white', width: '20px', height: '20px' }} /></button>
                :
                <button onClick={this.handleMoveFileFolder} type="button" className={`moveModalControlsBtn ${this.state.selectedFolderForMove && 'moveModalControlsBtn2'} `}>Move</button>
            }
          </Box>
        </Box>
      </MoveModal>
    )
  }

  renameModal = () => {
    return (
      <NewFolderModal>
        <Box className="moveModalContainer">
          <Box onClick={this.handleRenameModalPopUp} className="moveModalCloseIconBox"><CloseIcon className="moveModalCloseIcon" /></Box>
          <Box className="moveModalHeading">Rename</Box>
          <Box className="moveModalInputBox">
            <input value={this.state.renameInput} onChange={(e) => this.setState({ renameInput: e.target.value })}
              className="moveModalInput" type="text" width={'100%'} height={'100%'}
              placeholder="Search by Lawyer name, service or jurisdiction" />
          </Box>
          <Box className="moveModalControls">
            <button onClick={this.handleRenameModalPopUp} type="button" className="moveModalControlsBtn moveModalControlsBtn1">Cancel</button>
            {
              this.state.workLoading ?
                <button type="button" className={`moveModalControlsBtn ${'moveModalControlsBtn2'} `}><CircularProgress style={{ color: 'white', width: '20px', height: '20px' }} /></button>
                :
                <button type="button" onClick={this.handleRenameFileFolder} className={`moveModalControlsBtn ${'moveModalControlsBtn2'} `}>Rename</button>
            }
          </Box>
        </Box>
      </NewFolderModal>
    )
  }

  newFolderModal = () => {

    return (
      <NewFolderModal>
        <Box className="moveModalContainer">
          <Box onClick={this.handleNewFolderModalPopUp} className="moveModalCloseIconBox"><CloseIcon className="moveModalCloseIcon" /></Box>
          <Box className="moveModalHeading">New Folder</Box>
          <Box className="moveModalInputBox">
            <input value={this.state.addNewFolderInput} onChange={this.handleChangeAddNewFolderInputValue}
              className="moveModalInput" type="text" width={'100%'} height={'100%'}
              placeholder="Search by Lawyer name, service or jurisdiction" />
          </Box>
          <Box className="moveModalControls">
            <button onClick={this.handleNewFolderModalPopUp} type="button" className="moveModalControlsBtn moveModalControlsBtn1">Cancel</button>
            <button type="button" onClick={this.handleCreateFolder} className={`moveModalControlsBtn ${this.state.addNewFolderInput.length > 0 && 'moveModalControlsBtn2'} `}>Create</button>
          </Box>
        </Box>
      </NewFolderModal>
    )

  }

  deleteModal = () => {

    return (
      <DeleteModal>
        <Box className="moveModalContainer">
          <Box onClick={this.handleCloseDeleteModalPopUp} className="moveModalCloseIconBox"><CloseIcon className="moveModalCloseIcon" /></Box>
          <Box className="DeleteModaldeleteIconBox"><DeleteOutlineOutlined className="deleteIcon" /></Box>
          <Box className="deleteMsg">Are you sure you want to delete File/Folder?</Box>
          <Box className="moveModalControls">
            <button onClick={this.handleCloseDeleteModalPopUp} type="button" className="moveModalControlsBtn moveModalControlsBtn1">Cancel</button>
            {
              this.state.workLoading ?
                <button type="button" className={`moveModalControlsBtn ${'moveModalControlsBtn2'} `}><CircularProgress style={{ color: 'white', width: '20px', height: '20px' }} /></button>
                :
                <button type="button" onClick={this.handleDeleteFileORFolder} className={`moveModalControlsBtn ${'moveModalControlsBtn2'} `}>Delete</button>
            }
          </Box>
        </Box>
      </DeleteModal>
    )

  }

  noPermissionModal = () => {
    return (
      <NoPermissionModal>
        <Box className="NoPermissionModalmoveModalContainer">
          <Box onClick={this.handleNoPermissionModalPopUp} className="NoPermissionModalmoveModalCloseIconBox"><CloseIcon className="NoPermissionModalmoveModalCloseIcon" /></Box>
          <Box className="NoPermissionModaldeleteIconBox"><CloseIcon className="NoPermissionModaldeleteIcon" /></Box>
          <Box className="NoPermissionModaldeleteMsg">You do not have permssion to do this operation on items created by other users.</Box>
        </Box>
      </NoPermissionModal>
    )
  }

  render() {
    return (
      <>
        <MainDiv data-test-id="resultScreen"
          style={{
            maxHeight: this.state.mobileOpen ? '100vh' : '',
            overflow: this.state.mobileOpen ? "hidden" : ""
          }}
          onClick={this.handleCloseBox}>
          <Box className="sidebar">
            {this.drawer()}
            {this.buttonMenu()}
            {this.state.mobileOpen &&
              <>
                <div className="offcanvascss" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
                  {this.drawer()}
                </div>
              </>
            }
          </Box>
          {this.state.mobileOpen && <div className="offcanvasbackdrop" ></div>}
          <Box className="rightbar" data-test-id="rightbar">
            {this.rightBox()}
          </Box>
          {this.state.moveModalPopUp && this.moveModal()}
          {this.state.renameModalPopUp && this.renameModal()}
          {this.state.newFolderModalPopUp && this.newFolderModal()}
          {this.state.deleteModalPopUp && this.deleteModal()}
          {this.state.isNoPermission && this.noPermissionModal()}
        </MainDiv>
      </>
    );
  }
}

const ArchiveSectionMsg = styled('div')({
  display:'flex',
  backgroundColor:'rgb(219, 236, 253)',
  borderRadius:'10px',
  justifyContent:'space-between',
  alignItems:'center',
  padding:'10px',
  gap:'10px',
  color:'#7c7ce1',
  marginLeft:'1rem',
  marginBottom:'10px',
  "& .archMsg":{

  },
  "& .archCon":{
    display:'flex',
    gap:'10px'
  },
  "& .archBtn":{
    border:'1px solid blue',
    padding:'5px',
    borderRadius:'10px'
  }
})

const NoFilesOrFolder = styled('div')({
  display:'flex',
  flexDirection:"column",
  gap:'10px',
  justifyContent:'center',
  alignItems:'center',
  marginTop:'200px',
  "& .noDataImg":{
    backgroundColor:'#e6e3e3',
    padding:'10px',
    borderRadius:'50%',
    color:'blue'
  }
})

const NoPermissionModal = styled('div')({
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  position: 'absolute',
  top: '0',
  left: '0',
  width: '100vw',
  height: '100vh',
  maxHeight: '100vh',
  overflowY: 'scroll',
  userSelect: 'none',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  "& .NoPermissionModaldeleteIconBox": {
    display: "flex",
    justifyContent: 'center',
    alignItems: 'center'
  },
  "& .NoPermissionModaldeleteIcon": {
    fontSize: '3rem',
    color: '#d9291e',
    borderRadius: '50%',
    padding: '20px',
    backgroundColor: '#fee2e1'
  },
  "& .NoPermissionModaldeleteMsg": {
    fontSize: '1.2rem',
    display: "flex",
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center'
  },

  "& .NoPermissionModalmoveModalContainer": {
    display: 'flex',
    width: '50%',
    height: '28%',
    backgroundColor: '#FFFFFF',
    borderRadius: '20px',
    flexDirection: 'column',
    padding: '0 20px',
    gap: '1rem',
    "@media (min-width:1200px)": {
      width: '30%',
      height: '28%'
    },
  },
  "& .NoPermissionModalmoveModalCloseIconBox": {
    padding: '20px 10px 0 20px',
    display: 'flex',
    justifyContent: 'end',
    cursor: 'pointer !important'
  },
  "& .NoPermissionModalmoveModalCloseIcon": {
    color: 'gray',
  },
})

const DeleteModal = styled('div')({
  overflowY: 'scroll',
  position: 'absolute',
  top: '0',
  left: '0',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  width: '100vw',
  height: '100vh',
  maxHeight: '100vh',
  userSelect: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  "& .DeleteModaldeleteIconBox":{
    display:"flex",
    justifyContent:'center',
    alignItems:'center'
  },
  "& .deleteIcon":{
    backgroundColor:'#fee2e1',
    fontSize:'3rem',
    borderRadius:'50%',
    padding:'20px',
    color:'#d9291e'
  },
  "& .deleteMsg":{
    display:"flex",
    justifyContent:'center',
    alignItems:'center',
    fontSize:'1.2rem'
  },
  
  "& .moveModalContainer": {
    display: 'flex',
    width: '50%',
    height: '35%',
    backgroundColor: '#FFFFFF',
    borderRadius: '20px',
    flexDirection: 'column',
    padding:'0 20px',
    gap: '1rem',
    "@media (min-width:1200px)": {
      width: '30%',
      height: '35%'
    },
  },
  "& .moveModalCloseIconBox": {
    display: 'flex',
    justifyContent: 'end',
    cursor:'pointer !important',
    padding: '20px 10px 0 20px'
  },
  "& .moveModalCloseIcon":{
    color:'gray',
  },
  "& .moveModalHeading": {
    marginTop: '-15px',
    fontSize: '22px'
  },
  "& .moveModalControls":{
    padding:'10px',
    display:'flex',
    gap:'0.5rem'
  },
  "& .moveModalControlsBtn":{
    fontSize: '16px',
    border: 'none',
    color:' gray',
    padding: '10px 30px',
    textAlign: 'center',
    textDecoration: 'none',
    display: 'inline-block',
    margin: '4px 2px',
    borderRadius: '8px',
    minWidth:'50%',
    cursor:'pointer !important'
  },
  "& .moveModalControlsBtn1":{
    border: '1px solid gray',
    backgroundColor: '#f4f4f4',
    color: 'gray'
  },
  "& .moveModalControlsBtn2":{
    backgroundColor: '#d9291e',
    color:'white'
  }

})

const NewFolderModal = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  top: '0',
  left: '0',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  width: '100vw',
  height: '100vh',
  maxHeight: '100vh',
  overflowY: 'scroll',
  userSelect: 'none',
  
  "& .moveModalContainer": {
    display: 'flex',
    width: '50%',
    height: '25%',
    backgroundColor: '#FFFFFF',
    borderRadius: '20px',
    flexDirection: 'column',
    padding:'0 20px',
    gap: '1rem',
    "@media (min-width:1200px)": {
      width: '30%',
      height: '25%'
    },
  },
  "& .moveModalCloseIconBox": {
    display: 'flex',
    padding: '20px 10px 0 20px',
    justifyContent: 'end',
    cursor:'pointer !important'
  },
  "& .moveModalCloseIcon":{
    color:'gray',
  },
  "& .moveModalHeading": {
    fontSize: '22px',
    marginTop: '-15px'
  },
  "& .moveModalInputBox":{
    display:'flex',
    position:'relative',
    width:'100%',
    height:'30px',
    marginBottom:'10px'
  },
  "& .moveModalInput":{
    width:'98%',
    display:'flex',
    outline:'none',
    padding:'20px 20px 20px 10px',
    borderRadius:'10px',
    fontSize:'15px',
    border:'1px solid #b2dafa'
  },
  "& .moveModalInput::placeholder":{
    color:'#a29e9e'
  },
  "& .moveModalControls":{
    padding:'10px',
    display:'flex',
    justifyContent:'end',
    gap:'0.5rem'
  },
  "& .moveModalControlsBtn":{
    fontSize: '16px',
    border: 'none',
    color:' gray',
    padding: '10px 30px',
    textAlign: 'center',
    textDecoration: 'none',
    display: 'inline-block',
    margin: '4px 2px',
    borderRadius: '8px',
    minWidth:'150px',
    cursor:'pointer !important'
  },
  "& .moveModalControlsBtn1":{
    backgroundColor: '#f4f4f4',
    border: '1px solid gray',
    color: 'gray'
  },
  "& .moveModalControlsBtn2":{
    backgroundColor: '#345ee6',
    color:'white'
  }
})

const MoveFolders = styled('div')({
  boxShadow:'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
  borderRadius:'10px',
  width:'calc(99% - 20px)',
  height:'100%',
  overflow:'scroll',
  padding:'10px',
  display:'flex',
  flexDirection:'column',
  "&::-webkit-scrollbar": {
    width: "10px",
  },

  "&::-webkit-scrollbar-thumb": {
    background: "red",
    borderRadius: "5px", 
  },

  "&::-webkit-scrollbar-track": {
    background: "#f1f1f1", 
    borderRadius: "5px",
  },

  scrollbarWidth: "auto", 
  scrollbarColor: "#345ee6 white",

  "& .folderIcon":{
    color:'#BFBFBF'
  },
  "& .folderInput":{
    borderColor:'red'
  },
  "& .mainFolder":{
    padding:'10px 10px 10px 25px',
    display:'flex',
    gap:'0.5rem',
    position:'relative'
  },
  "& .subFolder":{
    padding:'10px 10px 10px 25px',
    display:'flex !important',
    gap:'0.5rem',
    position:'relative'
  },
  "& .selectFolder":{
    position:'absolute',
    left:'0'
  },
  "& .borderBottom":{
  },
  "& .showSubMenu":{
  },
  "& ul": {
    listStyle: 'none',
    margin: 0,        
    padding: 0,       
  },
  "& li": {
    margin: 0,        
    padding: 0,    
  },
})

const MoveModal = styled('div')({
  overflowY: 'scroll',
  position: 'absolute',
  top: '0',
  left: '0',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  width: '100vw',
  height: '100vh',
  userSelect: 'none',
  maxHeight: '100vh',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  
  "& .moveModalContainer": {
    width: '50%',
    backgroundColor: '#FFFFFF',
    height: '60%',
    display: 'flex',
    borderRadius: '20px',
    flexDirection: 'column',
    padding:'0 20px',
    gap: '1rem',
    "@media (min-width:1200px)": {
      width: '30%',
      height: '70%'
    },
  },
  "& .moveModalCloseIconBox": {
    display: 'flex',
    padding: '20px 10px 0 20px',
    justifyContent: 'end',
    cursor:'pointer !important'
  },
  "& .moveModalHeading": {
    fontSize: '22px',
    marginTop: '-15px'
  },
  "& .moveModalInputBox":{
    display:'flex',
    width:'100%',
    height:'30px',
    position:'relative',
    marginBottom:'10px'
  },
  "& .moveModalInput":{
    display:'flex',
    width:'100%',
    padding:'20px 20px 20px 35px',
    outline:'none',
    borderRadius:'10px',
    fontSize:'15px',
    border:'1px solid #b2dafa'
  },
  "& .moveModalInput::placeholder":{
    color:'#a29e9e'
  },
  "& .moveModalSearchIcon":{
    position:'absolute',
    top:'10px',
    left:'10px',
    color:'#BFBFBF'
  },
  "& .moveModalData":{
    width:'100%',
    height:'60%',
    backgroundColor:'#FFFFFF'
  },
  "& .moveModalControls":{
    padding:'10px',
    display:'flex',
    justifyContent:'end',
    gap:'0.5rem'
  },
  "& .moveModalControlsBtn":{
    display: 'inline-block',
    border: 'none',
    color:' gray',
    padding: '10px 30px',
    textAlign: 'center',
    textDecoration: 'none',
    fontSize: '16px',
    margin: '4px 2px',
    borderRadius: '8px',
    minWidth:'150px',
    cursor:'pointer !important'
  },
  "& .moveModalControlsBtn1":{
    border: '1px solid gray',
    backgroundColor: '#f4f4f4',
    color: 'gray'
  },
  "& .moveModalControlsBtn2":{
    backgroundColor: '#345ee6',
    color:'white'
  }
})

const InfoModal = styled('div')({
  position:'absolute',
  display:'flex',
  flexDirection:'column',
  gap:'1rem',
  fontSize:'20px',
  backgroundColor:'#FFFFFF',
  top:'4px',
  padding:'0 20px 20px 20px',
  right:'60px',
  borderRadius:'10px',
  width:'350px',
  flexWrap:'wrap',
  boxShadow: '0 4px 10px rgba(164, 161, 161, 0.5)',
  "& .fileFormatsBox":{
    display:'flex',
    gap:'10px',
    flexWrap:'wrap',
    margin:'-30px 20px 0 0',
  },
  "& .fileFormats":{
    color:'#3695f4'
  },
  "& .fileSizeBox":{
    display:'flex'
  },
  "& .infoIcss":{
    position:'absolute',
    fontSize:'30px',
    color:'#595959',
    top:'7px',
    right:'8px'
  },
  "& .infoIconI":{
    color:'#dbecfd'
  },
  "& .infoIconBox":{
    display:'flex',
    justifyContent:'end'
  },
  "& .infoIconBox:hover":{
    cursor:'pointer !important'
  }
})

const MoreOptions = styled('div')({
  position:'absolute',
  padding:'10px 20px 10px 10px',
  borderRadius:'10px',
  backgroundColor:'#FFFFFF',
  boxShadow: '0 4px 10px rgba(164, 161, 161, 0.5)',
  "& .tripledotcss":{
    position:'absolute',
    fontSize:'30px',
    color:'#595959',
    top:'7px',
    right:'8px'
  },
  "& .tripledotcss:hover":{
    cursor:'!important'
  },
  "& .optionRow":{
    display:'flex',
    alignItems:'center',
    gap:'1rem',
    padding:'10px',
    fontSize:'20px',
    cursor:'pointer !important',
    textWrap:'nowrap'
  }

})

const MoreOptionsF = styled('div')({
  position:'absolute',
  top:'-35px',
  right:'60px',
  padding:'10px 20px 10px 10px',
  borderRadius:'10px',
  backgroundColor:'#FFFFFF',
  boxShadow: '0 4px 10px rgba(164, 161, 161, 0.5)',
  "& .tripledotcss":{
    position:'absolute',
    fontSize:'30px',
    color:'#595959',
    top:'7px',
    right:'8px'
  },
  "& .optionRow":{
    display:'flex',
    alignItems:'center',
    gap:'1rem',
    padding:'10px',
    fontSize:'20px',
    cursor:'pointer !important',
    textWrap:'nowrap'
  }

})


const ScrollBox = styled('div')({
  width: "125%",
  height: "32%",
  margin: "0 auto",
  overflow: "hidden",
  overflowY: "scroll",
  padding: "0 20px 0 0",

  "&::-webkit-scrollbar": {
    width: "10px",
  },

  "&::-webkit-scrollbar-thumb": {
    background: "red",
    borderRadius: "5px", 
  },

  "&::-webkit-scrollbar-track": {
    background: "#f1f1f1", 
    borderRadius: "5px",
  },

  scrollbarWidth: "auto", 
  scrollbarColor: "#345ee6 white",
});

const MainBox = styled('div')({
  display:'flex',
  width:'100%',
  height:'80%',
  padding:'0 0 0 20px',
  fontFamily:'DM Sans !important',
  "& .folderBox":{
    width:'80%',
    height:'100%',
    display:'flex',
    flexDirection:'column',
    gap:'0.5rem',
    overflowY:'scroll',
  },
  "& .folderBoxA":{
    width:'100%',
    height:'650px',
    display:'flex',
    flexDirection:'column',
    gap:'0.5rem',
    overflowY:'scroll',
  },
  '& .showScroll': {
    "&::-webkit-scrollbar": {
      width: "10px",
    },

    "&::-webkit-scrollbar-thumb": {
      background: "red",
      borderRadius: "5px", 
    },

    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1", 
      borderRadius: "5px",
    },

    scrollbarWidth: "auto", 
    scrollbarColor: "#345ee6 white",
  },
  '& .noScroll': {
    overflowY: "scroll",
    scrollbarWidth: "none",
  },
  "& .noScroll::-webkit-scrollbar": {
    display: "none",
  },
  "& .headerContent": {
    fontSize:'1rem',
    fontWeight: '600',
    "@media (min-width:1200px)": {
      fontSize:'1.5rem',
    },
  },
  "& .uploadBox":{
    width:'15%',
    height:'640px',
    padding:'0 0 0 0',
    flexDirection:'column',
    gap:'0.5rem',
    justifyContent:'center',
    alignItems:'center',
  },
  "& .uploadBox > *":{
    marginTop: '1rem',
  },
  "& .uploadHeading":{
    display:'flex',
    fontSize:'1.5rem',
    justifyContent:'space-around',
    alignItems:'center',
    fontWeight:'600',
    position:'relative',
    gap:'70px',
    textWrap: 'no-wrap',
    width: "135%"
  },
  "& .uploadDragZone":{
    width:'120%',
    height:'225px',
    border:'3px dashed #345ee6',
    display:'flex',
    flexDirection:'column',
    padding:'10px',
    textAlign:'center',
    backgroundColor:'#ecf1ff',
    borderRadius:'1rem',
    justifyContent:'center',
    alignItems:'center',
    fontSize:'12px',
    marginTop: '1.5rem'
  },
  "& .dragIcon":{
    fontSize:'2rem',
    color:'#345ee6'
  },
  "& .infoIconBox":{},
  "& .infoIcon":{
    color:'gray'
  },
  "& .addNewFolder": {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#345ee6',
    padding: '1rem',
    color: '#FFFFFF',
    borderRadius: '10px',
    width:'120%',
    marginLeft: '0',
  },
  "& .addNewFolder:hover":{
    cursor:'pointer !important',
  },
  "& .orText":{
    margin:"0",
    display: 'flex',
    width: '130%',
    justConten: 'center',
    alignItems: 'center',
    gap: '5px',
    color: "#345ee6",
    marginTop: '1rem'
  },
  "& .hr": {
    width: "50%",
    height: '1px',
    backgroundColor: '#345ee6'
  },
  "& .moreOne":{
    position:'relative'
  }

})

const TopBox = styled('div')({
  width:'100%',
  display:'flex',
  flexDirection:'column',
  gap:'0.5rem',
  "& .topBoxHeader":{
    display:'flex',
    gap:'0.5rem',
    padding:'0.2rem',
    alignItems:'center'
  },
  "& .backIcon":{
    color: '#6b92ff',
    cursor: 'ponter !important',
    "@media (min-width:1200px)": {
      fontSize:'3rem',
    },
  },
  "& .headerContent":{
    fontSize:'1rem',
    fontWeight: '600',
    "@media (min-width:1200px)": {
      fontSize:'1.5rem',
    },
  },
  "& .topBoxSubHeader":{
    display:'flex',
    gap:'0.5rem',
    padding:'0.2rem',
    alignItems:'center',
  },
  "& .subHeaderContent":{
    display:'flex',
    fontSize:'0.9rem',
    alignItems:'center',
    paddingLeft: '2rem',
    "@media (min-width:1200px)": {
      fontSize:'1.2rem',
      paddingLeft: '3.5rem',
    },
  },
  "& .optionsBar":{
    display:'flex',
    flexDirection:'column',
    gap:'1rem',
    "@media (min-width:1200px)": {
      flexDirection:'row',
      justifyContent:'space-between',
    },
  },
  "& .filterSelected":{
    display:'flex',
    gap:'0.5rem',
    marginLeft:'3rem',
    marginBottom:'10px'
  },
  "& .filterSelectedOption":{
    display:'flex',
    alignItems:'center',
    justifyContent:'space-between',
    gap:'0.5rem',
    padding:'5px 10px',
    backgroundColor:'#dbecfd',
    borderRadius:'50px',
    color:'#345ee6',
  },
  "& .filterSelectedOptionIcon":{
    fontSize:'18px'
  },
  "& .searchBar":{
    display:'flex',
    alignItems:'center',
    gap:'1rem',
  },
  "& .searchIcon":{
    color:'#b8b8b8',
    width: '100%',
    "@media (min-width:1200px)": {
      fontSize:'1.8rem',
      paddingLeft:'1rem'
    },
  },
  "& .searchFieldBox":{
    flexGrow: 1
  },
  "& .searchField":{
    width: '100%',
    outline: 'none',
    border: 'none',
    padding: '0.1rem'
  },
  "& .searchField::placeholder":{
    color: 'gray',
    "@media (min-width:1200px)": {
      fontSize:'1rem',
    },
  },
  "& .optionsBarSubBox":{
    display:'flex',
    alignItems:'center',
    gap:'2rem',
    justifyContent:'space-between',
    "@media (min-width:1200px)": {
      justifyContent:'start',
      gap:'4rem'
    },
  },
  "& .filterBox":{
    backgroundColor: '#ecf1ff',
    padding:'0.5rem',
    position:'relative'
  },
  "& .filterIcon":{
    color:'#345ee6'
  },
  "& .addNewFolder":{
    backgroundColor: '#345ee6',
    padding: '1rem',
    color: '#FFFFFF',
    borderRadius: '10px',
  },
  "& .viewFilterBox":{
    display:'none',
    alignItems:'center',
    gap:'0.5rem',
    "@media (min-width:1200px)": {
      display:'flex',
    },
  },
  "& .listBox":{
    backgroundColor: '#dbecfd',
    padding:'0.7rem 0.5rem 0.7rem 0.7rem',
    borderRadius: '50% 0 0 50%'
  },
  "& .listIcon":{
    color:'#345ee6'
  },
  "& .listIconi":{
    color:'gray'
  },
  "& .gridBox":{
    backgroundColor: '#dbecfd',
    padding:'0.7rem 0.7rem 0.7rem 0.5rem',
    borderRadius: '0 50% 50% 0'
  },
  "& .gridIcon":{
    color:'#345ee6'
  },
})

const RightBox = styled('div')({
  width:'100%',
  height:'100%',
  fontFamily:'DM Sans !important'
})

const MainDiv = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  fontFamily: 'DM Sans',

  " ::-webkit-scrollbar": {
    width: "10px !important"
  },
  " ::-webkit-scrollbar-track": {
    background: "red !important"
  },
  " ::-webkit-scrollbar-thumb": {
    background: "red !important"
  },
  " ::-webkit-scrollbar-thumb:hover": {
    background: "red !important"
  },
  "@media (max-width:991px)": {
      display: "block",
  },
  "& .MuiSelect-selectMenu:focus": {
      backgroundColor: "#fff"
  },
  "& .sidebar": {
      width: '355px',
      height: '100vh',
      position: 'fixed',
      top: '0',
      left: '0',
      borderRight: "1px solid #C3D1FF",
      "@media (max-width: 1100px)": {
          width: "355px"
      },
      "@media (max-width: 991px)": {
          width: '100vw',
          height: '80px'
      }
  },
  "& .rightbar": {
      width: 'calc(100% - 355px)',
      padding: "20px 70px",
      marginLeft: '355px',
      marginTop: '0px',
      backgroundColor: '#fafafa',
      "@media (max-width: 1100px)": {
          padding: '20 40px',
          width: 'calc(100% - 355px)',
          marginLeft: '355px',
      },
      "@media (max-width: 991px)": {
          width: 'auto !important',
          marginLeft: '0px',
          marginTop: '0px',
          height: 'calc(100vh - 90px)'
      }
  },
 
  "& .offcanvascss": {
      position: 'fixed',
      top: '0px !important',
      bottom: '0px !important',
      left: '0% !important',
      width: '355px !important',
      height: '100vh !important',
      backgroundColor: '#fff',
      zIndex: 9999,
  },
  "& .offcanvasbackdrop": {
      position: "fixed",
      top: '0px',
      left: '355px',
      width: 'calc(100% - 355px)',
      height: '100vh',
      zIndex: 999,
      backgroundColor: '#000',
      opacity: 0.5,
  },

})

const DrawerBox = styled('div')({
  minHeight: '100vh',
  width: "355px",
  "& .logobox": {
      display: 'flex', justifyContent: "start",
      height: "76px", marginTop: '20px',
      marginLeft: '20px'
  },
  "& .logoimag":
  {
      height: '42px',
      width: '118px',
  },
  "& .settingtext": {
      color: '#262626', fontSize: '22px',
      marginLeft: '6px',
      fontWeight: 400, fontFamily: 'DM Sans',
      marginTop:'3px'
  },
  "& .settingbox":
  {
      display: 'flex',
      height: "65px",
      cursor: 'pointer'
  },

  "& .lefticon":
  {
      marginTop: '4px',
      marginLeft: '30px',
      fontSize: '30px !important',
      color: '#514e4eeb'
  },

  "@media (max-width: 1100px)":
  {
      zIndex: 999,
      width: '355px',
  },
  "@media (max-width: 991px)":
  {
      zIndex: 999,
      width: '355px',
      display: 'none',
  },
});

const ButtonBox = styled('div')({
  display: 'none',
  "@media (max-width: 991px)":
  {
      width: '100%',
      display: 'block', minHeight: '70px',
      "& .buttonbox":
      {
          height: '70px', width: '100vw'
      },

      "& .iconbtn":
      {
          marginTop: '10px', marginLeft: '10px'
      }
  }
})

const CustomLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: '6px',
  width: '50px', 
  borderRadius: '5px', 
  '& .MuiLinearProgress-bar': {
    borderRadius: '5px', 
    backgroundColor: '#355EE7',
  },
}));

// Customizable Area End
