import React from "react";
// Customizable Area Start
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import { styled } from '@material-ui/core/styles';
import { logo, userpic } from "./assets";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import InfoIcon from '@material-ui/icons/Info';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { Box, Typography, Button, TextField, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, InputAdornment } from "@material-ui/core";
import { Formik, Field } from "formik";
import PhoneInput from "react-phone-input-2";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import CloseIcon from '@material-ui/icons/Close';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CameraAltOutlinedIcon from '@material-ui/icons/CameraAltOutlined';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import * as Yup from "yup";

import "react-datepicker/dist/react-datepicker.css";
import ErrorSuccessPopUp from "../../../components/src/ErrorSuccessPopUp.web";


// Customizable Area End
// Customizable Area Start

import CustomisableUserProfilesController, {
  CountryDatatype,
  Props
} from "./CustomisableUserProfilesController";
// Customizable Area End

export default class CustomisableUserProfiles extends CustomisableUserProfilesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
    // Customizable Area Start
  validationSchema = () => (

    Yup.object().shape({

      password: Yup.string()
        .required('Password is required').min(8, "Minimum 8 characters")
        .matches(/[A-Z]/, 'Password does not match requirements')
        .matches(/[a-z]/, 'Password does not match requirements')
        .matches(/\d/, 'Password does not match requirements')
    })
  )

  validationSchemaUser = () => {
    return Yup.object().shape({
      firstname: Yup.string().matches(/^[A-Za-z\s]*$/, 'Invalid characters in First Name'),
      lastname: Yup.string().matches(/^[A-Za-z\s]*$/, 'Invalid characters in Last Name'),
      email: Yup.string().email('Invalid email format').required('Email is required'),
      country: Yup.string().required('Country is required'),
      lawfirm: Yup.string().required('Company/Law firm is required'),
      contactNumber: Yup.string().required('Phone number is required'),
    });
  };
  passwordCertiaShow1 = (values: { password: string }) => {
    return (
      <>
        {this.state.showPasswordCriteria && (
          <Box mt={1}>
            <Typography className="errorParent">Your password must contain:</Typography>
            <Box display="flex" alignItems="center">
              <IconButton style={{ padding: '3px' }}>
                {/[A-Z]/.test(values.password) ? (
                  <CheckCircleIcon style={{ marginBottom: '6px', color: 'green' }} />
                ) : (
                  <RemoveCircleIcon style={{ marginBottom: '6px', color: 'red' }} />
                )}
              </IconButton>
              <Typography className="passError">At least one uppercase letter</Typography>
            </Box>
            <Box display="flex" alignItems="center" className="errorBox">
              <IconButton style={{ padding: '3px' }}>
                {values.password && /[a-z]/.test(values.password) ? (
                  <CheckCircleIcon style={{ marginBottom: '6px', color: 'green' }} />
                ) : (
                  <RemoveCircleIcon style={{ marginBottom: '6px', color: 'red' }} />
                )}
              </IconButton>
              <Typography className="passError">At least one lowercase letter</Typography>
            </Box>
            <Box display="flex" alignItems="center" className="errorBox">
              <IconButton style={{ padding: '3px' }}>
                {/\d/.test(values.password) ? (
                  <CheckCircleIcon style={{ marginBottom: '6px', color: 'green' }} />
                ) : (
                  <RemoveCircleIcon style={{ marginBottom: '6px', color: 'red' }} />
                )}
              </IconButton>
              <Typography className="passError">At least one number</Typography>
            </Box>
            <Box className="passwordErrorBox errorBox" display="flex" alignItems="center">
              <IconButton style={{ padding: '3px' }}>
                {this.handlePasswordIcon1(values)}
              </IconButton>
              <Typography className="passError" style={{ marginTop: '5px' }}>Old password cannot be reused</Typography>
            </Box>
          </Box>
        )}
      </>
    )
  }
  handlePasswordIcon1 = (values: { password: string }) => {
    let passwordIcon1;
    if (this.state.passwordError1) {
      if (values.password !== this.state.oldpassword1) {
        passwordIcon1 = (
          <div>
            {this.handleRemoveError1()}
            <CheckCircleIcon style={{ color: 'green' }} />
          </div>
        );
      } else {
        passwordIcon1 = <RemoveCircleIcon style={{ color: 'red' }} />;
      }
    } else {
      passwordIcon1 = <CheckCircleIcon style={{ color: 'green' }} />;
    }
    return (
      passwordIcon1
    )
  }
  IconOldPassword = () => {
    return (
      <>
        {this.state.oldchangepassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
      </>
    )
  }

  passwordform = () => (

    <Formik data-test-id="changepassForm"
      initialValues={{ oldpassword: "", password: "" }}
      validationSchema={this.validationSchema()}
      onSubmit={(values) => {
        this.handleChangePassword(values);
      }}
    >
      {({ values, errors, handleSubmit, handleChange }) => {
        return (
          <form className="thirdForm"
            onSubmit={handleSubmit}>
            <Box className="firstNameField">
              <Box className="passwordBoxField">
                <Typography variant="subtitle1" className="firstNamePlaceHolder" style={{
                  color: "#434343",
                  fontSize: '15px',
                  fontWeight: 700,
                  fontFamily: 'DM Sans',
                }}>
                  Old Password
                </Typography>
                <TextField
                  name="oldpassword"
                  type={this.state.oldchangepassword ? "text" : "password"}
                  fullWidth
                  variant="outlined"
                  placeholder="Your password"
                  style={{ marginBottom: '15px' }}
                  data-test-id="oldpassword1"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const { value } = event.target;
                    if (/^[A-Za-z0-9!@#$%^&*()_\-+=\[\]{}|\\:;"'<>,.?/~` ]*$/.test(value)) {
                      handleChange(event);
                    }
                  }}
                  inputProps={{ "data-test-id": "oldpassword" }}
                  InputProps={{
                    style: {
                      borderRadius: '8px'
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          data-test-id="toggleOldPasswordVisibility"
                          aria-label="toggle password visibility"
                          onClick={this.showoldpassicon}
                          edge="end"
                        >
                          {this.IconOldPassword()}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </Box>
              <Box>
                <Typography variant="subtitle1" style={{
                  color: "#434343",
                  fontSize: '15px',
                  fontWeight: 700,
                  fontFamily: 'DM Sans',
                }}>
                  New Password
                </Typography>
                <Field
                  as={TextField}
                  name="password"
                  data-test-id="confirmpassword"
                  type={this.state.changepassword ? "text" : "password"}
                  fullWidth
                  variant="outlined"
                  placeholder="Your password"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const { value } = event.target;
                    if (/^[A-Za-z0-9!@#$%^&*()_\-+=\[\]{}|\\:;"'<>,.?/~` ]*$/.test(value)) {
                      handleChange(event);
                      this.setState({ error_shown: true })
                    }
                  }}
                  value={values.password}
                  error={this.state.error_shown && !!errors.password || this.state.errormsg}
                  onFocus={this.handleFocusError}
                  InputProps={{
                    "data-test-id": "confirmpass",
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          data-test-id="toggleNewPasswordVisibility"
                          aria-label="toggle password visibility"
                          onClick={this.showchangepassicon}
                          edge="end"
                        >
                          {this.state.changepassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </Box>
              {this.handleErrorBox(this.state.error_shown, <div className="statusErrorCustom" data-test-id="passwordError" style={{ color: 'red' }}>
                {this.state.errormsg || errors.password}
              </div>, "")}
              {this.passwordCertiaShow1(values)}
            </Box>
            <Box style={{ padding: '20px', borderTop: '1px solid #E2E8F0', display: "flex", justifyContent: "center", }}>
              <CustomChangebutton
                data-test-id="changPassButton"
                type="submit"
                disabled={Boolean(!values.oldpassword || !values.password || errors.oldpassword || errors.password)}
                style={{
                  backgroundColor: Boolean(!values.oldpassword || !values.password || errors.oldpassword || errors.password) ? "#D9D9D9" : "#355EE7",
                }}
              >
                Change password
              </CustomChangebutton>
            </Box>
          </form>
        )
      }}
    </Formik>
  )

  handleGetCountryData = (item:CountryDatatype, index:number)=>{                    
    return(
      <option  data-test-id={`country-${index}`} key={index} style={{color:'#000'}}value={item.attributes.name} >{item.attributes.name}</option>
    )
  } 

  userBox=()=>{
    return(
      <Formik
      data-test-id="userboxForm"
      validationSchema={this.validationSchemaUser()}
      initialValues={{
        firstname: this.state.userdata?.first_name || "",
        contactNumber: this.state.userdata?.full_phone_number || "",
        lastname: this.state.userdata?.last_name || "",
        email: this.state.userdata?.email || "",
        country: this.state.userdata?.jurisdiction || "",
        lawfirm: this.state.userdata?.firm || "",
      }}
      onSubmit={(values, { setStatus }) => {
        if (!values.firstname || values.contactNumber.length<10 || !values.lastname || !values.email || !values.country ||
           !values.lawfirm ) {
            setStatus(` Please complete your profile with valid information to proceed.`);
        } else {
          setStatus(null);
          this.UpdateUserData(values)
        }
      }}

    >
      {({ values,status, handleSubmit, handleChange,setFieldValue }) => {
        return (
          <form
            onSubmit={handleSubmit}>
            <Box className="topbox">
              <Typography className="titleuser">
                User profile settings
              </Typography>
              <button type="submit" className="titlbtn" style={{ display: this.state.mobileOpen ? "none" : "" }}>
                Save changes
              </button>
            </Box>
            <Box mb={2}>
            {status && (!values.firstname || values.contactNumber.length<10 || !values.lastname || !values.email || !values.country 
            || !values.lawfirm ) && (
                <Box className="statusError" borderRadius={4} textAlign="center">
                  {<ErrorOutlineIcon style={{marginRight:'3px'}}/>}{status}
                </Box>
              )}
            </Box>
            <Box>
              <Box className="profilebox">
                <Typography className="profiletext">
                  Profile picture
                </Typography>
                <Box className="profileImg">
                  <Box className="positionrel" style={{ display: this.state.mobileOpen ? "none" : "" }}>
                    <label htmlFor="image" className="imagenot" style={{ backgroundImage: this.state.changeimage ? `url(${this.state.changeimage})` : `url(${userpic})` }}>
                      <>
                        <CameraAltOutlinedIcon className="usericon" />
                        <Typography className="edittxt">Edit picture</Typography>
                      </>
                    </label>
                    <input
                      type="file"
                      id="image"
                      name="image"
                      style={{ display: 'none' }}
                      onChange={this.handleImageChange}
                    />
                  </Box>
                </Box>
              </Box>
              <Box className="detailsbox">
                <Box className="personaldetailtext">
                  <Typography className="fontUi" >Personal details</Typography>
                </Box>
                <Box className="personalform">
                  <Box className="fullName">
                    <Box className="firstname">
                      <Typography variant="subtitle1" className="firstNamePlaceHolder">
                        First Name
                      </Typography>
                      <Field
                        as={TextField}
                        inputProps={{ "data-test-id": "firstname" }}
                        InputProps={{
                          style: {
                            borderRadius: '8px'
                          }
                        }}
                        name="firstname"
                        fullWidth
                        variant="outlined"
                        placeholder="Enter First Name"
                        style={{ display: this.state.mobileOpen ? "none" : "" }}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          const { value } = event.target;
                          if (/^[A-Za-z\s]*$/.test(value)) {
                            handleChange(event);
                          }}}
                        value={values.firstname}
                      />
                    </Box>
                    <Box className="lastname">
                      <Typography variant="subtitle1" className="firstNamePlaceHolder">
                        Last Name
                      </Typography>
                      <TextField
                        name="lastname"
                        inputProps={{ "data-test-id": "lastname" }}
                        InputProps={{
                          style: {
                            borderRadius: '8px'
                          }
                        }}
                        style={{ display: this.state.mobileOpen ? "none" : "" }}
                        fullWidth
                        variant="outlined"
                        placeholder="Enter Last Name"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          const { value } = event.target;
                          if (/^[A-Za-z\s]*$/.test(value)) {
                            handleChange(event);
                          }}}
                        value={values.lastname}
                      />
                    </Box>
                  </Box>
                  <Box className="lawFirm">
                    <Typography variant="subtitle1" className="firstNamePlaceHolder">
                      Company/Law firm
                    </Typography>
                    <TextField
                      name="lawfirm"
                      inputProps={{ "data-test-id": "lawfirm" }}
                      InputProps={{
                        style: {
                          borderRadius: '8px'
                        }
                      }}
                      style={{ display: this.state.mobileOpen ? "none" : "" }}
                      fullWidth
                      variant="outlined"
                      placeholder="National Law Court"
                      onChange={handleChange}
                      value={values.lawfirm}
                      disabled={this.state.lawfirmNameDisabled}
                    />
                  </Box>
                  <Box className="country">
                    <Typography variant="subtitle1" className="firstNamePlaceHolder">
                    Jurisdiction
                    </Typography>
                    <select 
                  className="selectCountry"
                  data-test-id="country"
                  name="country"
                  value={values.country}
                  onChange={(event) => setFieldValue("country", event.target.value)}
                >
                  {this.state.countryData?.map(this.handleGetCountryData)}                 
                </select>
                  </Box>
                  <Box className="PhoneNumer">
                    <Typography variant="subtitle1" className="firstNamePlaceHolder">
                      Phone Number
                    </Typography>
                    <PhoneInputStyle className="phoneInput" style={{ display: this.state.mobileOpen ? "none" : "" }}>
                    <PhoneInput
  country={this.state.countryCode || 'in'}
  placeholder={""}
  inputProps={{
    required: true,
    id: "contactNumber",
    name: "contactNumber",
    "data-test-id": "PhoneNumberModel",
  }}
  enableSearch={true}
  disableSearchIcon={true}
  jumpCursorToEnd={true}
  countryCodeEditable={false}
  inputClass={"PhoneInputPackgeClass"}
  value={values.contactNumber}
  onChange={(phone: string, country: any) => {
    const newPhone = this.handleCountryChange(phone, country);
    setFieldValue("contactNumber", newPhone);

  }}
/>

                    </PhoneInputStyle>
                    {this.state.countryCodeMisMatch && (
    <Typography variant="caption" color="error">
      The phone number does not match the selected jurisdiction.
    </Typography>
  )}
                  </Box>
                  <Box className="Email">
                    <Typography variant="subtitle1" className="firstNamePlaceHolder">
                      Email
                    </Typography>
                    <TextField
                      inputProps={{ "data-test-id": "email" }}
                      InputProps={{
                        style: {
                          borderRadius: '8px'
                        }
                      }}
                      style={{ display: this.state.mobileOpen ? "none" : "" }}
                      name="email"
                      type="email"
                      fullWidth
                      variant="outlined"
                      placeholder="Your email"
                      onChange={handleChange}
                      value={values.email}
                    />
                  </Box>
                  <Box className="Password">
                    <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Typography variant="subtitle1" className="firstNamePlaceHolder">
                        Current password
                      </Typography>
                      <Box data-test-id="changepasstext" className="changepasstext" onClick={this.handleClickOpenchangepass}>Change Password</Box>
                    </Box>
                    <Typography className="passtext">********</Typography>
                  </Box>
                </Box>
              </Box>
              <Box className="accountsbox">
                <Box className="accountdetailtext">
                  <Typography style={{fontFamily:"DM sans"}}>Account actions</Typography>
                </Box>
                <Box className="accountsactions">
                  <Box className="logouttxt" data-test-id="logoutdBtn" onClick={this.handleClickOpen}>
                    <ExitToAppIcon /><Typography style={{ marginLeft: '8px', marginTop: '6px' ,fontFamily:"DM Sans"}}>Log out</Typography>
                  </Box>
                  <Box className="deletetxt" onClick={this.handleClickOpendelete}>
                    <DeleteOutlineIcon /><Typography style={{ marginLeft: '8px', marginTop: '6px',fontFamily:"DM Sans" }}>Delete account</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </form>
        )
      }}
    </Formik>
    )
  }

  drawer = () => (

    <DrawerBox style={{ display: this.state.mobileOpen ? 'block' : '' }}>
      <div className="toolbar" />
      <Divider />
      <div className="logobox">
        <img src={logo} alt="logo" className="logoimag" />
      </div>
      {this.state.mobileOpen && <CloseIcon style={{ position: 'fixed', top: '30px', left: '260px', cursor: 'pointer' }} onClick={this.handleclosesidebar} />}
      <div className="settingbox"  onClick={this.handleSettings}>
      <ChevronLeftIcon className="lefticon"/>
        <Typography className="settingtext">Setting</Typography>
      </div>
      <List>
        <ListItem className="listbox1" onClick={this.handleUserProfile}>
          <ListItemIcon><PermIdentityIcon className="icondrawer1" /></ListItemIcon>
          <ListItemText className="listtile1">
            User Profile
            <Typography className="listtext">
              Settings related to your personal information and account credentials
            </Typography>
          </ListItemText>
        </ListItem>
        <ListItem className="listbox" onClick={this.handleCompanyInfo}>
          <ListItemIcon><InfoIcon className="icondrawer2" /></ListItemIcon>
          <ListItemText className="listtile">
            Company information
            <Typography className="listtext">
              View Digip’s Terms and Conditions and Privacy Policy
            </Typography>
          </ListItemText>
        </ListItem>
        <ListItem className="listbox" onClick={this.handleNotifications}>
          <ListItemIcon><NotificationsIcon className="icondrawer3" /></ListItemIcon>
          <ListItemText className="listtile">
            Notifications
            <Typography className="listtext">
              All settings related to notifications
            </Typography>
          </ListItemText>
        </ListItem>
      </List>
    </DrawerBox>
  )
    // Customizable Area End
  render() {
    // Customizable Area Start

    const buttonMenu = (
      <ButtonBox className="buttonbox">
        <IconButton
          className="iconbtn"
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={this.handleDrawerToggle}
          style={{ marginRight: '24px', color: '#000' }}
        >
          <MenuIcon />

        </IconButton>
      </ButtonBox>
    )

    const paperProps = {
      style: {
        borderRadius: '16px'
      }
    };
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        {!this.state.loadData &&
          <MainDiv data-test-id="SettingUserProfile"
            style={{
              maxHeight: this.state.mobileOpen ? '100vh' : '',
              overflow: this.state.mobileOpen ? "hidden" : ""
            }}>
            <Box className="sidebar">
              {this.drawer()}
              {buttonMenu}
              {this.state.mobileOpen &&
                <>
                  <div className="offcanvascss" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
                    {this.drawer()}
                  </div>
                </>
              }
            </Box>
            {this.state.mobileOpen && <div className="offcanvasbackdrop" onClick={this.handleclosesidebar}></div>}

            <Box className="rightbar">
              <Box className="userdetailbox">
               {this.userBox()}
              </Box>
            </Box>
            <Dialog
              open={this.state.openchangepass}
              onClose={this.handleClosechangepass}
              aria-labelledby="responsive-dialog-title"
              data-test-id="dialogchangepassword"
              PaperProps={paperProps}
            >
              <DialogTitle id="responsive-dialog-title" style={{ textAlign: 'right' }}><CloseIcon style={{cursor:'pointer'}} onClick={this.handleClosechangepass} /></DialogTitle>
              <DialogContent>
                <DialogContentText>
                  <Typography
                    style={{
                      backgroundColor: '#ffffff',
                      color: '#0F172A',
                      fontSize: '22px',
                      fontWeight: 400,
                      fontFamily: 'DM Sans',
                      padding: '10px 0px'
                    }}
                  >Change password</Typography>
                  <Typography
                    style={{
                      color: '#0F172A',
                      fontSize: '16px',
                      fontWeight: 400,
                      fontFamily: 'DM Sans',
                      marginBottom: '20px'
                    }}
                  >
                    You can update your password to keep your account secure.
                  </Typography>
                  {this.passwordform()}
                </DialogContentText>
              </DialogContent>
            </Dialog>
            <Dialog
              open={this.state.open}
              data-test-id="logoutMainId"
              onClose={this.handleClose}
              aria-labelledby="responsive-dialog-title"
              PaperProps={paperProps}
            >
              <DialogTitle id="responsive-dialog-title" style={{ textAlign: 'right' }}><CloseIcon style={{cursor:'pointer'}}  onClick={this.handleClose} /></DialogTitle>
              <DialogContent>
                <DialogContentText
                  style={{
                    backgroundColor: '#ffffff',
                    color: '#000000',
                    fontSize: '22px',
                    fontWeight: 400,
                    fontFamily: 'DM Sans',
                    padding: '10px 0px',
                    marginBottom: '15px',
                  }}
                >
                  Are you sure you want to log out of your account?
                </DialogContentText>
              </DialogContent>
              <DialogActions style={{ padding: '20px', borderTop: '1px solid #E2E8F0' }}>
                <Button variant="outlined" className="btncss" autoFocus onClick={this.handleClose}
                  style={{
                    color: "#355EE7 ",
                    borderRadius: "8px ",
                    fontSize: '16px ',
                    fontWeight: 400,
                    fontFamily: 'DM Sans ',
                    border: '1px solid #355EE7',

                  }}
                >
                  Cancel
                </Button>
                <Button variant="contained" data-test-id="handleLogoutId" className="btncss" onClick={this.handlelogout} autoFocus
                  style={{
                    backgroundColor: "#355EE7 ",
                    color: '#ffffff ',
                    borderRadius: "8px ",
                    fontSize: '16px ',
                    fontWeight: 400,
                    fontFamily: 'DM Sans ',
                    marginLeft: '15px',
                    cursor:'pointer'
                  }}
                >
                  Log out
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={this.state.opendelete}
              onClose={this.handleCloseDelete}
              aria-labelledby="responsive-dialog-title"
              PaperProps={paperProps}
            >
              <DialogTitle id="responsive-dialog-title" style={{ textAlign: 'right' }}><CloseIcon style={{cursor:'pointer'}}  onClick={this.handleCloseDelete} /></DialogTitle>
              <DialogContent>
                <DialogContentText>
                  <Typography style={{
                    backgroundColor: '#ffffff',
                    color: '#0F172A',
                    fontSize: '22px',
                    fontWeight: 400,
                    fontFamily: 'DM Sans',
                    padding: '10px 0px'
                  }}>
                    Are you sure you want to delete your account?</Typography>
                  <Typography style={{
                    backgroundColor: '#ffffff',
                    color: '#0F172A',
                    fontSize: '19px',
                    fontWeight: 400,
                    fontFamily: 'DM Sans',
                    marginBottom: '20px',
                  }}>
                    This action can’t be undone and all the data stored in your account won’t be accessible anymore.
                  </Typography>
                </DialogContentText>
              </DialogContent>
              <DialogActions style={{ padding: '20px', borderTop: '1px solid #E2E8F0' }}>
                <Button variant="outlined" className="btncss" data-test-id="deleteBtnId" autoFocus onClick={this.handleDeleteUser} color="primary"
                  style={{
                    color: "#355EE7 ",
                    borderRadius: "8px ",
                    fontSize: '16px ',
                    fontWeight: 400,
                    fontFamily: 'DM Sans ',
                    border: '1px solid #355EE7',
                    cursor:'pointer'
                  }}
                >
                  Delete account
                </Button>
                <Button variant="contained" className="btncss" onClick={this.handleCloseDelete} color="primary" autoFocus
                  style={{
                    backgroundColor: "#355EE7 ",
                    color: '#ffffff ',
                    borderRadius: "8px ",
                    fontSize: '16px ',
                    fontWeight: 400,
                    fontFamily: 'DM Sans ',
                    marginLeft: '15px',
                    cursor:'pointer'
                  }}
                >
                  Keep account
                </Button>
              </DialogActions>
            </Dialog>

            {this.state.popShow && <ErrorSuccessPopUp
                  showPop={this.state.popShow}
                  setShowPop={(newState: boolean) => this.setState({ popShow: newState })}
                  showResponse={this.state.responseShown}
                  termfunction={  () => {}}
                  deletePop={this.state.settingNav||this.state.deleteNavi?true:false}
                  loginNav={this.state.deleteNavi?this.handleLogIn:this.handleDashboard}
                />}
          </MainDiv>
        }
      </>
     // Customizable Area End
    );
  }
}

// Customizable Area Start
const PhoneInputStyle = styled(Box)({
  "& .country-list": {
    top: "0px !important",
    height: "155px !important"
  },
  "& .react-tel-input .country-list .country-name": {
    fontFamily: "DM Sans"
  },
  "& .react-tel-input": {
    fontFamily: "DM Sans"
  },
  "& .react-tel-input .country": {
    marginBottom:"0px"
  },
  "& .PhoneInputPackgeClass": {
    width: "97.3%",
    height: "56px",
    borderRadius: "5px",
    fontSize: "16px",
    boder: "none !important",
    backgroundColor: "white",
    marginLeft: "20px",
    
  },
  "& .flag-dropdown": {
    boder: "none !important",
    width: '65px',
  },
  "& .react-tel-input .flag-dropdown": {
    boder: "none !important",
  },
  "& .selected-flag": {
    width: '60px !important',
    boder: "none !important",
  },
  "& .PhoneInput": {
    position: "relative",
    "& .PhoneError": {
      fontSize: "18px",
      color: "red",
      margin: "6px 14px",
      position: "absolute"
    },
  },
  '&:hover': {
    border:'1px solid #000',
    borderRadius:"4px"
  },
  "&:focus-within": {
    border: '2px solid #3f51b5',
    borderRadius: "6px",
  }
});


const MainDiv = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  "@media (max-width:991px)": {
    display: "block",
  },
  
  "& .sidebar": {
    width: '350px',
    height: '100vh',
    position: 'fixed',
    top: '0',
    left: '0',
    borderRight: "1px solid #C3D1FF",
    "@media (max-width: 1100px)": {
      width: "300px"
    },
    "@media (max-width: 991px)": {
      width: '100vw',
      height: '80px'
    }
  },
  "& .selectCountry": {
    width: '100%',
    padding: '15px',
    border: '0.4px solid #c5cbc6',  // Keeps the border
    borderRadius: '4px',
    fontSize: '16px',
    marginBottom: '8px',
    outline: 'none',
    backgroundColor: '#fff',
    backgroundImage: 'url("data:image/svg+xml;utf8,<svg fill=\'%23c5cbc6\' height=\'20\' viewBox=\'0 0 24 24\' width=\'20\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M7 10l5 5 5-5z\'/></svg>")',
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: '95%',
    backgroundPositionY: 'center',
    appearance: 'none',
    fontWeight:400,
    fontFamily:"DM Sans",
    '&:hover': {
      border:'1px solid #000',
      borderRadius:"8px"
    },
    "&:focus":{
      border:'2px solid #3f51b5;',
      borderRadius:"8px"
    }
    
  },
  "& .mainheader": {
    position: 'fixed',
    left: '350px',
    width: '100%'
  },
  "& .fontUi": {
    fontFamily:"DM sans"
  },
  "& . firstNamePlaceHolder": {
    fontFamily:"DM sans"
  },

  "& .Header": {
    height: '50px',
    fontSize: '20px',
    padding: '20px',
  },
  "& .rightbar": {
    width: 'calc(100% - 350px)',
    padding: "20px 70px",
    marginLeft: '350px',
    marginTop: '100px',
    "@media (max-width: 1100px)": {
      padding: '20 40px',
      width: 'calc(100% - 300px)',
      marginLeft: '300px',
    },
    "@media (max-width: 991px)": {
      width: 'auto !important',
      marginLeft: '0px',
      marginTop: '50px',
      padding: "10px 50px",
    }
  },
  "& .userdetailbox": {
    width: 'auto',
    margin: "0px auto",
  },
  "& .profilebox": {
    height: '156px',
    display: 'flex',
    borderBottom: '1px solid #D9D9D9',
    "@media (max-width: 450px)": {
      height: '120px',
    },
  },
  "& .profileImg": {
    display: 'flex',
    justifyContent: "center",
    width: "80%",
  },
  "& .profiletext": {
    fontSize: '16px',
    fontWeight: 400,
    color: '#8C8C8C',
    fontFamily: 'DM Sans',
  },
  "& .positionrel": {
    position: "relative",
  },
  "& .imagenot": {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    border: '4px solid #ECF1FF',
    padding: '70px',
    position: "absolute",
    borderRadius: '50%',
    "@media (max-width: 450px)": {
      padding: '50px'
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: '#000',
      opacity: 0.7,
      borderRadius: '50%',
      zIndex: 0
    }
  },
  "& .userimg": {
    height: '120px',
    width: '120px',
    position: "absolute",
    borderRadius: '50%',
    backgroundColor: "#000000bf",
  },
  "& .edittxt": {
    color: '#fff',
    position: 'absolute',
    top: '80px',
    left: '31px',
    fontSize: '14px',
    fontWeight: 700,
    fontFamily: 'DM Sans',
    zIndex: 1,
    "@media (max-width: 450px)": {
      position: 'absolute',
      top: '50px',
      left: '11px',
    },
  },
  "& .usericon": {
    position: 'absolute',
    top: '52px',
    left: '55px',
    fontSize: '22px',
    fontWeight: 700,
    fontFamily: 'DM Sans',
    color: '#fff',
    zIndex: 1,
    "@media (max-width: 450px)": {
      position: 'absolute',
      top: '28px',
      left: '37px',
    },
  },
  '& .topbox': {
    display: 'flex',
    width: "auto",
    height: '70px',
    justifyContent: 'space-between',
    "@media (max-width: 991px)": {
      width: "auto !important",
    },
  },
  "& .titleuser": {
    height: '30px',
    width: '230px',
    fontSize: '22px',
    fontWeight: 400,
    fontFamily: "DM Sans",
    color: '#000',
    "@media (max-width: 450px)": {
      width: '200px',
      fontSize: '16px',
    },
  },
  "& .titlbtn": {
    cursor:'pointer',
    height: '44px',
    width: '163px',
    borderRadius: '8px',
    padding: '10px 12px',
    fontSize: '16px',
    fontWeight: 400,
    color: '#FFFFFF',
    backgroundColor: "#355EE7",
    fontFamily: 'DM Sans',
    textTransform: "none",
    boxShadow:'none !important',
    border:'2px solid #355EE7 ',
  '&:hover': {
    border:'2px solid #B9C5FF',
    boxShadow:'none !important'
  },
    "@media (max-width: 450px)": {
      height: '40px',
      width: '140px',
      padding: '7px 9px',
    },
  },
  "& .detailsbox": {
    display: 'flex',
    marginTop: '20px',
    borderBottom: '1px solid #D9D9D9',
    "@media (max-width: 600px)": {
      display: 'block'
    },
  },
  "& .personaldetailtext": {
    width: '20%',
    fontSize: '16px',
    fontWeight: 400,
    fontFamily: 'DM Sans',
    color: '#8C8C8C',
    "@media (max-width: 600px)": {
      width: '100%',
      marginBottom: '20px'
    },
  },
  "& .personalform": {
    width: '80%',
    "@media (max-width: 600px)": {
      width: '100%'
    },
  },
  "& .fullName": {
    width: '100%',
    display: 'flex',
    marginBottom: '10px',
    "@media (max-width: 650px)": {
      display: 'block',
      width: '100%'
    }
  },
  "& .firstname": {
    width: "45%",
    marginRight: "10px",
    "@media (max-width: 650px)": {
      width: "91% !important",
      marginBottom: '15px',
    },
  },
  
  "& .lastname": {
    width: "45%",
    "@media (max-width: 650px)": {
      width: "91% !important",
    },
  },
  "& .lawFirm": {
    width: "91%",
    marginBottom: '15px',
  },
  "& .country": {
    width: "91%",
    marginBottom: '15px',
  },
  "& .PhoneNumer": {
    width: "91%",
    marginBottom: '15px',
  },
  "& .Email": {
    width: "91%",
    marginBottom: '15px',
  },
  "& .Password": {
    width: "91%",
    marginBottom: '15px',
  },
  "& .passtext": {
    color: "#434343",
    fontSize: '14px',
    fontWeight: 400,
    fontFamily: 'DM Sans',
  },
  "& .changepasstext": {
    color: "#6200EA",
    fontSize: '14px',
    fontWeight: 700,
    fontFamily: 'DM Sans',
    cursor: 'pointer',
    marginTop: '4px',
  },
  "& .accountsbox": {
    display: 'flex',
    marginTop: '20px',
    "@media (max-width: 600px)": {
      display: 'block',
      marginTop: '10px',
    },
  },
  "& .accountdetailtext": {
    width: '20%',
    color: "#8C8C8C",
    fontSize: '16px',
    fontWeight: 400,
    fontFamily: 'DM Sans',
    "@media (max-width: 600px)": {
      width: '100%',
      marginBottom: '20px'
    },
  },
  "& .accountsactions": {
    width: '80%',
    marginBottom: '40px',
    "@media (max-width: 600px)": {
      width: '100%',
    },
  },
  "& .logouttxt": {
    display: 'flex',
    alignItems: 'center',
    margin: '0px 0px 20px 0px',
    color: "#434343",
    fontSize: '16px',
    fontWeight: 400,
    fontFamily: 'DM Sans',
    cursor: 'pointer',
  },
  
  "& .deletetxt": {
    display: 'flex',
    alignItems: 'center',
    margin: '20px 0px',
    color: "#DC2626",
    fontSize: '16px',
    fontWeight: 400,
    fontFamily: 'DM Sans',
    cursor: 'pointer',
  },
  "& .errorOutline": {
    '& .MuiFormControl-root': {
      '& fieldset': {
        borderColor: 'red 1px solid',
      },
    },
  },
  "& .statusError": {
    display:'flex', alignItems:'center',
    fontFamily: "DM Sans", fontWeight: "400", fontSize: "16px", textAlign: "left",
    color: "#DC2626", marginBottom: "20px"

  },
  "& .offcanvascss": {
    position: 'fixed',
    top: '0px !important',
    bottom: '0px !important',
    left: '0% !important',
    width: '300px !important',
    height: '100vh !important',
    backgroundColor: '#fff',
    zIndex: 9999,
  },
  "& .offcanvasbackdrop": {
    position: "fixed",
    top: '0px',
    left: '300px',
    width: 'calc(100% - 300px)',
    height: '100vh',
    zIndex: 999,
    backgroundColor: '#000',
    opacity: 0.5,
  },
  "& .btncss":{
    border: 'none',
    boxShadow: 'none !important',
      '&:hover': {
        border: '2px solid #B9C5FF !important',
        boxShadow: 'none !important',
      },
  }
})

const CustomChangebutton= styled('button')({
    color: '#ffffff',
    cursor:'pointer',
    borderRadius: "8px",
    fontSize: '16px',
    fontWeight: 400,
    fontFamily: 'DM Sans',
    textTransform: "none",
    padding:'16px',
    boxShadow: 'none !important',
    border:"none",
      '&:hover': {
        border: '2px solid #B9C5FF !important',
        boxShadow: 'none !important',
      },
      "&:disabled": {
        border: "none !important",
        boxShadow: "none",
        textTransform: "none",
        "&:hover":{
          border: 'none !important',
          boxShadow: 'none !important',
        }
    },
    
})


const DrawerBox = styled('div')({
  minHeight: '100vh',
  width: "350px",
  "& .logobox": {
    display: 'flex', justifyContent: "start",
    height: "76px", marginTop: '20px',
    marginLeft: '20px'
  },
  "& .logoimag":
  {
    height: '42px',
    width: '118px',
  },
  "& .settingtext": {
    color: '#262626', fontSize: '22px',
    marginLeft: '6px',
    fontWeight: 400, fontFamily: 'DM Sans',
    cursor:"pointer"
  },
  "& .settingbox":
  {
    display: 'flex',
    height: "65px",
  },

  "& .lefticon":
  {
    marginTop: '4px',
    marginLeft: '30px',
    cursor:"pointer"
  },
  "& .listbox": {
    height: '110px',
    "&:hover": {
      backgroundColor: "#DCECFF",
      color: "#355EE7",
      "& .icondrawer2":
      {
        backgroundColor: "#DCECFF",
        color: "#355EE7",
      },
      "& .icondrawer3":
      {
        backgroundColor: "#DCECFF",
        color: "#355EE7",
      },
    },
  },
  "& .listbox1": {
    height: '110px',
    backgroundColor: '#DCECFF',
  },
  "& .icondrawer1": {
    marginBottom: '45px',
    color: '#355EE7',
    marginLeft: '18px',
  },
  "& .icondrawer3": {
    marginLeft: '18px',
    marginBottom: '25px',
  },
  "& .icondrawer2": {
    marginLeft: '18px',
    marginBottom: '45px',
  },

  "& .listtext": {
    fontSize: '14px',
    color: '#334155',
    fontFamily: 'DM Sans',
    fontWeight: 400,
  },
  "& .listtile1": {
    fontSize: "16px",
    color: '#355EE7',
    fontFamily: 'DM Sans',
    fontWeight: 400,
    cursor: 'pointer'
  },
  "& .listtile":
  {
    fontSize: "16px",
    fontFamily: 'DM Sans',
    fontWeight: 400,
    cursor: 'pointer',
  },
  "@media (max-width: 1100px)":
  {
    zIndex: 999,
    width: '300px',
  },
  "@media (max-width: 991px)":
  {
    zIndex: 999,
    width: '300px',
    display: 'none',
  },
});


const ButtonBox = styled('div')(
  {
    display: 'none',
    "@media (max-width: 991px)":
    {
      width: '100%',
      display: 'block', minHeight: '70px',
      "& .buttonbox":
      {
        height: '70px', width: '100vw'
      },

      "& .iconbtn":
      {
        marginTop: '10px', marginLeft: '10px'
      }
    }
  })
// Customizable Area End
