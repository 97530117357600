// Customizable Area Start
Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ProjectReporting";
exports.labelBodyText = "ProjectReporting Body";
exports.nameofproject= "Name of the Project";
exports.projecttype = "Project Type";
exports.manager = "Manager ID";
exports.co_manager = "Co-Manager ID";
exports.assessor ="Assessor";
exports.client = "Client ID"
exports.selectstartdate = "Select Start Date"
exports.selectecenddate = "Select End Date";
exports.uploaddoc ="Upload Documents"
exports.survey = "Survey Type";
exports.projectReporting = "Project Reporting"
exports.enterProjectReporting = "Enter Project Name"
exports.enterProjectType = "Enter Project Type"
exports.projectReporting = "Project Reporting"
exports.managerID = "Manager Id"
exports.coManagerId = "Co Manager Id"
exports.clientID = "Client ID"
exports.buttonSave = "Save"
exports.getProjectReportingCall = "bx_block_projectreporting/projects";
exports.createProjectCall="bx_block_profile/projects";
exports.downloadProjectReportingCsvFile = "bx_block_projectreporting/projects.csv"
exports.getCoManagersDataAPICall="account_block/co_managers";
exports.getManagersDataAPICall="account_block/managers";
exports.getClientDataAPICall="account_block/clients";
exports.getcsvfileAPICall = "bx_block_projectreporting/projects.csv"
exports.btnExampleTitle = "CLICK ME";
exports.projectReportingerror= " Please Enter Project Name"
exports.projectTypeerror = "Please Enter Project Type";
exports.managerNameerror ="Please Enter Manager Name"
exports.coManagerNameerror = "Please Enter Co-Manager";
exports.clientNamerror = "Please Enter Client Name";
exports.startdateerror = "Please Enter Start Date";
exports.enddateerror = "Please Enter End Date"
exports.errorsName = "Name already exists"
exports.errorsClientId = "has already been taken."
exports.noRecodFound = "No records found"
exports.downloadcsvfile = "Download to CSV file"
exports.downloadexcelfile = "Download to Excel file"
exports.dateFormate = "DD-MM-YYYY"
exports.dateMode = "date"
exports.maxDate = "01-01-2080"
exports.textConfirm = "Confirm"
exports.textCancle = "Cancel"
exports.mineTextCsv = "text/csv"
exports.titlecsv = "Downloading CSV"
exports.description = "Please wait..."
exports.getCsvAPICall = "bx_block_projectreporting/projects.csv"
exports.mineTextxlsx = "text/xlsx"
exports.titleXlsx = "Downloading Xlsx"
exports.getXlsxAPICall = "bx_block_projectreporting/projects.xlsx"
exports.associatedLawyersApi = "bx_block_catalogue/catalogues/list_of_associated_lawyers"
exports.todoListApi = "bx_block_tasks/task_to_dos?project_template_id="
exports.viewToDoApi = "bx_block_tasks/task_to_dos"
exports.postToDoApi = "bx_block_tasks/task_to_dos"
exports.deleteTodo = "bx_block_tasks/task_to_dos"
exports.assignLawyerApiEndPoint = "bx_block_joblisting/jobs/task_assigned_lawyers?project_template_id="
exports.apiContentType = "application/json";
exports.getApiMethod = "GET";
exports.postApiMethod = "POST";
exports.putApiMethod = "PUT";
exports.patchApiMethod = "PATCH";
exports.deleteApiMethod = "DELETE";
exports.postCreateTodoApi="bx_block_tasks/task_to_dos"

// Customizable Area End
